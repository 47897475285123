<template>
  <transition
    :name="showTransitionName"
  >
    <div
      v-if="isMenuOpened"
      class="vue-c-navigation"
      :class="classObject"
    >
      <div
        ref="contentContainer"
        class="vue-b-content-container"
      >
        <div class="vue-b-content">
          <div class="vue-b-main">
            <!-- TODO: get applicant username from API, save it in store and display here
            <prj1005-navigation-login
              v-if="!isSubmenuOpened"
            />
            -->
            <prj1005-navigation-items />
          </div>
          <div class="vue-b-rest">
            <prj1005-navigation-info
              v-if="!isSubmenuOpened"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapState} from 'vuex';

import Prj1005NavigationItems from './prj1005-navigation-items';
import Prj1005NavigationInfo from './prj1005-navigation-info';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import EVENT_BUS from '@/event-bus';

export default {
  name: 'Prj1005Navigation',
  components: {
    // Prj1005NavigationLogin,
    Prj1005NavigationItems,
    Prj1005NavigationInfo
  },

  data() {
    return {
      isSubnavigationOpened: false,
    }
  },

  computed: {
    ...mapState('general', [
      'isMenuOpened',
      'isSubmenuOpened',
      'backToNavigation'
    ]),

    classObject() {
      return {
        'vue-is-active': this.isMenuOpened,
        'vue-is-subnavigation-active': this.isSubmenuOpened,
      }
    },

    showTransitionName() {
      if (this.backToNavigation && this.isMenuOpened) {
        return 'vue-toggle-back';
      } else {
        return 'vue-toggle';
      }
    }
  },

  watch: {
    isMenuOpened(value) {
      if (value) {
        EVENT_BUS.$emit(GLOBAL_EVENTS.CLOSE_DRAWER);
        this.registerEventListeners();
      } else {
        this.unregisterEventListeners();
      }
    }
  },

  beforeDestroy() {
    this.unregisterEventListeners();
  },

  methods: {
    click(event) {
      if (!this.whiteListCheck(event)) {
        this.close();
      }
    },

    keyUp(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },

    close() {
      this.resetRouteParams()
        .then(() => {
          this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.CLOSE_DASHBOARD_NAVIGATION);

          if (this.isSubmenuOpened) {
            this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_SUBMENU);
          }
        });
    },

    whiteListCheck(event) {
      let element = event.target;
      let navbarButton = document.querySelector('.vue-navbar-button');

      if (this.$refs.contentContainer.contains(element) || navbarButton.contains(element)) {
        return true;
      }

      return false;
    },

    registerEventListeners() {
      setTimeout(() => {
        document.addEventListener('click', this.click);
        document.addEventListener('keyup', this.keyUp);
      }, 100);
    },

    unregisterEventListeners() {
      document.removeEventListener('click', this.click);
      document.removeEventListener('keyup', this.keyUp);
    },

    resetRouteParams() {
      return new Promise(resolve => {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        resolve();
      })
    }
  }
}
</script>
