import Gen1009Overlay from './gen1009-overlay';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1009Overlay);
    }
};

use(Plugin);

export default Plugin;

export { Gen1009Overlay };
