import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import general from './modules/general';
import persistentStorage from './modules/persistent-storage';
import user from './modules/user';
import dashboard from './modules/dashboard';
import popup from './modules/popup';
import onboarding from './modules/onboarding';

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: 'nastup-localstorage',
  storage: window.localStorage,
  modules: ['persistentStorage', 'user', 'dashboard', 'onboarding']
});

export default new Vuex.Store({
  modules: {
    general,
    persistentStorage,
    user,
    dashboard,
    popup,
    onboarding,
  },
  plugins: [vuexLocal.plugin]
});
