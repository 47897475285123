<template>
  <!-- TODO MBU: remove vue-heading class - refactor needed - styling in various modules relies on this style -->
  <div class="vue-c-heading vue-heading">
    <div class="vue-heading-container">
      <h1
        v-if="header && !detailheader"
        class="vue-header"
      >
        {{ header }}
      </h1>
      <h1
        v-if="detailheader"
        class="vue-detail-header"
      >
        {{ detailheader }}
      </h1>
      <h2
        v-if="subheader"
        class="vue-subheader"
      >
        {{ subheader }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prj1017Heading',
  components: {
  },

  props: {
    header: {
      type: String,
      required: false,
    },
    subheader: {
      type: String,
      required: false,
    },
    detailheader: {
      type: String,
      required: false,
    }
  },
}
</script>
