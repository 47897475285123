import Gen1010InformationTooltip from './gen1010-information-tooltip';
import Gen1010InformationTooltipMessage from './gen1010-information-tooltip-message';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1010InformationTooltip);
        registerComponent(Vue, Gen1010InformationTooltipMessage);
    }
};

use(Plugin);

export default Plugin;

export { Gen1010InformationTooltip, Gen1010InformationTooltipMessage };
