<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        class="vue-directory-intro"
        v-html="$i18n.t(isHPP ? 'onboarding.directoryDescription' : 'onboarding.directoryDescriptionDPP')"
      />
      <gen1006-info-panel
        v-if="isRejectMessageActive"
        type="error"
      >
        <div class="vue-message">
          <strong>{{ $t('onboarding.persdataRejected') }}</strong>
        </div>
        <div class="vue-message">
          {{ applicant.persdataRejectNote }}
        </div>
      </gen1006-info-panel>
      <div class="vue-b-info-controls">
        <template
          v-for="state in controlStates"
        >
          <h2
            v-if="state.enable"
            :key="state.type"
            class="vue-section-title"
          >
            {{ state.label }}
          </h2>
          <template
            v-for="button in controlButtonOptions"
          >
            <div
              v-if="'vue-is-' + state.type === button.status"
              :key="state.type + button.type"
              class="vue-control-group"
              :class="button.status"
            >
              <frm1006-button
                :icon-right="true"
                class="vue-control-item"
                type="internal"
                @buttonClickEvent="goToView(button.path)"
              >
                {{ button.text }}
              </frm1006-button>
            </div>
          </template>
        </template>
      </div>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT, ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES,
  ONBOARDING_APPLICANT_DOC_STATUSES, ONBOARDING_APPLICANT_STATUSES,
} from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'OnboardingDirectory',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      ONBOARDING_APPLICANT_STATUSES,
      navbarCaptionData: null,
      applicant: {},
      categoriesStatuses: {}
    };
  },

  computed: {
    formStatusClass() {
      const status = this.applicant.status;
      let state = 'positive';
      if (status === ONBOARDING_APPLICANT_STATUSES.regApproved || status === ONBOARDING_APPLICANT_STATUSES.persdataRejected) {
        state = 'pending'
      }
      if (status === ONBOARDING_APPLICANT_STATUSES.persdataFilled) {
        state = 'progress'
      }
      return `vue-is-${state}`
    },

    photoStatusClass() {
      const status = this.categoriesStatuses.photo;
      let state = 'positive';
      if (status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.pending) {
        state = 'pending'
      }
      if (status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.uploaded) {
        state = 'progress'
      }
      return `vue-is-${state}`
    },

    documentsStatusClass() {
      const status = this.applicant.docsStatus;
      let state = 'positive';
      if (status === ONBOARDING_APPLICANT_DOC_STATUSES.pendingUpload || status === ONBOARDING_APPLICANT_DOC_STATUSES.someRejected) {
        state = 'pending'
      }
      if (status === ONBOARDING_APPLICANT_DOC_STATUSES.pendingApproval) {
        state = 'progress'
      }
      return `vue-is-${state}`
    },

    isRejectMessageActive() {
      return (this.applicant.status === ONBOARDING_APPLICANT_STATUSES.persdataRejected ) && this.applicant.persdataRejectNote
    },

    isHPP() {
      return this.applicant.contractType === 'HIRE_INT';
    },

    controlButtonOptions() {
      const controls = [
        {
          'type': 'form',
          'path': 'onboarding-form',
          'status': this.formStatusClass,
          'order': this.orderControls(this.formStatusClass),
          'text': this.$i18n.t('onboarding.personalQuestionnaire'),
        },
        {
          'type': 'document',
          'path': 'onboarding-documents',
          'status': this.documentsStatusClass,
          'order': this.orderControls(this.documentsStatusClass),
          'text': this.$i18n.t('onboarding.documents'),
        },
      ];
      if (this.isHPP) { // photo is only applicable for HPP
        controls.push(
          {
            'type': 'photo',
            'path': 'onboarding-photo',
            'status': this.photoStatusClass,
            'order': this.orderControls(this.photoStatusClass),
            'text': this.$i18n.t('onboarding.photo'),
          }
        );
      }

      controls.sort(function (a, b) {
        return a.order - b.order;
      });

      return controls;
    },

    controlStates() {
      const states = [
        {
          'type': 'pending',
          'label': this.$i18n.t('onboarding.colorLegendPending'),
          'enable': this.controlButtonOptions.filter(control => control.status === 'vue-is-pending').length > 0,
        },
        {
          'type': 'positive',
          'label': this.$i18n.t('onboarding.colorLegendPositive'),
          'enable': this.controlButtonOptions.filter(control => control.status === 'vue-is-positive').length > 0,
        },
        {
          'type': 'progress',
          'label': this.$i18n.t('onboarding.colorLegendProgress'),
          'enable': this.controlButtonOptions.filter(control => control.status === 'vue-is-progress').length > 0,
        }
      ];

      return states;
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getApplicantDetail().then(() => {
      this.getApplicantDocumentsCategories().then(() => {
        this.initialized = true;
      })
    })
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName, params: { docsStatus: this.applicant.docsStatus }});
    },

    getApplicantDetail() {
      this.setLoadingState(true);

      return dataLoader.fetchApplicantDetail()
        .then((data) => {
          this.applicant = data;
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true;
            this.setLoadingState(false);
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {

        });
    },

    getApplicantDocumentsCategories() {
      return dataLoader.fetchApplicantDocumentsCategories()
        .then((data) => {
          this.categoriesStatuses = data;
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    orderControls(state) {
      if (state === 'vue-is-pending') {
        return 1;
      } else if (state === 'vue-is-positive') {
        return 2;
      } else if (state === 'vue-is-progress') {
        return 3;
      } else {
        return 4;
      }
    }
  },
}
</script>
