import Frm1027Slider from './frm1027-slider';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1027Slider);
    }
};

use(Plugin);

export default Plugin;

export { Frm1027Slider };
