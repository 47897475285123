import tbl1001Repeater from './tbl1001-repeater';
import Tbl1001RepeaterControls from './tbl1001-repeater-controls';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, tbl1001Repeater);
        registerComponent(Vue, Tbl1001RepeaterControls);
    }
};

use(Plugin);

export default Plugin;

export { tbl1001Repeater, Tbl1001RepeaterControls };
