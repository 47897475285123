<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaptionCustom"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div
        class="vue-onboarding-section-intro"
        v-html="$t('onboarding.documentsDescription.' + documentCategory, {docname: attachmentDocName})"
      />
    </template>

    <!--========== CONTENT FOOTER =========================-->
    <!--===================================================-->
    <template v-slot:footer>
      <frm1006-button
        type="primary"
        :title="$i18n.t('onboarding.confirm')"
        @buttonClickEvent="confirmDocument(documentCategory)"
      >
        {{ $t('onboarding.confirm') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import i18n from '@/i18n';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'OnboardingDocumentConfirm',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      attachmentDocName: 'xxs',
    };
  },

  computed: {
    navbarCaptionCustom() {
      return i18n.t('onboarding.documentsName')[this.$route.params.category]
    },

    documentCategory() {
      return this.$route.params.category
    },

    applicantCompany() {
      return this.$route.params.company
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    switch (this.applicantCompany) {
    case 'CSOBSS':
      this.attachmentDocName = 'CSOBS_CP_o_uzavreni_PPV.docx';
      break;
    case 'HB':
      this.attachmentDocName = 'HB_CP_o_uzavreni_PPV.docx';
      break;
    default:
      this.attachmentDocName = 'CP_o_uzavreni_PPV_k_CSOB.docx';
    }
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    confirmDocument(documentCategory) {
      this.setLoadingState(true);

      return dataLoader.setDocumentCategoryStatusUnused(documentCategory)
        .then(() => {
          this.goToView('onboarding-documents')
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true;
            this.setLoadingState(false);
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
        })
    },
  },
}
</script>
