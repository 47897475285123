import { TWO_WEEKS_IN_MS } from '@/constants/app-constants';
import * as MUTATIONS_CONSTANTS from '../constants/mutations';
import * as ACTIONS_CONSTANTS from '../constants/actions';

import utilsGeneral from '@/utils/utils-general';
import dataLoader from '@/utils/data-loader';
import * as CryptoJS from 'crypto-js';

const POSTPONE_PUSH_HINT_DAYS = 2; // FIXME MSE: for DEV testing, set to 30 for production use

const state = {
  activeArticleId: 0,
  yearAndWeek: '',
  registeredEvents: [],
  registeredForvardinoEvents: [],
  registeredWorkouts: [],
  ratedForvardinoEvents: [],
  pushHintPostponedUntil: null,
  lastFoodRatingTimestamp: 0,
  surveyInfo: {
    hasFilledSurvey: false,
    surveyId: 0,
  },
  parkingLastReserve: {},
  foodEshopFinishedOrderToken: [],
  auctionMember: null,
  isBuildingHHQIntroVisited: false,
  previousSelectedRestaurant: '',
  previousSelectedBranch: '',
  applicantFormStatus: '',
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_YEAR_AND_WEEK](state, yearAndWeek) {
    state.yearAndWeek = yearAndWeek;
  },

  [MUTATIONS_CONSTANTS.SET_REGISTERED_EVENT](state, event) {
    if (!state.registeredEvents.includes(event)) {
      state.registeredEvents.push(event);
    }
  },

  [MUTATIONS_CONSTANTS.REMOVE_REGISTERED_EVENT](state, voidEventId) {
    const filteredEvents = state.registeredEvents.filter(item => item !== voidEventId);

    state.registeredEvents = filteredEvents;
  },

  [MUTATIONS_CONSTANTS.SET_REGISTERED_FORVARDINO_EVENT](state, forvardinoEvent) {
    if (!state.registeredForvardinoEvents.includes(forvardinoEvent)) {
      state.registeredForvardinoEvents.push(forvardinoEvent);
    }
  },

  [MUTATIONS_CONSTANTS.REMOVE_REGISTERED_FORVARDINO_EVENT](state, voidForvardinoEventId) {
    const filteredEvents = state.registeredForvardinoEvents.filter(item => parseInt(item) !== parseInt(voidForvardinoEventId));

    state.registeredForvardinoEvents = filteredEvents;
  },

  [MUTATIONS_CONSTANTS.SET_RATED_FORVARDINO_EVENT](state, ratedForvardinoEventId) {
    state.ratedForvardinoEvents.push(ratedForvardinoEventId);
  },

  [MUTATIONS_CONSTANTS.REMOVE_RATED_FORVARDINO_EVENT](state, removedForvardinoEventId) {
    state.ratedForvardinoEvents.filter(itemId => itemId === removedForvardinoEventId);
  },

  [MUTATIONS_CONSTANTS.POSTPONE_PUSH_NOTIFICATIONS](state) {
    let doNotAskUntil = new Date();
    doNotAskUntil.setDate(doNotAskUntil.getDate() + POSTPONE_PUSH_HINT_DAYS);
    state.pushHintPostponedUntil = Math.floor(doNotAskUntil.getTime() / 1000);
  },

  [MUTATIONS_CONSTANTS.SET_REGISTERED_WORKOUT](state, workout) {
    if (!state.registeredWorkouts.includes(workout)) {
      state.registeredWorkouts.push(workout);
    }
  },

  [MUTATIONS_CONSTANTS.REMOVE_REGISTERED_WORKOUT](state, voidWorkoutIds) {
    const filteredWorkouts = state.registeredWorkouts.filter(item => item !== voidWorkoutIds);

    state.registeredWorkouts = [];

    filteredWorkouts.forEach(item => {
      state.registeredWorkouts.push(item);
    });
  },

  [MUTATIONS_CONSTANTS.SET_LAST_FOOD_RATING_TIMESTAMP](state) {
    let timestamp = new Date().getTime();
    state.lastFoodRatingTimestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_FILLED_SURVEY_ID](state, filledSurveyId) {
    state.surveyInfo.filledSurveyId = filledSurveyId;
  },

  [MUTATIONS_CONSTANTS.SET_HAS_FILLED_SURVEY](state, surveyFilledState) {
    state.surveyInfo.hasFilledSurvey = surveyFilledState;
  },

  [MUTATIONS_CONSTANTS.SET_FOOD_ESHOP_FINISHED_ORDER_TOKEN](state, token) {
    const timestamp = new Date().getTime();
    const tokenPayload = {
      timestamp: timestamp,
      orderToken: token
    };

    state.foodEshopFinishedOrderToken.push(tokenPayload);
  },

  [MUTATIONS_CONSTANTS.REMOVE_FOOD_ESHOP_FINISHED_ORDER_TOKEN](state, token) {
    let foodEshopTokenArray = state.foodEshopFinishedOrderToken;

    state.foodEshopFinishedOrderToken = foodEshopTokenArray.filter(tokenItem => tokenItem.success !== token);
  },

  [MUTATIONS_CONSTANTS.SET_AUCTION_MEMBER](state, member) {
    state.auctionMember = member;
  },

  [MUTATIONS_CONSTANTS.SET_BUILDING_HHQ_INTRO_VISITED](state, value) {
    state.isBuildingHHQIntroVisited = value;
  },

  [MUTATIONS_CONSTANTS.SET_PREVIOUS_SELECTED_RESTAURANT](state, value) {
    state.previousSelectedRestaurant = value;
  },

  [MUTATIONS_CONSTANTS.SET_PREVIOUS_SELECTED_BRANCH](state, value) {
    state.previousSelectedBranch = value;
  },

  [MUTATIONS_CONSTANTS.SET_APPLICANT_FORM_STATUS](state, value) {
    state.applicantFormStatus = value;
  },
};

const actions = {
  [ACTIONS_CONSTANTS.GENERATE_YEAR_AND_WEEK]({commit}) {
    const date = utilsGeneral.yearAndWeekCurrent();
    const yearAndWeek = `${date.year}-${date.week}`;

    commit(MUTATIONS_CONSTANTS.SET_YEAR_AND_WEEK, yearAndWeek);
  },

  [ACTIONS_CONSTANTS.REMOVE_FORVARDINO_EVENT]({ commit }, id) {
    commit(MUTATIONS_CONSTANTS.REMOVE_REGISTERED_FORVARDINO_EVENT, id);
    commit(MUTATIONS_CONSTANTS.REMOVE_RATED_FORVARDINO_EVENT, id);
  },

  [ACTIONS_CONSTANTS.CHECK_FOOD_ESHOP_TOKEN_TIMESTAMPS]({ state, commit }) {
    const timestampNow = new Date().getTime();

    state.foodEshopFinishedOrderToken.forEach(item => {
      const timestampDiff = timestampNow - item.timestamp;

      if (timestampDiff > TWO_WEEKS_IN_MS) {
        commit(MUTATIONS_CONSTANTS.REMOVE_FOOD_ESHOP_FINISHED_ORDER_TOKEN, item.orderToken.success);
      }
    });
  },

  // AUTHORIZED MODE ONLY ACTIONS
  // ===================================================
  // eslint-disable-next-line no-unused-vars
  [ACTIONS_CONSTANTS.PRIVATE_USER_DATA]({ commit }, options) {
    return dataLoader.fetchPrivateUserData(options)
      .then(data => {
        if(typeof data?.parking !== 'undefined') {
          let tmpEncryptedValue = CryptoJS.AES.decrypt(data?.parking?.values, options[2]);
          let tmpJSONValue = JSON.parse(tmpEncryptedValue.toString(CryptoJS.enc.Utf8));
          commit(MUTATIONS_CONSTANTS.SET_PARKING_LAST_RESERVE, {
            building: tmpJSONValue.building,
            carName: tmpJSONValue.carName,
            carNumber: tmpJSONValue.carNumber,
            note: tmpJSONValue.note
          });
        }
      })
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
