<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-button" :class="classObject">
        <!--========== BUTTON =================================-->
        <!--===================================================-->

        <!--======= DEFAULT =========================-->
        <!--=========================================-->
        <button
            v-if="mode === 'button'"
            ref="button"
            class="vue-button"
            type="button"
            :disabled="disabled"
            :title="title"
            :tabindex="tabindex"
            :aria-label="ariaLabel"
            @click="buttonClick($event)"
            @keydown="buttonKeyDown($event)"
            @keyup="buttonKeyUp($event)"
            @focus="buttonFocus($event)"
            @blur="buttonBlur($event)"
        >
            <!--==== BUTTON CONTENT START =====-->
            <span v-if="iconLeftComputed || iconRightComputed" class="vue-button-content">
                <gen1023-icon
                    v-if="iconLeftComputed"
                    class="vue-ci-icon-left"
                    :class="iconLeftCssClass"
                    :name="iconLeftName"
                    :iconSet="iconLeftIconSet"
                    :title="iconLeftTitle"
                    :ariaHidden="iconLeftAriaHidden"
                >
                    <slot name="iconLeft"></slot>
                </gen1023-icon>
                <span class="vue-text"><slot></slot></span>
                <gen1023-icon
                    v-if="iconRightComputed"
                    class="vue-ci-icon-right"
                    :class="iconRightCssClass"
                    :name="iconRightName"
                    :iconSet="iconRightIconSet"
                    :title="iconRightTitle"
                    :ariaHidden="iconRightAriaHidden"
                >
                    <slot name="iconRight"></slot>
                </gen1023-icon>
            </span>

            <span v-else class="vue-text"
                ><slot>{{ caption }}</slot></span
            >
            <!--==== BUTTON CONTENT END =======-->
        </button>

        <!--========== ANCHOR =================================-->
        <!--===================================================-->
        <template v-else-if="mode === 'anchor'">
            <!--======= DISABLED ========================-->
            <!--=========================================-->
            <!-- if button in anchor mode is disabled, use html element button which allows disabled state -->
            <button
                v-if="disabled"
                ref="button"
                class="vue-button"
                type="button"
                :disabled="disabled"
                :title="title"
                :tabindex="tabindex"
                :aria-label="ariaLabel"
                @click="buttonClick($event)"
                @keydown="buttonKeyDown($event)"
                @keyup="buttonKeyUp($event)"
                @focus="buttonFocus($event)"
                @blur="buttonBlur($event)"
            >
                <!--==== BUTTON CONTENT START =====-->
                <span v-if="iconLeftComputed || iconRightComputed" class="vue-button-content">
                    <gen1023-icon
                        v-if="iconLeftComputed"
                        class="vue-ci-icon-left"
                        :class="iconLeftCssClass"
                        :name="iconLeftName"
                        :iconSet="iconLeftIconSet"
                        :title="iconLeftTitle"
                        :ariaHidden="iconLeftAriaHidden"
                    >
                        <slot name="iconLeft"></slot>
                    </gen1023-icon>
                    <span class="vue-text"><slot></slot></span>
                    <gen1023-icon
                        v-if="iconRightComputed"
                        class="vue-ci-icon-right"
                        :class="iconRightCssClass"
                        :name="iconRightName"
                        :iconSet="iconRightIconSet"
                        :title="iconRightTitle"
                        :ariaHidden="iconRightAriaHidden"
                    >
                        <slot name="iconRight"></slot>
                    </gen1023-icon>
                </span>

                <span v-else class="vue-text"
                    ><slot
                        ><slot>{{ caption }}</slot></slot
                    ></span
                >
                <!--==== BUTTON CONTENT END =======-->
            </button>

            <!--======= DEFAULT =========================-->
            <!--=========================================-->
            <a
                v-else
                ref="button"
                class="vue-button"
                :href="link"
                :target="linkNewWindow ? '_blank' : null"
                :disabled="disabled"
                :title="title"
                :tabindex="tabindex"
                :aria-label="ariaLabel"
                @click="buttonClick($event)"
                @keydown="buttonKeyDown($event)"
                @keyup="buttonKeyUp($event)"
                @focus="buttonFocus($event)"
                @blur="buttonBlur($event)"
            >
                <!--==== BUTTON CONTENT START =====-->
                <span v-if="iconLeftComputed || iconRightComputed" class="vue-button-content">
                    <gen1023-icon
                        v-if="iconLeftComputed"
                        class="vue-ci-icon-left"
                        :class="iconLeftCssClass"
                        :name="iconLeftName"
                        :iconSet="iconLeftIconSet"
                        :title="iconLeftTitle"
                        :ariaHidden="iconLeftAriaHidden"
                    >
                        <slot name="iconLeft"></slot>
                    </gen1023-icon>
                    <span class="vue-text"><slot></slot></span>
                    <gen1023-icon
                        v-if="iconRightComputed"
                        class="vue-ci-icon-right"
                        :class="iconRightCssClass"
                        :name="iconRightName"
                        :iconSet="iconRightIconSet"
                        :title="iconRightTitle"
                        :ariaHidden="iconRightAriaHidden"
                    >
                        <slot name="iconRight"></slot>
                    </gen1023-icon>
                </span>

                <span v-else class="vue-text"
                    ><slot
                        ><slot>{{ caption }}</slot></slot
                    ></span
                >
                <!--==== BUTTON CONTENT END =======-->
            </a>
        </template>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1023Icon from '../../gen/gen1023-icon/gen1023-icon';

//=== MISC
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1006Button',
    components: {
        Gen1023Icon
    },
    props: {
        type: {
            default: config.buttonTypes[0],
            type: String,
            validator: value => {
                return config.buttonTypes.includes(value);
            }
        },
        importance: {
            default: config.buttonImportances[0],
            type: String,
            validator: value => {
                return config.buttonImportances.includes(value);
            }
        },
        mode: {
            default: 'button',
            type: String,
            validator: value => {
                return ['button', 'anchor'].indexOf(value) !== -1;
            }
        },
        active: Boolean,
        disabled: Boolean,
        title: {
            default: null,
            type: String
        },
        caption: String,
        captionHidden: Boolean,
        iconLeft: Boolean,
        iconLeftName: String,
        iconLeftIconSet: String,
        iconLeftTitle: String,
        iconLeftAriaHidden: {
            default: true,
            type: Boolean
        },
        iconLeftCssClass: {
            default() {
                return null;
            },
            type: [Object, Array, String]
        },
        iconRight: Boolean,
        iconRightName: String,
        iconRightIconSet: String,
        iconRightTitle: String,
        iconRightAriaHidden: {
            default: true,
            type: Boolean
        },
        iconRightCssClass: {
            default() {
                return null;
            },
            type: [Object, Array, String]
        },
        tabindex: {
            default: null,
            type: Number,
            validator: value => {
                return value === -1 || value === 0;
            }
        },
        link: {
            default: '#',
            type: String
        },
        linkNewWindow: Boolean,
        preventLosingFocus: Boolean,
        ariaLabel: String,
        id: String
    },
    computed: {
        classObject() {
            return {
                [`vue-is-${this.type}`]: this.type,
                [`vue-is-${this.importance}`]: this.importance,
                [`vue-is-mode-${this.modeComputed}`]: this.modeComputed,
                'vue-is-active': this.active,
                'vue-is-disabled': this.disabled,
                'vue-has-caption-hidden': this.captionHidden,
                'vue-has-icon-left': this.iconLeftComputed,
                'vue-has-icon-right': this.iconRightComputed
            };
        },
        // TODO REVIEW: do we need add Computed suffix to all computed properties?
        modeComputed() {
            // if button in anchor mode is disabled, use html element button which supports disabled state
            if (this.mode === 'anchor' && this.disabled) {
                return 'button';
            } else {
                return this.mode;
            }
        },
        iconLeftComputed() {
            return this.iconLeft || this.$slots.iconLeft !== undefined;
        },
        iconRightComputed() {
            return this.iconRight || this.$slots.iconRight !== undefined;
        }
    },
    mounted() {
        if (this.$refs.button) {
            this.$refs.button.addEventListener('mousedown', this.buttonMouseDown);
        }
    },
    destroyed() {
        if (this.$refs.button) {
            this.$refs.button.removeEventListener('mousedown', this.buttonMouseDown);
        }
    },
    methods: {
        buttonClick(event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('buttonClickEvent', event);
        },
        buttonKeyDown(event) {
            this.$emit('buttonKeyDownEvent', event);
        },
        buttonKeyUp(event) {
            this.$emit('buttonKeyUpEvent', event);
        },
        buttonFocus(event) {
            this.$emit('buttonFocusEvent', event);
        },
        buttonBlur(event) {
            this.$emit('buttonBlurEvent', event);
        },
        buttonMouseDown(event) {
            if (this.preventLosingFocus) {
                event.preventDefault();
            }
            this.$emit('buttonMouseDownEvent', event);
        }
    }
};
</script>
