import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { version } from '../package.json';

export default function(Vue) {
  // We do not want to use it in private builds right now (internal inTune etc.)
  if ( process.env.VUE_APP_MODE === 'staging' || process.env.VUE_APP_MODE === 'prod') {
    Sentry.init({
      Vue,
      dsn: 'https://31ed406f0ef6473fba6523073639e658@o504823.ingest.sentry.io/6106623',
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1, // for now, 20% recorded transactions globally is enough
      environment: process.env.VUE_APP_MODE,
      release: 'nastup@' + version
    });
  }
}
