import Frm1013Textarea from './frm1013-textarea';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1013Textarea);
    }
};

use(Plugin);

export default Plugin;

export { Frm1013Textarea };
