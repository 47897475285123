// === GENERAL ==============================//
export const TOGGLE_DASHBOARD_MENU = 'TOGGLE_DASHBOARD_MENU';
export const TOGGLE_DASHBOARD_SUBMENU = 'TOGGLE_DASHBOARD_SUBMENU';
export const GENERATE_YEAR_AND_WEEK = 'GENERATE_YEAR_AND_WEEK';
export const GET_APPLICATION_SETTINGS = 'GET_APPLICATION_SETTINGS';
export const TOGGLE_PUSH_NOTIFICATIONS = 'TOGGLE_PUSH_NOTIFICATIONS';
export const SUBSCRIBE_PUSH_NOTIFICATIONS = 'SUBSCRIBE_PUSH_NOTIFICATIONS';
export const UNSUBSCRIBE_PUSH_NOTIFICATIONS = 'UNSUBSCRIBE_PUSH_NOTIFICATIONS';
export const LOAD_PUSH_NOTIFICATIONS_STATUS = 'LOAD_PUSH_NOTIFICATIONS_STATUS';
export const OPEN_DASHBOARD_NAVIGATION = 'OPEN_DASHBOARD_NAVIGATION';
export const CLOSE_DASHBOARD_NAVIGATION = 'CLOSE_DASHBOARD_NAVIGATION';
export const FETCH_NEW_VERSION_INFO = 'FETCH_NEW_VERSION_INFO';
export const MAKE_SURVEY_CONFIG = 'MAKE_SURVEY_CONFIG';

// === PERSISTENT STORAGE ===================//
export const REMOVE_FORVARDINO_EVENT = 'REMOVE_FORVARDINO_EVENT';
export const CHECK_FOOD_ESHOP_TOKEN_TIMESTAMPS = 'CHECK_FOOD_ESHOP_TOKEN_TIMESTAMPS';
export const PRIVATE_USER_DATA = 'PRIVATE_USER_DATA';

// === USER =================================//
export const REGISTER_DEVICE_SYNC = 'REGISTER_DEVICE_SYNC';
export const REMOVE_DEVICE_SYNC = 'REMOVE_DEVICE_SYNC';
export const CHECK_DEVICE_SYNC_STATUS = 'CHECK_DEVICE_SYNC_STATUS';
export const GET_SYNC_DATA = 'GET_SYNC_DATA';
export const SET_SYNC_STATIC = 'SET_SYNC_STATIC';
export const SET_SYNC_LINKED = 'SET_SYNC_LINKED';
export const MAKE_SYNC_REMINDER_TIMESTAMP = 'MAKE_SYNC_REMINDER_TIMESTAMP';
export const MAKE_INSTALL_REMINDER_TIMESTAMP = 'MAKE_INSTALL_REMINDER_TIMESTAMP';
export const LOGOUT_USER = 'LOGOUT_USER';
export const TOGGLE_PROFILE_MENU = 'TOGGLE_PROFILE_MENU';
// authorized mode actions
export const AM_LOAD_AUTHORIZED_MODE_PROFILE = 'AM_LOAD_AUTHORIZED_MODE_PROFILE'

// === APP NOTIFICATIONS ========================//
export const TOGGLE_APP_NOTIFICATIONS_MENU = 'TOGGLE_APP_NOTIFICATIONS_MENU';
export const FETCH_APP_NOTIFICATIONS = 'FETCH_APP_NOTIFICATIONS';
export const APP_NOTIFICATIONS_READ_ALL = 'FETCH_APP_NOTIFICATIONS_READ_ALL';
export const APP_NOTIFICATION_READ = 'FETCH_APP_NOTIFICATION_READ';

// === DATA STORE ===========================//
export const GET_PREFETCH_CATEGORIES = 'GET_PREFETCH_CATEGORIES';
export const HANDLE_ARTICLES_IDS = 'HANDLE_ARTICLES_IDS';
export const FETCH_ARTICLES = 'FETCH_ARTICLES';
export const FETCH_ITEMS_DATA = 'FETCH_ITEMS_DATA';
export const FETCH_RESTAURANT_SHQ = 'FETCH_RESTAURANT_SHQ';
export const FETCH_RESTAURANT_NHQ = 'FETCH_RESTAURANT_NHQ';
export const FETCH_RESTAURANT_HHQ = 'FETCH_RESTAURANT_HHQ';
export const FETCH_RECOMMENDED_POSTS = 'FETCH_RECOMMENDED_POSTS';

// === POPUP ===============================//
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const POPUP_POSITION = 'POPUP_POSITION';

// === EVENTS ==============================//
export const FETCH_EVENTS_CATEGORIES = 'FETCH_EVENTS_CATEGORIES';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FILTER_EVENTS = 'FILTER_EVENTS';
export const RESET_ALL_EVENTS = 'RESET_ALL_EVENTS';

// === FORVARDINO =========================//
export const FETCH_FORVARDINO_FILTER_DATA = 'FETCH_FORVARDINO_DATA';
export const RESET_ALL_FORVARDINO_FILTERS = 'RESET_ALL_FORVARDINO_FILTERS';

// === WORKOUTS ============================//
export const FETCH_WORKOUTS = 'FETCH_WORKOUTS';
export const FETCH_WORKOUTS_QUARTERS = 'FETCH_WORKOUTS_QUARTERS';

// === CLIENT SATISFACTION =================//
export const FETCH_NPS_DATA = 'FETCH_NPS_DATA';

// === COVID 19 ============================//
export const FETCH_COVID19_FAQ_DATA = 'FETCH_COVID19_FAQ_DATA';

// === TRIO ================================//
export const FETCH_TRIO_DATA = 'FETCH_TRIO_DATA';

// === SURVEY ==============================//
export const MAKE_SURVEY_CONTENT = 'MAKE_SURVEY_CONTENT';

// === ARTICLES ============================//
export const RESET_SELECTED_CATEGORIES = 'RESET_SELECTED_CATEGORIES';

// === FOOD ESHOP ==========================//
export const HANDLE_FOOD_ESHOP_ITEM_QUANTITY_DECREASE = 'HANDLE_FOOD_ESHOP_ITEM_QUANTITY_DECREASE';

// === BENEFITS ============================//
export const FETCH_BENEFITS_FILTER_DATA = 'FETCH_BENEFITS_FILTER_DATA';
export const RESET_ALL_BENEFITS_FILTER = 'RESET_ALL_BENEFITS_FILTER';
