// === GENERAL ==============================//
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE';
export const SET_DASHBOARD_MENU = 'SET_DASHBOARD_MENU';
export const SET_DASHBOARD_SUBMENU = 'SET_DASHBOARD_SUBMENU';
export const SET_DASHBOARD_SUBMENU_CATEGORY = 'SET_DASHBOARD_SUBMENU_CATEGORY';
export const SET_BACK_TO_NAVIGATION = 'SET_BACK_TO_NAVIGATION';
export const SET_DRAWER_IS_OPENED = 'SET_DRAWER_IS_OPENED';
export const SET_SW_REGISTRATION = 'SET_SW_REGISTRATION';
export const SET_SW_INSTANCE = 'SET_SW_INSTANCE';
export const SET_APP_UPDATE_READY = 'SET_APP_UPDATE_READY';
export const SET_APP_UPDATE_FAILED = 'SET_APP_UPDATE_FAILED';
export const SET_APP_VERSION = 'SET_APP_VERSION';
export const SET_APPLICATION_CONFIG = 'SET_APPLICATION_CONFIG';
export const SET_SURVEY_CONFIG = 'SET_SURVEY_CONFIG';
export const SET_NAVIGATION_DATA = 'SET_NAVIGATION_DATA';
export const SET_DASHBOARD_BUTTONS = 'SET_DASHBOARD_BUTTONS';
export const SET_DASHBOARD_GROUP_BUTTONS = 'SET_DASHBOARD_GROUP_BUTTONS';
export const SET_PUSH_NOTIFICATIONS_STATUS = 'SET_PUSH_NOTIFICATIONS_STATUS';
export const SET_PUSH_NOTIFICATIONS_ENDPOINT = 'SET_PUSH_NOTIFICATIONS_ENDPOINT';
export const SET_PUSH_NOTIFICATIONS_PERMISSION = 'SET_PUSH_NOTIFICATIONS_PERMISSION';
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR';
export const SET_STATISTICS_TIMESTAMP = 'SET_STATISTICS_TIMESTAMP';
export const SET_VERSION_ON_SERVER = 'SET_VERSION_ON_SERVER';
export const SET_APP_CHANGELOG = 'SET_APP_CHANGELOG';
export const SET_COVID19_ACTIVE_CATEGORY = 'SET_COVID19_ACTIVE_CATEGORY';
export const START_PROCESS = 'START_PROCESS';
export const STOP_PROCESS = 'STOP_PROCESS';

// === PERSISTENT STORAGE ===================//
export const SET_YEAR_AND_WEEK = 'SET_YEAR_AND_WEEK';
export const SET_REGISTERED_EVENT = 'SET_REGISTERED_EVENT';
export const REMOVE_REGISTERED_EVENT = 'REMOVE_REGISTERED_EVENT';
export const SET_REGISTERED_FORVARDINO_EVENT = 'SET_REGISTERED_FORVARDINO_EVENT';
export const REMOVE_REGISTERED_FORVARDINO_EVENT = 'REMOVE_REGISTERED_FORVARDINO_EVENT';
export const SET_RATED_FORVARDINO_EVENT = 'SET_RATED_FORVARDINO_EVENT';
export const REMOVE_RATED_FORVARDINO_EVENT = 'REMOVE_RATED_FORVARDINO_EVENT';
export const SET_REGISTERED_WORKOUT = 'SET_REGISTERED_WORKOUT';
export const REMOVE_REGISTERED_WORKOUT = 'REMOVE_REGISTERED_WORKOUT';
export const POSTPONE_PUSH_NOTIFICATIONS = 'POSTPONE_PUSH_NOTIFICATIONS';
export const SET_LAST_FOOD_RATING_TIMESTAMP = 'SET_LAST_FOOD_RATING_TIMESTAMP';
export const SET_FILLED_SURVEY_ID = 'SET_FILLED_SURVEY_ID';
export const SET_HAS_FILLED_SURVEY = 'SET_HAS_FILLED_SURVEY';
export const SET_FOOD_ESHOP_FINISHED_ORDER_TOKEN = 'SET_FOOD_ESHOP_FINISHED_ORDER_TOKEN';
export const REMOVE_FOOD_ESHOP_FINISHED_ORDER_TOKEN = 'REMOVE_FOOD_ESHOP_FINISHED_ORDER_TOKEN';
export const SET_BUILDING_HHQ_INTRO_VISITED = 'SET_BUILDING_HHQ_INTRO_VISITED';
export const SET_PREVIOUS_SELECTED_RESTAURANT = 'SET_PREVIOUS_SELECTED_RESTAURANT';
export const SET_PREVIOUS_SELECTED_BRANCH = 'SET_PREVIOUS_SELECTED_BRANCH';
export const SET_APPLICANT_FORM_STATUS = 'SET_APPLICANT_FORM_STATUS';

// === DATA STORE ===========================//
export const SET_CONTENT_DATA_UPDATE = 'SET_CONTENT_DATA_UPDATE';
export const UPDATE_ARTICLES = 'UPDATE_ARTICLES';
export const UPDATE_RESTAURANTS = 'UPDATE_RESTAURANTS';
export const UPDATE_RESTAURANTS_EXTERNAL = 'UPDATE_RESTAURANTS_EXTERNAL';
export const UPDATE_RECOMMENDED_POSTS = 'UPDATE_RECOMMENDED_POSTS';

// === ARTICLES ============================//
export const SET_NEWS_ACTIVE_CATEGORY = 'SET_NEWS_ACTIVE_CATEGORY';
export const SHOW_FILTERED_ARTICLES = 'SHOW_FILTERED_ARTICLES';
export const ADD_FILTERED_ARTICLES = 'ADD_FILTERED_ARTICLES';

// === USER ================================//
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SET_IS_LOGGED = 'SET_IS_LOGGED';
export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';
export const SET_SYNC_DATA = 'SET_SYNC_DATA';
export const SET_SYNC_REGISTRATION = 'SET_SYNC_REGISTRATION';
export const SET_SYNC_REMINDER_TIMESTAMP = 'SET_SYNC_REMINDER_TIMESTAMP';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_BROWSER = 'SET_BROWSER';
export const SET_IS_STANDALONE = 'SET_IS_STANDALONE';
export const SET_SYSTEM_VERSION = 'SET_SYSTEM_VERSION';
export const SET_INSTALL_REMINDER_TIMESTAMP = 'SET_INSTALL_REMINDER_TIMESTAMP';
export const SET_FORVARDINO_NEWSLETTER_STATE = 'SET_FORVARDINO_NEWSLETTER_STATE';
// authorized mode mutations
export const AM_SET_USER_PROFILE = 'AM_SET_USER_PROFILE';
export const AM_SET_USER_PROFILE_PHOTO = 'AM_SET_USER_PROFILE_PHOTO';

// === PROFILE =============================//
export const SET_PROFILE_MENU = 'SET_PROFILE_MENU';

// === APP NOTIFICATIONS ========================//
export const SET_APP_NOTIFICATIONS_MENU = 'SET_APP_NOTIFICATIONS_MENU';
export const SET_APP_NOTIFICATIONS = 'SET_APP_NOTIFICATIONS';
export const SET_APP_NOTIFICATIONS_RESET = 'SET_APP_NOTIFICATIONS_RESET';

// === DASHBOARD ===========================//
export const SET_SELECTED_BUILDING = 'SET_SELECTED_BUILDING';
export const SET_DASHBOARD_BACKGROUND = 'SET_DASHBOARD_BACKGROUND';

// === POPUP ===============================//
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';
export const SET_ACTIVE_POPUP_MODULE = 'SET_ACTIVE_POPUP_MODULE';
export const SET_POPUP_POSITION = 'SET_POPUP_POSITION';

// === EVENTS ==============================//
export const SET_ALL_EVENTS = 'SET_ALL_EVENTS';
export const SET_EVENTS_CATEGORIES = 'SET_EVENTS_CATEGORIES';
export const SET_EVENTS_IS_LOADED = 'SET_EVENTS_IS_LOADED';
export const SET_FILTERED_CATEGORIES = 'SET_FILTERED_CATEGORIES';
export const SET_FILTERED_EVENTS = 'SET_FILTERED_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';
export const SET_EVENT_REGISTRATION_ID = 'SET_EVENT_REGISTRATION_ID';

// === RECRUITMENT ==============================//
export const SET_RECRUITMENT_FILTER_LOCATION = 'SET_RECRUITMENT_FILTER_LOCATION';
export const SET_RECRUITMENT_FILTER_SPECIALIZATION = 'SET_RECRUITMENT_FILTER_SPECIALIZATION';
export const SET_RECRUITMENT_FILTER_TYPE = 'SET_RECRUITMENT_FILTER_TYPE';
export const SET_RECRUITMENT_FILTER_KEYWORD = 'SET_RECRUITMENT_FILTER_KEYWORD';
export const SET_RECRUITMENT_RESULT_DATA = 'SET_RECRUITMENT_RESULT_DATA';
export const SET_RECRUITMENT_REGISTRIES = 'SET_RECRUITMENT_REGISTRIES';
export const SET_RECRUITMENT_DETAIL_DATA = 'SET_RECRUITMENT_DETAIL_DATA';

// === RESTAURANTS =========================//
export const SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT';
export const SET_RESTAURANT_DAY = 'SET_RESTAURANT_DAY';
export const SET_CAFE_VISIBILITY = 'SET_CAFE_VISIBILITY';

// === FORVARDINO ==========================//
export const SET_FORVARDINO_FILTER_CATEGORIES = 'SET_FORVARDINO_FILTER_CATEGORIES';
export const SET_FORVARDINO_SELECTED_CATEGORIES = 'SET_FORVARDINO_SELECTED_CATEGORIES';
export const SET_ARE_FILTERS_ACTIVE = 'SET_ARE_FILTERS_ACTIVE';
export const SET_FORVARDINO_ACTIVE_CATEGORY = 'SET_FORVARDINO_ACTIVE_CATEGORY';
export const SET_FORVARDINO_PAGES = 'SET_FORVARDINO_PAGES';

// === COMPETITITON ========================//
export const SET_COMPETITION_ANSWER = 'SET_COMPETITION_ANSWER';
export const SET_COMPETITION_RESULT = 'SET_COMPETITION_RESULT';
export const SET_COMPETITION_SENT_RESULT = 'SET_COMPETITION_SENT_RESULT';
export const SET_COMPETITION_SENT_TIMESTAMP = 'SET_COMPETITION_SENT_TIMESTAMP';
export const SET_COMPETITION_SUMMARY_TIMESTAMP = 'SET_COMPETITION_SUMMARY_TIMESTAMP';
export const SET_COMPETITION_RESET = 'SET_COMPETITION_RESET';

// === WORKOUTS ============================//
export const SET_ALL_WORKOUTS = 'SET_ALL_WORKOUTS';
export const SET_WORKOUTS_IS_LOADED = 'SET_WORKOUTS_IS_LOADED';
export const SET_WORKOUT_BUILDING = 'SET_WORKOUT_BUILDING';
export const SET_WORKOUT_QUARTAL = 'SET_WORKOUT_QUARTAL';
export const SET_WORKOUT_REGISTRATION_DATA = 'SET_WORKOUT_REGISTRATION_DATA';
export const SET_WORKOUTS_OPTIONS = 'SET_WORKOUTS_OPTIONS';
export const SET_WORKOUT_REGISTRATION_SUCCESSFULLY = 'SET_WORKOUT_REGISTRATION_SUCCESSFULLY';
export const SET_WORKOUT_LESSON_ID = 'SET_WORKOUT_LESSON_ID';
export const SET_WORKOUTS_HAS_ERROR = 'SET_WORKOUTS_HAS_ERROR';

// === CLIENT SATISFACTION =================//
export const SET_NPS_FEEDBACK_PAGES = 'SET_NPS_FEEDBACK_PAGES';
export const SET_NPS_GRAPH_VALUES = 'SET_NPS_GRAPH_VALUES';
export const SET_NPS_IS_LOADED = 'SET_NPS_IS_LOADED';

// === TUTORIAL ============================//
export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL';
export const SET_TUTORIAL_IS_COMPLETED = 'SET_TUTORIAL_IS_COMPLETED';
export const SET_TUTORIAL_IS_NOT_COMPLETED = 'SET_TUTORIAL_IS_NOT_COMPLETED';

// === COVID-19 ============================//
export const SET_COVID19_FAQ_ITEMS = 'SET_COVID19_FAQ_ITEMS';
export const SET_COVID19_FAQ_IS_LOADED = 'SET_COVID19_FAQ_IS_LOADED';
export const SET_COVID19_HAS_ERROR = 'SET_COVID19_HAS_ERROR';

// === TRIO ================================//
export const SET_TRIO_PAGES = 'SET_TRIO_PAGES';
export const SET_TRIO_IS_LOADED = 'SET_TRIO_IS_LOADED';
export const SET_TRIO_HAS_ERROR = 'SET_TRIO_HAS_ERROR';

// === WEEK ACTIONS ========================//
export const SET_WEEK_ACTIONS_SELECTED_CATEGORIES = 'SET_WEEK_ACTIONS_SELECTED_CATEGORIES';

// === SURVEY ==============================//
export const SET_SURVEY_IS_LOADED = 'SET_SURVEY_IS_LOADED';
export const SET_SURVEY_HAS_ERROR = 'SET_SURVEY_HAS_ERROR';
export const SET_SURVEY_CONTENT = 'SET_SURVEY_CONTENT';
export const SET_SURVEY_ORGANIZATION_STRUCTURE = 'SET_SURVEY_ORGANIZATION_STRUCTURE';

// === PARKING =============================//
export const SET_PARKING_CAPACITY = 'SET_PARKING_CAPACITY';
export const SET_MY_PARKING_RESERVATIONS = 'SET_MY_PARKING_RESERVATIONS';
export const SET_PARKING_LAST_RESERVE = 'SET_PARKING_LAST_RESERVE';

// === FOOD ESHOP ==========================//
export const ADD_FOOD_ESHOP_ITEM = 'ADD_FOOD_ESHOP_ITEM';
export const REMOVE_FOOD_ESHOP_ITEM = 'REMOVE_FOOD_ESHOP_ITEM';
export const INCREASE_FOOD_ESHOP_ITEM_QUANTITY = 'INCREASE_FOOD_ESHOP_ITEM';
export const DECREASE_FOOD_ESHOP_ITEM_QUANTITY = 'DECREASE_FOOD_ESHOP_ITEM_QUANTITY';
export const SET_CART_ITEM_REFUNDABLE_BOX = 'SET_CART_ITEM_REFUNDABLE_BOX';
export const SET_SELECTED_FOOD_ESHOP_ITEM = 'SET_SELECTED_FOOD_ESHOP_ITEM';
export const SET_DELIVERY_MODAL_VISIBILITY = 'SET_CHOOSE_DELIVERY_MODAL_VISIBILITY';
export const SET_PERSONAL_DELIVERY = 'SET_PERSONAL_DELIVERY';
export const SET_SELECTED_DAY_CODE = 'SET_SELECTED_DAY_CODE';
export const SET_FOOD_ESHOP_ACTIVE_MAIN_CATEGORY = 'SET_FOOD_ESHOP_ACTIVE_MAIN_CATEGORY';
export const SET_FOOD_ESHOP_CATEGORIES = 'SET_FOOD_ESHOP_CATEGORIES';
export const ADD_FOOD_ESHOP_ORDER_DATA = 'ADD_FOOD_ESHOP_ORDER_DATA';
export const SET_DELIVERY_PRICE = 'SET_DELIVERY_PRICE';
export const SET_HAS_PERSONAL_DELIVERY = 'SET_HAS_PERSONAL_DELIVERY';
export const SET_HAS_REFUNDABLE_CASE = 'SET_HAS_REFUNDABLE_CASE';
export const SET_ACTIVE_ORDER_OBJECT = 'SET_ACTIVE_ORDER_OBJECT';
export const RESET_CART_AFTER_ORDER = 'RESET_CART_AFTER_ORDER';

// === AUCTION =============================//
export const SET_AUCTION_MEMBER = 'SET_AUCTION_MEMBER';

// === BENEFITS ============================//
export const SET_BENEFITS_FILTER_CATEGORIES = 'SET_BENEFITS_FILTER_CATEGORIES';
export const SET_BENEFITS_FILTERS_LOADED = 'SET_BENEFITS_FILTERS_LOADED';
export const SET_BENEFITS_ACTIVE_CATEGORY = 'SET_BENEFITS_ACTIVE_CATEGORY';
export const SET_BENEFITS_FILTER_ACTIVE = 'SET_BENEFITS_FILTER_ACTIVE';
export const SET_BENEFITS_SELECTED_CATEGORIES = 'SET_BENEFITS_SELECTED_CATEGORIES';

// === ONBOARDING =============================//
export const SET_ONBOARDING_USER_UUID = 'SET_ONBOARDING_USER_UUID';
export const SET_ONBOARDING_REQUEST_USER_SMS_TIMESTAMP = 'SET_ONBOARDING_REQUEST_USER_SMS_TIMESTAMP';
export const SET_ONBOARDING_REQUEST_USER_SMS_STATE = 'SET_ONBOARDING_REQUEST_USER_SMS_STATE';
export const SET_ONBOARDING_REQUEST_USER_AUTHORIZED = 'SET_ONBOARDING_REQUEST_USER_AUTHORIZED';
export const SET_ONBOARDING_REQUEST_USER_AUTHORIZED_TIMESTAMP = 'SET_ONBOARDING_REQUEST_USER_AUTHORIZED_TIMESTAMP';
export const SET_ONBOARDING_USER_FIRST_NAME = 'SET_ONBOARDING_USER_FIRST_NAME';
export const SET_ONBOARDING_USER_LAST_NAME = 'SET_ONBOARDING_USER_LAST_NAME';
