import axios from 'axios';
import * as envConfig from 'env-config';

export default {
  // ========================= Authorization ========================= //
  authRequest(userData) {
    return axios.post(envConfig.default.webServices.AUTHORIZATION_CODE, userData, { withCredentials: true }).then(response => response.data);
  },

  logoutRequest() {
    return axios.post(envConfig.default.webServices.LOGOUT, {}, { withCredentials: true }).then(response => response.data);
  },

  fetchApiDataList(publicKey, id) {
    return axios
      .get(envConfig.default.webServices.CMS_API_DATA_LIST + id, { withCredentials: true })
      .then(response => response.data);
  },

  fetchSubPages(publicKey, pageId, priv) {
    return axios
      .get(envConfig.default.webServices.CMS_SUB_PAGES + '?pageId=' + pageId + (priv ? '&private=1' : ''), { withCredentials: true })
      .then(response => response.data);
  },

  fetchPostById(publicKey, postId, priv) {
    return axios
      .get(envConfig.default.webServices.CMS_POST_DETAIL + '?post_id=' + postId + (priv ? '&private=1' : ''), { withCredentials: true })
      .then(response => response.data);
  },

  fetchApplicantDetail() {
    return axios
      .get(envConfig.default.webServices.ONBOARDING_APPLICANT, { withCredentials: true })
      .then(response => response.data);
  },

  updateApplicantDetail(applicant, mode='') {
    return axios
      .put(envConfig.default.webServices.ONBOARDING_APPLICANT + '?mode=' + mode, applicant, { withCredentials: true })
      .then(response => response.data);
  },

  fetchApplicantDocuments() {
    return axios
      .get(envConfig.default.webServices.ONBOARDING_DOCUMENTS, { withCredentials: true })
      .then(response => response.data);
  },

  fetchApplicantDocumentsCategories() {
    return axios
      .get(envConfig.default.webServices.ONBOARDING_CATEGORIES, { withCredentials: true })
      .then(response => response.data);
  },

  fetchDocument(documentID) {
    return axios
      .get(envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/' + documentID, { withCredentials: true })
      .then(response => response.data);
  },

  fetchDocumentContent(documentID) {
    return axios
      .get(envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/' + documentID + '/content', { withCredentials: true })
      .then(response => response.data);
  },

  sendDocument(filename, type, category, subcategory, content) {
    let formData = new FormData();

    if(filename) {
      formData.append('filename', filename);
    }
    if(type) {
      formData.append('type', type);
    }
    if(category) {
      formData.append('category', category);
    }
    if(subcategory) {
      formData.append('subcategory', subcategory);
    }
    if(content) {
      formData.append('file', content);
    }

    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    };
    return axios
      .post(envConfig.default.webServices.ONBOARDING_DOCUMENTS, formData, options)
      .then(response => response.data);
  },

  deleteDocument(documentID) {
    return axios
      .delete(envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/' + documentID, { withCredentials: true })
      .then(response => response.data);
  },

  setDocumentCategoryStatusUnused(documentID) {
    return axios
      .post(envConfig.default.webServices.ONBOARDING_CATEGORIES + '/' + documentID + '/unused', {}, { withCredentials: true })
      .then(response => response.data);
  },

  sendDocumentsApprovalRequest() {
    return axios
      .post(envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/approvalRequest', {}, { withCredentials: true })
      .then(response => response.data);
  },

  fetchAppNotifications() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    return axios
      .get(envConfig.default.webServices.APP_NOTIFICATIONS, config)
      .then(response => response.data);
  },

  postAppNotificationsReadAll() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    return axios
      .post(envConfig.default.webServices.APP_NOTIFICATIONS_READ_ALL, {}, config)
      .then(response => response.data);
  },

  postAppNotificationRead(id) {
    const data = {
      'id': id
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    return axios
      .post(envConfig.default.webServices.APP_NOTIFICATIONS_READ, data, config)
      .then(response => response.data);
  },

  subscribeOnServer(subscription) {
    const subscriptionData = JSON.parse(JSON.stringify(subscription));
    const data = {
      'endpoint': subscriptionData.endpoint,
      'publicKey': subscriptionData.keys.p256dh,
      'authToken': subscriptionData.keys.auth,
      'destination': window.location.hostname
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    return axios
      .post(envConfig.default.webServices.PUSH_SERVER_API_SUBSCRIPTION_CREATE, data, config)
      .then(response => response.data);
  },

  unsubscribeOnServer(endpoint) {
    const data = {
      'endpoint': endpoint
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    return axios
      .post(envConfig.default.webServices.PUSH_SERVER_API_SUBSCRIPTION_DELETE, data, config)
      .then(response => response.data);
  },

  fetchVersionFromServer() {
    return axios
      .get(envConfig.default.webServices.VERSION_CHECK)
      .then(response => response.data)
  },
}
