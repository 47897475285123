<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <gen1006-info-panel
          v-if="isDocumentationClosed"
          type="success"
        >
          <span v-html="$t('onboarding.documentationClosed')" />
        </gen1006-info-panel>
        <div
          class="vue-directory-intro"
          v-html="$t('onboarding.documentsTitle')"
        >
        </div>

        <div class="vue-b-info-controls">
          <h2
            v-if="pendingDocuments.length > 0"
            class="vue-section-title"
          >
            {{ $i18n.t('onboarding.colorDocumentPending') }}
          </h2>
          <div
            v-for="(category, index) in pendingDocuments"
            v-show="isCategoryVisible(category)"
            :key="'pending' + index"
            class="vue-control-group"
            :class="documentStatusClass(category)"
          >
            <frm1006-button
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToDocumentUpload(category)"
            >
              {{ $i18n.t('onboarding.documentsName')[category] }}
            </frm1006-button>
          </div>

          <h2
            v-if="approvedDocuments.length > 0"
            class="vue-section-title"
          >
            {{ $i18n.t('onboarding.colorDocumentApproved') }}
          </h2>
          <div
            v-for="(category, index) in approvedDocuments"
            v-show="isCategoryVisible(category)"
            :key="'approved' + index"
            class="vue-control-group"
            :class="documentStatusClass(category)"
          >
            <frm1006-button
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToDocumentUpload(category)"
            >
              {{ $i18n.t('onboarding.documentsName')[category] }}
            </frm1006-button>
          </div>

          <h2
            v-if="uploadedDocuments.length > 0"
            class="vue-section-title"
          >
            {{ $i18n.t('onboarding.colorDocumentUploaded') }}
          </h2>
          <div
            v-for="(category, index) in uploadedDocuments"
            v-show="isCategoryVisible(category)"
            :key="'uploaded' + index"
            class="vue-control-group"
            :class="documentStatusClass(category)"
          >
            <frm1006-button
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToDocumentUpload(category)"
            >
              {{ $i18n.t('onboarding.documentsName')[category] }}
            </frm1006-button>
          </div>

          <h2
            v-if="unusedDocuments.length > 0"
            class="vue-section-title"
          >
            {{ $i18n.t('onboarding.colorDocumentUnused') }}
          </h2>
          <div
            v-for="(category, index) in unusedDocuments"
            v-show="isCategoryVisible(category)"
            :key="'unused' + index"
            class="vue-control-group"
            :class="documentStatusClass(category)"
          >
            <frm1006-button
              :icon-right="true"
              class="vue-control-item"
              type="internal"
              @buttonClickEvent="goToDocumentUpload(category)"
            >
              {{ $i18n.t('onboarding.documentsName')[category] }}
            </frm1006-button>
          </div>
        </div>
      </template>
    </template>

    <!--========== BUTTON FIXED ===========================-->
    <!--===================================================-->
    <template v-slot:buttonFixed>
      <frm1006-button
        type="primary"
        class="vue-button-page-layout-fixed"
        :title="$i18n.t('onboarding.sendToControl')"
        :disabled="!isAllDocumentsReady"
        @buttonClickEvent="setAllDocumentsReady"
      >
        {{ $t('onboarding.sendToControl') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE,
  ONBOARDING_APPLICANT_DOC_CATEGORIES,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES,
  ONBOARDING_DEFAULT_NATIONALITY,
  ONBOARDING_APPLICANT_DOC_STATUSES,
} from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'OnboardingDocuments',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      ONBOARDING_APPLICANT_DOC_CATEGORIES,
      categoriesStatuses: {},
      applicant: {}
    };
  },

  computed: {
    isAllDocumentsReady() {
      return Object.keys(this.categoriesStatuses).every(category =>
        this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.uploaded ||
        this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.approved ||
        this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.unused ||
        !this.isCategoryVisible(category)
      )
    },

    isDocumentationClosed() {
      return this.applicant?.docsStatus === ONBOARDING_APPLICANT_DOC_STATUSES.sentToBank;
    },

    isHPP() {
      return this.applicant.contractType === 'HIRE_INT';
    },

    isDPP() {
      return this.applicant.contractType === 'HIRE_DPP';
    },

    applicantAge() {
      if (!this.applicant.birthDate) {
        return -1;
      }
      const birthDate = new Date(this.applicant.birthDate);
      if (!birthDate || isNaN(birthDate)) {
        return -1;
      }
      let now = new Date();
      let currentYear = now.getFullYear();
      let birthYear = birthDate.getFullYear();
      let age = currentYear - birthYear;
      if (now < new Date(birthDate.setFullYear(currentYear))) {
        age = age - 1;
      }
      return age;
    },

    approvedDocuments() {
      const result = [];
      ONBOARDING_APPLICANT_DOC_CATEGORIES.forEach((category) => {
        if (this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.approved) {
          result.push(category);
        }
      });
      return result;
    },

    pendingDocuments() {
      const result = [];
      ONBOARDING_APPLICANT_DOC_CATEGORIES.forEach((category) => {
        if (this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.pending) {
          result.push(category);
        }
      });
      return result;
    },

    uploadedDocuments() {
      const result = [];
      ONBOARDING_APPLICANT_DOC_CATEGORIES.forEach((category) => {
        if (this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.uploaded) {
          result.push(category);
        }
      });
      return result;
    },

    unusedDocuments() {
      const result = [];
      ONBOARDING_APPLICANT_DOC_CATEGORIES.forEach((category) => {
        if (this.categoriesStatuses[category] === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.unused) {
          result.push(category);
        }
      });
      return result;
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    }
  },

  mounted() {
    this.initialized = true;
    this.fetchOnboardingDocumentsData();
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    goToDocumentUpload(category) {
      if (category === 'medical' || category === 'study' || category === 'children' || category === 'declaration') {
        this.$router.push({name: 'onboarding-document-confirm', params: { category: category, company: this.applicant.company }});
      } else {
        this.$router.push({name: 'onboarding-document', params: { category: category, company: this.applicant.company, docsStatus: this.applicant.docsStatus }});
      }
    },

    getDocumentStatus(documentCategory) {
      const status = this.categoriesStatuses[documentCategory] ? this.categoriesStatuses[documentCategory] : null;

      if (!status || status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.pending) {
        return 'pending'
      }
      if (status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.uploaded) {
        return 'progress'
      }
      if (status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.approved) {
        return 'positive'
      }
      if (status === ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES.unused) {
        return 'unused'
      }
    },

    documentStatusClass(documentCategory) {
      return `vue-is-${this.getDocumentStatus(documentCategory)}`
    },
    isCategoryVisible(category) {
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.study) { // Study
        return this.applicant.student && this.applicant.student === this.$t('onboarding.person.student.options')[0]
      }
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.foreigner) { // Foreigner
        return this.applicant.nationality && this.applicant.nationality !== ONBOARDING_DEFAULT_NATIONALITY
      }
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.pension) { // Pension
        return this.applicant.pension && this.applicant.pension === this.$t('onboarding.person.pension.options')[0]
      }
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.children) { // Children
        return this.applicant.childrenCount !== null && this.applicant.childrenCount > 0
      }
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.disabled) { // Disabled
        return this.applicant.pension
          && this.applicant.pension === this.$t('onboarding.person.pension.options')[0]
          && this.applicant.pensionType
          && this.$t('onboarding.person.pensionType.optionsDisabled').includes(this.applicant.pensionType)
      }
      if (category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.medical) { // Medical - show for HPP or DPP younger than 18
        return (this.isHPP || (this.isDPP && this.applicantAge < 18))
      }
      // the following categories do not show in DPP
      if (
        category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.criminal ||
        category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.education ||
        category === ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.workhistory ||
        category === 'photo'  // photo has special handling
      ) {
        return (this.isHPP)
      }

      return true
    },

    setAllDocumentsReady() {
      this.setLoadingState(true);
      return dataLoader.sendDocumentsApprovalRequest()
        .then(() => {
          this.setLoadingState(false);
          this.goToView('onboarding-directory')
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true;
            this.setLoadingState(false);
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
        })
    },

    fetchOnboardingDocumentsData() {
      this.setLoadingState(true);

      Promise.all([
        dataLoader.fetchApplicantDocumentsCategories(),
        dataLoader.fetchApplicantDetail(),
      ])
        .then(results => {
          this.categoriesStatuses = results[0];
          this.applicant = results[1];
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        })
    },
  },
}
</script>
