<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <table :id="id" class="vue-helper vue-table-simple">
        <template v-if="columns && rows">
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="'column-' + index">
                        <div v-if="html" v-html="column.content"></div>
                        <template v-else>{{ column.content }}</template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in rows" :key="'row-' + index">
                    <td v-for="(cell, index) in row" :key="index">
                        <div v-if="html" v-html="cell"></div>
                        <template v-else>{{ cell }}</template>
                    </td>
                </tr>
            </tbody>
        </template>
    </table>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script itemType="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Hlp1001TableSimple',
    props: {
        dataFormat: {
            default: 'columnsRowsSeparate',
            type: String
        },
        columns: null,
        rows: null,
        html: Boolean,
        id: String
    },
    computed: {},
    methods: {}
};
</script>
