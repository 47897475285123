import Frm1009Password from './frm1009-password';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1009Password);
    }
};

use(Plugin);

export default Plugin;

export { Frm1009Password };
