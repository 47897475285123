import Gen1021NumericValue from './gen1021-numeric-value';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1021NumericValue);
    }
};

use(Plugin);

export default Plugin;

export { Gen1021NumericValue };
