<template>
  <div class="vue-navigation-items">
    <transition
      name="vue-fade"
      mode="out-in"
    >
      <div
        v-if="!isSubmenuOpened"
        class="vue-navigation"
      >
        <template v-for="(item, index) in navigationData">
          <!-- FIXME MSE: change BE to return proper data type for privateOnly (integer), not string -->
          <div
            :key="'item-' + index"
            class="vue-navigation-item"
            @click="revealSubitems(item, index)"
          >
            <span class="vue-name">{{ item.name }}</span>
            <span
              v-if="hasStickerNew(item)"
              class="vue-navigation-sticker vue-is-new"
            >{{ $t('general.new') }}</span>
          </div>
        </template>
      </div>
    </transition>

    <transition name="vue-fade">
      <div
        v-if="isSubmenuOpened"
        class="vue-subnavigation"
      >
        <template
          v-for="(subitem, subitemIndex) in selectedSubitemData"
        >
          <div
            :key="'subitem-' + subitemIndex"
          >
            <!-- FIXME JNO: change BE to return proper data type for privateOnly (integer), not string boolean -->
            <div
              v-if="subitem.headline"
              class="vue-subnavigation-headline"
              :class="assignHeadlineClass(subitem)"
            >
              {{ subitem.name }}
            </div>

            <div
              v-else
              class="vue-subnavigation-item vue-subitem-icon"
              :class="subnavigationClassObject(subitem)"
              @click="goToRoute(subitem)"
            >
              <div
                class="vue-subnavigation-item-link"
              >
                <span class="vue-name">
                  {{ subitem.name }}
                </span>
                <span
                  v-if="subitem.sticker.length"
                  class="vue-navigation-sticker"
                  :class="getNavItemCssClasses(subitem)"
                >
                  {{ renderTranslateKey(subitem.sticker) }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import router from '@/router';

import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'Prj1005NavigationItems',
  props: {
    openedSubItem: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedSubitem: 0
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination',
      'isSubmenuOpened',
      'navigationData',
      'originSubmenuIndex'
    ]),

    selectedSubitemData() {
      if (this.selectedSubitem === '' || !this.navigationData[this.selectedSubitem] || !this.navigationData[this.selectedSubitem].children) {
        return null;
      }

      return this.navigationData[this.selectedSubitem].children;
    },
  },

  mounted() {
    if (typeof this.originSubmenuIndex === 'number') {
      this.revealSubitems(null, this.originSubmenuIndex);
    }
  },

  methods: {
    goToRoute(subitem) {
      const routeToGo = {};

      if (subitem.routeName.startsWith('/')) {
        routeToGo.path = subitem.routeName;
      } else {
        routeToGo.name = subitem.routeName;
      }

      if (!subitem.categoryId && subitem.postId) {
        routeToGo.params = { id: this.postId };
      }

      else if (subitem.categoryId) {
        routeToGo.query = { categoryId: this.categoryId };
      }

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.CLOSE_DASHBOARD_NAVIGATION);
      router.push(routeToGo);
    },

    revealSubitems(item, index) {
      if (item && typeof item.children === 'undefined') {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_MENU);
        router.push(item.routeName);
      } else {
        this.selectedSubitem = index;
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_SUBMENU);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY, this.selectedSubitem);
      }
    },

    assignHeadlineClass(subitem) {
      return {
        // TODO: this value is temporary - stringy boolean
        'vue-no-background': subitem.background == 'false',
        'vue-restaurants-shq': subitem.title === 'SHQ' && subitem.background,
        'vue-restaurants-nhq': subitem.title === 'NHQ' && subitem.background,
      }
    },

    subnavigationClassObject(subitem) {
      return {
        ['vue-subitem-icon-' + subitem.icon]: subitem.icon,
        'vue-subitem-icon': subitem.icon || subitem.cssClass,
        [subitem.cssClass]: subitem.cssClass,
      }
    },

    hasStickerNew(subitem) {
      let hasStickerNew = false;

      if (subitem.children) {
        subitem.children.forEach(childrenItem => {
          if (childrenItem.sticker === 'new') {
            hasStickerNew = true;
          }
        });
      }

      return hasStickerNew;
    },

    getNavItemCssClasses(navItem) {
      let cssClasses = {};

      if (navItem.sticker) {
        cssClasses['vue-is-' + navItem.sticker] = true;
      }

      return cssClasses;
    },

    renderTranslateKey(key) {
      return this.$i18n.t('navigation.' + key);
    }
  },
}
</script>
