let config = {
    inputLiveUpdate: true,
    globalInputButtonClear: false,
    testConfigProp: 'propValueDefault', // TODO MBU: only for config override testing, remove when certain it works
    formElementStates: ['info', 'valid', 'warning', 'invalid'],
    infoStates: ['info', 'success', 'warning', 'error'],
    formRowSlotSizes: ['micro', 'mini', 'small', 'medium', 'large', 'full', 'auto'],
    buttonTypes: ['primary', 'secondary', 'tertiary', 'link', 'internal', 'shadow'],
    buttonImportances: ['normal', 'major', 'minor'],
    frm1027slider: {
        options: {
            tooltip: 'none',
            dragOnClick: true,
            useKeyboard: true,
            duration: 0
        }
    }
};

export default config;

export const setOptionsConfig = optionsConfig => {
    config = optionsConfig;
};
