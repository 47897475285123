import Gen1015Section from './gen1015-section';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1015Section);
    }
};

use(Plugin);

export default Plugin;

export { Gen1015Section };
