<template>
  <div
    id="app"
    class="vue-page-layout vue-building-background vue-building-background-hidden-phone"
    :class="classObject"
    :style="generateBackground"
  >
    <!-- TODO MBU: v-if="!appInitializing" to solve page transtions on page-layout? -->
    <!-- alternative is to create global event on init -->
    <router-view />
    <prj1005-navigation />

    <gen1009-overlay
      :active="appUpdaterActive && swInstance !== null && !isTutorialVisible"
      class="vue-update-overlay"
      preventClosing="all"
    >
      <template v-if="appUpdating">
        <p>{{ $t('general.updatingApp') }}</p>
        <gen1016-loading-indicator />
      </template>
      <template v-else>
        <p>
          {{ $t('general.updateAvailable') }}
        </p>
        <div
          v-if="appChangelog"
          class="vue-b-changelog"
          v-html="appChangelog"
        />
        <frm1006-button
          @buttonClickEvent="updateApplication"
        >
          {{ $t('general.update') }}
        </frm1006-button>
      </template>
    </gen1009-overlay>

    <!-- TODO MBU: autoclose this overlay? CSOBZ-501 -->
    <gen1009-overlay
      class="vue-is-info-panel"
      :active.sync="hasError"
      :buttonClose="true"
      :closeAfter="ERROR_MODAL_TIMEOUT"
    >
      <gen1006-info-panel
        type="error"
      >
        {{ $t('error.generic') }}
      </gen1006-info-panel>
    </gen1009-overlay>

    <gen1009-overlay :active="networkError">
      <h1 class="vue-overlay-heading">
        {{ $t('general.networkUnstable') }}
      </h1>
      <p>{{ $t('general.contentPossiblyOutdated') }}</p>
      <frm1006-button
        type="primary"
        @buttonClickEvent="confirmNetworkProblems"
      >
        {{ $t('general.acknowledge') }}
      </frm1006-button>
    </gen1009-overlay>

    <div class="vue-orientation-landscape">
      <div class="vue-b-content">
        {{ $t('general.changeOrientationPlease') }}
      </div>
    </div>

    <gen1009-overlay
      :active="isInMaintenance"
      preventClosing="all"
      class="vue-maintenance-overlay vue-is-full-screen"
    >
      <img
        class="vue-zapka-logo"
        alt="Zapka Logo"
        src="../public/img/logo/intro-logo-with-branding.svg"
      >
      <h1 class="vue-overlay-heading">
        {{ $t('general.maintenanceHeading') }}
      </h1>
      <p>{{ $t('general.maintenanceContent') }}</p>
      <gen1016-loading-indicator :active="appConfigReloading" />
    </gen1009-overlay>

    <gen1009-overlay
      class="vue-is-position-bottom"
      :active.sync="showInstallReminderModal"
    >
      <prj1035-pwa-installer
        @closePwaInstallerOverlay="closeInstallReminderModal"
      />
    </gen1009-overlay>

    <gen1009-overlay
      :active="appUpdateFailedActive && !isTutorialVisible"
      class="vue-update-overlay"
      preventClosing="all"
    >
      {{ $t('general.appUpdateFailed') }}

      <frm1006-button
        @buttonClickEvent="manuallyRefreshApp"
      >
        {{ $t('userSettings.restartApp') }}
      </frm1006-button>
      <frm1006-button
        @buttonClickEvent="cancelUpdate"
      >
        {{ $t('userSettings.continueWithOldVersion') }}
      </frm1006-button>
    </gen1009-overlay>

    <!-- TODO MSE: this is just a temporary solution until global loaders are introduced - CSOBZ-324 -->
    <div
      v-if="appInitializing"
      class="vue-app-loading"
    >
      <gen1016-loading-indicator
        :active="true"
      />
    </div>
  </div>
</template>

<script>
import logger from './utils/logger';
import { mapState, mapGetters } from 'vuex';

import EVENT_BUS from '@/event-bus';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATION_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import * as STRING_VALUES from '@/store/string-values';
import '../themes/theme-zapp-2/css/profile-default/profile/profile.scss';

import Prj1005Navigation from '@/components/prj1005-navigation/prj1005-navigation';
import Prj1035PwaInstaller from '@/components/prj1035-pwa-installer/prj1035-pwa-installer';

import UtilsBrowser from '@/utils/utils-browser';

export default {
  name: 'NastupApp',
  components: {
    Prj1005Navigation,
    Prj1035PwaInstaller
  },

  data() {
    return {
      appInitializing: false,
      appConfigReloading: false,
      appUpdating: false,
      appUpdaterActive: false,
      appUpdateFailedActive: false,
      hasError: false,
      subscribingPushNotifications: false,
      currentDateTime: new Date(),
      workarounds: {
        iOSOrientationTimeout: null,
        iOSLayoutSelector: 'vue-page-layout'
      },
      ERROR_MODAL_TIMEOUT,
      showInstallReminderModal: false,
      orientation: this.ORIENTATION_LANDSCAPE,
      allowedZeroPaddingLayout: [
        'dashboard',
        'pages',
        'user-settings',
        'login',
        'onboarding-photo',
        'onboarding-faq',
        'onboarding-article',
        'onboarding-directory',
        'onboarding-documents',
        'onboarding-document-confirm',
        'onboarding-document',
        'onboarding-form',
        'onboarding-contacts',
      ],
      allowedFullscreenLayout: [],
      enabledScrolling: []
    }
  },

  computed: {
    ...mapState('persistentStorage', [
      'pushHintPostponedUntil'
    ]),
    ...mapState('user', [
      'isLogged',
      'publicKey',
      'deviceId',
      'platform',
      'installReminderTimestamp',
      'isStandalone',
      'amUserProfile'
    ]),
    ...mapState('general', [
      'appUpdateReady',
      'appUpdateFailed',
      'swRegistration',
      'swInstance',
      'swInstanceWaiting',
      'appChangelog',
      'appDestination',
      'isMenuOpened',
      'applicationConfig',
      'applicationConfigLoaded',
      'navigationData',
      'pushNotificationsSubscribed',
      'pushNotificationsPermission',
      'networkError'
    ]),
    ...mapState('popup', [
      'isPopupVisible',
      'categories'
    ]),
    ...mapState('tutorial', [
      'isTutorialVisible'
    ]),
    ...mapState('onboarding', {
      authorized: state => state.user.authorized
    }),
    ...mapGetters('general',
      {
        'pushBrowserSupport': GETTERS_CONSTANTS.PUSH_BROWSER_SUPPORT
      }),

    isInMaintenance() {
      if(this.applicationConfig && typeof this.applicationConfig.customFields === 'object' && this.applicationConfig.customFields.length) {
        // TODO MSE: create getter providing converted key-based custom fields and replace forEach here
        for(let item of this.applicationConfig.customFields) {
          if(item.key === 'SERVICE_MAINTENANCE') {
            return item.value === 'true'; // TODO MSE: upgrade custom fields to provide values in proper data-types, not strings only
          }
        }
      }
      return false;
    },

    classObject() {
      return {
        'vue-zero-padding-layout': this.allowedZeroPaddingLayout.includes(this.currentRoute),
        'vue-fullscreen-layout': this.allowedFullscreenLayout.includes(this.currentRoute),
        'vue-is-scroll': this.enabledScrolling.includes(this.currentRoute),
        [`vue-is-${this.orientation}`]: this.orientation,
        [`vue-is-${this.platform}`]: this.platform,
        'vue-is-unsupported-browser': !this.usesSupportedBrowser
      }
    },

    currentRoute() {
      return this.$route.name;
    },

    showPushRegistrationTip() {
      if(!this.pushBrowserSupport || !this.swInstance || !this.isLogged || this.pushNotificationsSubscribed === null) {
        return false;
      }

      let currentTimeSecs = Math.floor(new Date().getTime() / 1000);
      let postponed = false;

      if(this.pushHintPostponedUntil) {
        postponed = currentTimeSecs < this.pushHintPostponedUntil;
      }

      return !this.appUpdating && !this.pushNotificationsSubscribed && this.pushNotificationsPermission !== 'denied' && !postponed;
    },

    isBuildingSet() {
      return typeof this.selectedBuilding !== 'undefined';
    },
    generateBackground() {
      return 'background-image: url(/img/bg/kampus.jpg)';
    },
  },

  watch: {
    appUpdateReady() {
      if (this.appUpdateReady) {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.FETCH_NEW_VERSION_INFO)
          .finally(() => {
            this.appUpdaterActive = true;
          });
      } else {
        this.appUpdaterActive = false;
      }
    },

    appUpdateFailed() {
      this.appUpdateFailedActive = this.appUpdateFailed && !this.appUpdaterActive;
    },

    isPopupVisible(value) {
      logger.info('popup active = ' + value);
    },

    swInstance() {
      this.loadPushNotificationsStatus();
    },
  },

  created() {
    this.removeFastResponseDomElements();
    this.detectPlatform();
    this.detectSystemVersion();
    this.detectBrowser();
    this.detectStandalone();
    this.verticalHeightCorrection();

    window.addEventListener('resize', this.onWindowResize);
    document.addEventListener('visibilitychange', this.onDocumentVisibilityChange);
    document.addEventListener('orientationchange', this.onOrientationChange);
    logger.info('LOGGER => Nástup has been loaded');
  },

  mounted() {
    this.calculateViewportSize();
    this.detectScreenOrientation();
    this.openInstallerPrompt();
    this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_APP_VERSION, this.getVersionFromHTML());
    if (this.authorized) {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS);
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    document.removeEventListener('visibilitychange', this.onDocumentVisibilityChange);
    document.removeEventListener('orientationchange', this.onOrientationChange);
  },

  methods: {
    loadPushNotificationsStatus() {
      if(this.pushBrowserSupport) {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.LOAD_PUSH_NOTIFICATIONS_STATUS)
          .catch(error => {
            logger.error(error);
          })
      }
    },

    detectPlatform() {
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      let android = /(android)/i.test(navigator.userAgent);
      let platform = STRING_VALUES.PLATFORM_OTHER;

      if(iOS) {
        platform = STRING_VALUES.PLATFORM_IOS;
      }

      else if(android) {
        platform = STRING_VALUES.PLATFORM_ANDROID;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_PLATFORM, platform)
    },

    detectSystemVersion() {
      let version = STRING_VALUES.SYSTEM_VERSION_UNKNOWN;

      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
        let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        version = parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10);
      }

      else if(/android\s([0-9.]*)/.test(navigator.userAgent.toLowerCase())) {
        let match = navigator.userAgent.toLowerCase().match(/android\s([0-9.]*)/i);
        version = match ? match[1] : STRING_VALUES.SYSTEM_VERSION_UNKNOWN;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_SYSTEM_VERSION, version)
    },

    detectBrowser() {
      let userAgentConstructor = new UtilsBrowser();
      let userAgent = userAgentConstructor.init();

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_BROWSER, userAgent.browser.name);
    },

    detectStandalone() {
      let isInStandaloneModeIOS = ('standalone' in window.navigator) && (window.navigator.standalone);
      let isInStandaloneModeAndroid = (window.matchMedia('(display-mode: standalone)').matches);
      let isStandalone = isInStandaloneModeIOS || isInStandaloneModeAndroid;

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATION_CONSTANTS.SET_IS_STANDALONE, isStandalone)
    },

    /*
     * We need to fix orientation in JS for following reasons:
     * - iOS PWA does not support manifest orientation
     * - app running in web browser
     * - in future, when supported, there is a possibility of use https://developer.mozilla.org/en-US/docs/Web/API/Screen/lockOrientation#Browser_compatibility
     *
     * Current solution is based on:
     * https://stackoverflow.com/questions/1207008/how-do-i-lock-the-orientation-to-portrait-mode-in-a-iphone-web-application
     * https://stackoverflow.com/questions/5298467/prevent-orientation-change-in-ios-safari
     */

    detectScreenOrientation() {
      this.orientation = screen.width > screen.height ? STRING_VALUES.ORIENTATION_LANDSCAPE : STRING_VALUES.ORIENTATION_PORTRAIT;

      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        this.orientation = this.detectOrientation();

        /**
         * WORKAROUND: iOS recalculates values with some delay, but not synchronously and breaks
         * vh CSS variable. We must recalculate after some time (500ms is enough).
         * This occurs ONLY in PWA mode, in Safari problem does not exist. Remove, when
         * iOS fixes issue.
         * Inspired by: https://github.com/dimsemenov/PhotoSwipe/issues/1315
         */
        clearTimeout(this.workarounds.iOSOrientationTimeout);

        this.workarounds.iOSOrientationTimeout = setTimeout(() => {
          this.verticalHeightCorrection();
        }, 500);
      }
    },

    openInstallerPrompt() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
          .then(() => {
            if (this.platform !== STRING_VALUES.PLATFORM_OTHER && !this.isStandalone && this.currentDateTime.getTime() > this.installReminderTimestamp) {
              this.showInstallReminderModal = true;
              this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP);
            }
          });
      }
    },

    /**
     * WORKAROUND: iOS recalculates values with some delay, but not synchronously and breaks
     * vh CSS variable. We must recalculate after some time (500ms is enough).
     * This occurs ONLY in PWA mode, in Safari problem does not exist. Remove, when
     * iOS fixes issue.
     * Inspired by: https://github.com/dimsemenov/PhotoSwipe/issues/1315
     */

    fixIOSCSSHeightCalculation() {
      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        setTimeout(() => {
          this.verticalHeightCorrection();
        }, 500);
      }
    },

    verticalHeightCorrection() {
      // used to fix iOS Safari PWA mode wrong property calculation
      if (this.platform === STRING_VALUES.PLATFORM_IOS) {
        let vh = window.innerHeight * 0.01;
        try {
          document.getElementsByClassName(this.workarounds.iOSLayoutSelector)[0].style.setProperty('--vh', `${vh}px`);
        } catch(err) {
          logger.error(err);
        }
      }

      this.verticalHeight = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.verticalHeight}px`);
    },

    detectOrientation() {
      return (window.orientation % 180 !== 0) ? STRING_VALUES.ORIENTATION_LANDSCAPE : STRING_VALUES.ORIENTATION_PORTRAIT;
    },

    /**
     * Gets version from data attribute version on document <head>
     *
     * @return {string} version of application printed in HTML
     */
    getVersionFromHTML() { /*TODO need to implement application updates, compare versions and get the latest version of the application, etc.*/
      let head = document.head || document.getElementsByTagName('head')[0];
      return head.dataset.version;
    },

    updateApplication() {
      if (this.swInstanceWaiting) {
        this.appUpdating = true;
        this.swInstanceWaiting.postMessage({ action: 'skipWaiting' });
      }
    },

    cancelUpdate() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_APP_UPDATE_FAILED, false);
    },

    calculateViewportSize() {
      let viewportSize = {
        'windowHeight': window.innerHeight,
        'windowWidth': window.innerWidth,
        'height': document.getElementById('app').offsetHeight,
        'width': document.getElementById('app').offsetWidth,
        'correctionalConstant': window.innerHeight * 0.01,
      };

      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_VIEWPORT_SIZE, viewportSize);
      EVENT_BUS.$emit(GLOBAL_EVENTS.RESIZE_EVENT);
      EVENT_BUS.$emit(GLOBAL_EVENTS.CLOSE_DRAWER);
    },

    confirmNetworkProblems() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATION_CONSTANTS.SET_NETWORK_ERROR, false);
    },

    refreshContent() {
      this.$store.dispatch(STORE_MODULES.PERSISTENT_STORAGE + '/' + ACTIONS_CONSTANTS.GENERATE_YEAR_AND_WEEK);
    },

    onDocumentVisibilityChange() {
      if (document.visibilityState === 'visible') {
        if (this.swRegistration) {
          this.swRegistration.update();
        }
        this.refreshContent();
      }
    },

    removeFastResponseDomElements() {
      let criticalLoadingElement = document.getElementById('zap-critical-loading');
      if(criticalLoadingElement) {
        criticalLoadingElement.remove();
      }
    },

    onWindowResize() {
      this.detectScreenOrientation();
      this.verticalHeightCorrection();
      this.calculateViewportSize();
    },

    onOrientationChange() {
      this.detectScreenOrientation();
      this.verticalHeightCorrection();
    },

    openInstallReminderModal() {
      this.showInstallReminderModal = true;
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP);
    },

    closeInstallReminderModal() {
      this.showInstallReminderModal = false;
    },

    manuallyRefreshApp() {
      window.location.reload();
    }
  }
}
</script>
