<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-c-form-row" :class="classObject">
        <div v-if="hasCaption" class="vue-b-form-row-caption">
            <slot name="caption">
                <template v-if="!slotCaptionHasContent">
                    <template v-if="!captionFor">
                        <span :id="captionId" class="vue-caption">{{ caption }}</span>
                    </template>
                    <template v-else>
                        <label :id="captionId" class="vue-caption" :for="captionFor">{{ caption }}</label>
                    </template>
                </template>
            </slot>
        </div>
        <div class="vue-b-form-row-content">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== MIXINS
import Component from '../../mixins/component';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1023';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1023FormRow',
    mixins: [Component],
    props: {
        caption: String,
        captionFor: String,
        required: Boolean,
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: String
        }
    },
    computed: {
        classObject() {
            return [
                {
                    'vue-is-required': this.required,
                    'vue-has-caption': this.hasCaption,
                    'vue-has-no-caption': !this.hasCaption,
                    'vue-has-no-content': !this.slotDefaultHasContent
                }
            ];
        },
        captionId() {
            if (this.idComputed) {
                return this.idComputed + this.ID_EXTENSIONS.CAPTION;
            }

            return null;
        },
        hasCaption() {
            return this.slotCaptionHasContent || this.caption;
        },
        slotCaptionHasContent() {
            return !!this.$slots.caption;
        },
        slotDefaultHasContent() {
            return !!this.$slots.default;
        }
    }
};
</script>
