// FIXME: change camelCase to snake_case
export const STRING_VALUE_FOOD_ESHOP = {
  LIST: 'food_eshop_list',
  DETAIL: 'food_eshop_detail',
  CHECKOUT_1: 'food_eshop_checkout_step_1',
  CHECKOUT_2: 'food_eshop_checkout_step_2',
  CHECKOUT_3: 'food_eshop_checkout_step_3',
  CHECKOUT_4: 'food_eshop_checkout_step_4',
  CHECKOUT_5: 'food_eshop_checkout_step_5'
}

export const PLATFORM_OTHER = 'other';
export const PLATFORM_ANDROID = 'android';
export const PLATFORM_IOS = 'ios';
export const ORIENTATION_LANDSCAPE = 'landscape';
export const ORIENTATION_PORTRAIT = 'portrait';
export const SYSTEM_VERSION_UNKNOWN = 'unknown';
