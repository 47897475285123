// === MAP COMPONENT ==================//
export const MAP_RESET_POSITION_EVENT = 'MAP_RESET_POSITION_EVENT';
export const MAP_ZOOM_IN_EVENT = 'MAP_ZOOM_IN_EVENT';
export const MAP_ZOOM_OUT_EVENT = 'MAP_ZOOM_OUT_EVENT';

// === ARTICLE DRAWER =================//
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const RESIZE_EVENT = 'RESIZE_EVENT';
export const RELOAD_ARTICLES = 'RELOAD_ARTICLES';

// === POPUP FILTER ===================//
export const FILTER_ITEM_LIST = 'FILTER_ITEM_LIST';
export const FILTER_COVID_ITEM_LIST = 'FILTER_COVID_ITEM_LIST';

// === FORVARDINO =====================//
export const FILTER_LIST = 'FILTER_LIST';
