import * as MUTATIONS_CONSTANTS from '../constants/mutations';

const state = {
  selectedBuilding: '', // must be string for FRM1028: Advanced combo usage
  dashboardBackground: ''
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_SELECTED_BUILDING](state, building) {
    state.selectedBuilding = building;
  },

  [MUTATIONS_CONSTANTS.SET_DASHBOARD_BACKGROUND](state, background) {
    state.dashboardBackground = background;
  }
};

const actions = {

};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
