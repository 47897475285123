<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <!--=== H1 ==========================================-->
    <h1 v-if="level === 1" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h1>

    <!--=== H2 ==========================================-->
    <h2 v-else-if="level === 2" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h2>

    <!--=== H3 ==========================================-->
    <h3 v-else-if="level === 3" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h3>

    <!--=== H4 ==========================================-->
    <h4 v-else-if="level === 4" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h4>

    <!--=== H5 ==========================================-->
    <h5 v-else-if="level === 5" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h5>

    <!--=== H6 ==========================================-->
    <h6 v-else-if="level === 6" :id="id" class="vue-component vue-c-heading" :class="classObject">
        <slot>{{ caption }}</slot>
    </h6>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1002Heading',
    props: {
        level: {
            default: 1,
            type: Number
        },
        caption: String,
        id: String
    },
    computed: {
        classObject() {
            return ['vue-is-level-' + this.level];
        }
    }
};
</script>
