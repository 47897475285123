<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div
        class="vue-component vue-c-notification-popup"
        :class="classObject"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
    >
        <frm1006-button
            ref="buttonToggleVisibility"
            type="internal"
            :captionHidden="true"
            class="vue-ci-button-open"
            @buttonClickEvent="buttonOpenClick"
        >
            <template v-slot:iconLeft>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="26px"
                    height="27px"
                    viewBox="0 0 26 27"
                    version="1.1"
                >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="bell-mobile-flinto" transform="translate(-23.000000, -751.000000)" stroke="#FFFFFF">
                            <g id="bell" transform="translate(11.000000, 636.000000)">
                                <g id="icon" transform="translate(13.000000, 116.000000)">
                                    <path
                                        id="Shape"
                                        d="M0,18.7500003 C1.96061089,18.7500003 3.55000005,15.1606111 3.55000005,13.2000002 L3.55000005,8.28333347 C3.55000012,3.70857477 7.25857481,1.39077542e-07 11.8333335,1.39077542e-07 C16.4080922,1.39077542e-07 20.1166669,3.70857477 20.1166669,8.28333347 L20.1166669,13.2000002 C20.1166669,15.1606111 21.7060561,18.7500003 23.666667,18.7500003 L0,18.7500003 Z"
                                        stroke-width="2"
                                        stroke-linecap="square"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        id="Shape-Copy"
                                        d="M13.8805002,23.4833337 C13.4571062,24.2132188 12.6771314,24.6624736 11.8333335,24.6624736 C10.9895356,24.6624736 10.2095608,24.2132188 9.7861668,23.4833337"
                                        stroke-width="2"
                                        stroke-linecap="square"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        id="Path-2"
                                        d="M7,9.82068577 C7.14804612,7.8766259 7.66185633,6.43106655 8.54143064,5.48400773 C9.42100495,4.53694891 10.7091686,4.04227967 12.4059215,4"
                                        stroke-opacity="0.48866368"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </template>

            Otevřít upozornění push notifikace
        </frm1006-button>

        <div ref="contentContainer" class="vue-b-content-container">
            <div class="vue-content">
                <slot name="content"></slot>
            </div>
        </div>

        <div v-if="buttonClose || !!$slots.controls" class="vue-b-controls">
            <slot name="controls"></slot>
            <frm1006-button
                type="internal"
                :title="buttonCloseCaption"
                :preventLosingFocus="true"
                class="vue-ci-button-close"
                :captionHidden="true"
                @buttonClickEvent="buttonCloseClick"
            >
                <template v-slot:iconLeft>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#d9f2ff"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </template>
                {{ buttonCloseCaption }}
            </frm1006-button>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//============ EXPORT ==================================//
//======================================================//
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

export default {
    name: 'Wcm1001NotificationPopup',
    components: { Frm1006Button },
    mixins: [],
    props: {
        expanded: Boolean,
        mode: {
            default: 'click',
            type: String,
            validator: value => {
                return ['click', 'hover'].includes(value);
            }
        },
        buttonClose: {
            default: true,
            type: Boolean
        },
        whiteList: {
            default: () => [],
            type: Array
        },
        buttonCloseCaption: {
            default: 'Zavřít',
            type: String
        },
        id: String
    },
    data() {
        return {
            expandedData: false,
            hover: false
        };
    },
    computed: {
        classObject() {
            return {
                'vue-is-expanded': this.expandedData,
                'vue-has-hover': this.hover,
                'vue-has-button-close': this.buttonClose
            };
        }
    },
    watch: {},
    mounted() {
        // set initial state from prop to data
        this.expandedData = this.expanded;
        document.addEventListener('click', this.click);
    },
    destroyed() {
        document.removeEventListener('click', this.click);
    },
    methods: {
        buttonOpenClick() {
            this.expandedData = true;
        },
        buttonCloseClick() {
            this.expandedData = false;
            // TODO MBU: implement timer so that hover is not triggered if the button close is over button container
            this.hover = false;
        },
        // checks if clicked item is on white list, if it is, it won't trigger clickOutside
        whiteListCheck(element) {
            //=== button toggle visibility
            if (element === this.$refs.buttonToggleVisibility.$el) {
                return true;
            }

            let buttonToggleVisibilityChildren;
            if (this.$refs.buttonToggleVisibility.$el) {
                buttonToggleVisibilityChildren = this.$refs.buttonToggleVisibility.$el.querySelectorAll('*');
            }

            if (buttonToggleVisibilityChildren !== undefined) {
                for (let child of buttonToggleVisibilityChildren) {
                    if (element === child) {
                        return true;
                    }
                }
            }

            // content container
            if (element === this.$refs.contentContainer) {
                return true;
            }

            let contentContainerChildren;
            if (this.$refs.contentContainer) {
                contentContainerChildren = this.$refs.contentContainer.querySelectorAll('*');
            }

            if (contentContainerChildren !== undefined) {
                for (let child of contentContainerChildren) {
                    if (element === child) {
                        return true;
                    }
                }
            }

            // passed white list
            if (this.whiteList.length > 0 && this.whiteList.indexOf(element) > -1) {
                return true;
            }

            return false;
        },
        //=== EVENTS
        click(event) {
            if (!this.whiteListCheck(event.target)) {
                this.expandedData = false;
            }
        },
        mouseOver() {
            if (this.mode === 'hover' && !this.disabled) {
                this.hover = true;
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('notificationPopupExpandEvent', 'mouseOver');
            }
        },
        mouseLeave() {
            if (this.mode === 'hover') {
                this.hover = false;
                // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
                this.$emit('notificationPopupCloseEvent', 'mouseLeave');
            }
        }
    }
};
</script>
