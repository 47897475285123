<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="buttonCustomEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="initialized && !isLoading"
      >
        <div
          v-if="!isOnboardingFinished"
          class="vue-b-onboarding-dashboard"
        >
          <div class="vue-onboarding-header">
            <h1
              class="vue-onboarding-title"
            >
              {{ $t('onboarding.dashboardTitle') }}
            </h1>

            <gen1006-info-panel
              class="vue-onboarding-infobox"
              type="neutral"
            >
              <div class="vue-b-info-caption">{{ $t('onboarding.dashboardDaysToBoarding') }}</div>
              <div class="vue-b-info-value">{{ daysToBoarding }}</div>
            </gen1006-info-panel>
          </div>

          <div class="vue-dashboard-tiles">
            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-we-will-need-from-you vue-tile-onboarding-is-required"
              type="tile"
              @buttonClickEvent="goToView('onboarding-directory')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.weWillNeedFromYou') }}
              <div class="vue-dashboard-tile-sticker">{{ $t('onboarding.isRequired') }}</div>
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-contacts"
              type="tile"
              @buttonClickEvent="goToView('onboarding-contacts')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.contacts') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-first-day"
              type="tile"
              @buttonClickEvent="goToView('onboarding-first-day')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.myFirstDay') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-about-group"
              type="tile"
              @buttonClickEvent="goToView('onboarding-about-group')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.aboutCSOBGroup') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-benefits"
              type="tile"
              @buttonClickEvent="goToView('onboarding-benefits')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.benefits') }}
            </frm1006-button>

            <frm1006-button
              class="vue-dashboard-item-tile vue-tile-onboarding-help"
              type="tile"
              @buttonClickEvent="goToView('onboarding-help')"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                />
              </template>
              {{ $t('onboarding.help') }}
            </frm1006-button>
          </div>
        </div>
        <div
          v-else
          class="vue-onboarding-header"
        >
          <h1
            class="vue-onboarding-title"
          >
            {{ $t('onboarding.dashboardTitle') }}
          </h1>
          <prj1017-heading
            :subheader="$i18n.t('onboarding.finishOnboardingMessage')"
          />
        </div>
      </template>
    </template>
    <template v-slot:contentRest>
      <frm1006-button
        v-if="isOnboardingFinished"
        type="primary"
        mode="anchor"
        :link="MAIN_APP_URL"
        :title="$i18n.t('onboarding.goToMainApp')"
      >
        {{ $t('onboarding.goToMainApp') }}
      </frm1006-button>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  ONBOARDING_DASHBOARD_ID,
  MAIN_APP_URL, ONBOARDING_APPLICANT_STATUSES
} from '@/constants/app-constants';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import { mapState } from 'vuex';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

export default {
  name: 'OnboardingDashboard',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1017Heading
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      onboardingCategories: [],
      applicant: {},
      daysToBoarding: '',
      MAIN_APP_URL
    };
  },

  computed: {
    ...mapState('user', [
      'publicKey'
    ]),

    ...mapState('general', [
      'swRegistration',
      'applicationConfig',
      'applicationConfigLoaded',
      'isMenuOpened',
      'isSubmenuOpened',
      'dashboardButtons',
      'dashboardGroupButtons',
      'backToNavigation',
      'viewportSize',
      'appDestination'
    ]),

    isOnboardingFinished() {
      return this.applicant.status ? this.applicant.status === ONBOARDING_APPLICANT_STATUSES.completed : false
    },

    navbarButtonType() {
      if (this.dashboardMenuClosed) {
        return 'hamburger'
      } else if (this.dashboardMenuTopLevelOpen) {
        return 'close'
      } else if (this.dashboardSubmenuOpen) {
        return 'back'
      }

      return '';
    },

    dashboardMenuClosed() {
      return !this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardMenuTopLevelOpen() {
      return this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardSubmenuOpen() {
      return this.isMenuOpened && this.isSubmenuOpened
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },
  },

  mounted() {
    if (this.backToNavigation) {
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.OPEN_DASHBOARD_NAVIGATION)
        .then(() => {
          this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        });
    }

    this.getOnboardingDashboardCategories();
    this.getApplicantDetail().then(() => {
      this.daysFromToday(this.applicant.startDate)
        .then(result => {
          this.daysToBoarding = result;
        });
    });
    this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS);
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      if (viewName === 'onboarding-directory') {
        this.$router.push({ name: 'onboarding-directory' });
      } else if (viewName === 'onboarding-contacts') {
        this.$router.push({ name: 'onboarding-contacts' })
      } else {
        this.$router.push({ name: 'onboarding-faq', params: {categoryId: this.getCategoryId(viewName), categorySlug: this.getCategorySlug(viewName)} });
      }
    },

    getOnboardingDashboardCategories() {
      this.setLoadingState(true);

      return dataLoader.fetchApiDataList(this.publicKey, ONBOARDING_DASHBOARD_ID)
        .then(data => {
          this.onboardingCategories = data.onboardingCategories;
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    getCategoryId(viewName) {
      return this.onboardingCategories.find(({categoryName}) => categoryName === viewName).categoryId
    },

    getCategorySlug(viewName) {
      return viewName.replace('onboarding-', '');
    },

    getApplicantDetail() {
      this.setLoadingState(true);

      return dataLoader.fetchApplicantDetail()
        .then((data) => {
          this.applicant = data;
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_FIRST_NAME, data.firstName);
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_LAST_NAME, data.lastName);
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    daysFromToday(date) {
      return new Promise((resolve) => {
        const today = new Date();
        const selectedDate = new Date(date);
        let timeDiff = Math.ceil((selectedDate - today) / (1000 * 3600 * 24)) - 1;
        return resolve(timeDiff);
      });
    },

    buttonCustomEvent() {
      // menu top level open - 2 conditions to prevent open & close with same click
      if (!this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, true);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY, null);
      } else if (this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, false);
      }        // submenuopen
      if (this.dashboardSubmenuOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU, false);
      }
    },
  },
}
</script>
