<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
        @buttonCustomEvent="buttonCustomEvent"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="!isFormSent">
        <template v-if="!isEmpty(applicant)">
          <ValidationObserver
            ref="observer"
            tag="form"
            novalidate
            :class="classObject"
            @submit.prevent="updateApplicantDetail()"
          >
            <prj1040-stepper
              :stepsCount="sections.length"
              :activeStep="activeStep"
            >
            </prj1040-stepper>

            <!-- Base data-->
            <section
              v-if="activeStep === 1 || summary"
            >
              <h1
                v-if="summary"
                class="vue-section-title"
              >
                {{ $t('onboarding.section.summary') }}
              </h1>

              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.baseData') }}
              </h1>

              <gen1006-info-panel
                v-if="!summary"
                type="info"
              >
                {{ $t('onboarding.persDataInfo') }}
              </gen1006-info-panel>

              <!-- Last name -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.lastName')"
                  rules="required"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.lastName"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.lastName')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- First name -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.firstName')"
                  rules="required"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.firstName"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.firstName')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Person title -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.titlePrimary.title')"
                  rules=""
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.title"
                    :options="comboOptions($t('onboarding.person.titlePrimary.options'))"
                    :placeholder="$i18n.t('onboarding.person.titlePrimary.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.titlePrimary.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Person title 2 -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.titleSecondary.title')"
                  rules=""
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.title2"
                    :options="comboOptions($t('onboarding.person.titleSecondary.options'))"
                    :placeholder="$i18n.t('onboarding.person.titleSecondary.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.titleSecondary.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Student -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.student.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.student"
                    :options="comboOptions($t('onboarding.person.student.options'))"
                    :placeholder="$i18n.t('onboarding.person.student.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.student.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>
            </section>

            <!-- Contact data-->
            <section
              v-if="activeStep === 2 || summary"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.contactData') }}
              </h1>

              <!-- Email -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.email')"
                  rules="required|email"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.email"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.email')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Phone -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.phone')"
                  :rules="{required: true, phone: true}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.phone"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.phoneLabel')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>
            </section>

            <!-- Personal data-->
            <section
              v-if="activeStep === 3 || summary"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.personalData') }}
              </h1>

              <!-- Nationality -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.nationality')"
                  :rules="{required: true, max: 2}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.nationality"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.nationality')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Personal number -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.personalNumber')"
                  :rules="{required: true, identification: !isForeigner}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.personalNo"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.personalNumber')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Date of birth-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="$i18n.t('onboarding.person.dateOfBirth')"
                  :rules="{required: true, date: true}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="localDateOfBirth"
                    :options="null"
                    :label="$i18n.t('onboarding.person.dateOfBirth')"
                    :tooltip="false"
                    :disabled="isFormDisabled || summary"
                    @inputBlurEvent="applicant.birthDate = localeToISODate(localDateOfBirth)"
                  />
                  <frm1006-button
                    :type="'internal'"
                    :icon-left="true"
                    class="vue-is-calendar"
                    :disabled="isFormDisabled || summary"
                    @buttonClickEvent="isDateOfBirthCalendarVisible = true"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Birth place -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.birthLocation')"
                  rules="required"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.birthLocation"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.birthLocation')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Birth name -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.birthName')"
                  rules=""
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.birthName"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.birthName')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Bank account -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.bankAccount')"
                  :rules="{required: true, account: true}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.accountNo"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.bankAccount')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  <span class="vue-input-note">{{ bankName }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Marital Status -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.maritalStatus.title')"
                  rules=""
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.maritalStatus"
                    :options="comboOptions($t('onboarding.person.maritalStatus.options'))"
                    :placeholder="$i18n.t('onboarding.person.maritalStatus.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.maritalStatus.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Coming from -->
              <frm1035-form-group v-if="isHPP">
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.comingFrom.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.comingFrom"
                    :options="comboOptions($t('onboarding.person.comingFrom.options'))"
                    :placeholder="$i18n.t('onboarding.person.comingFrom.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.comingFrom.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Criminal record -->
              <frm1035-form-group v-if="isHPP">
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.cleanCriminalRecord.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.cleanCriminalRecord"
                    :options="comboOptions($t('onboarding.person.cleanCriminalRecord.options'))"
                    :placeholder="$i18n.t('onboarding.person.cleanCriminalRecord.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.cleanCriminalRecord.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <template v-if="isForeigner">
                <h1
                  class="vue-section-title"
                  :class="classObject"
                >
                  {{ $t('onboarding.subSectionForeigners') }}
                </h1>
                <!-- Insurance No-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.foreignerInsuranceNo')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.insuranceNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.foreignerInsuranceNo')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Social No-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.foreignerSocialNo')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.socialNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.foreignerSocialNo')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Passport No-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.foreignerPassportNo')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.passportNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.foreignerPassportNo')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Passport Authority-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.foreignerPassportAuthority')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.passportAuthority"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.foreignerPassportAuthority')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Work Permission -->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.workPermission.title')"
                    rules="required"
                    tag="div"
                  >
                    <frm1028-advanced-combo
                      v-model="applicant.workPermission"
                      :options="comboOptions($t('onboarding.person.workPermission.options'))"
                      :placeholder="$i18n.t('onboarding.person.workPermission.title')"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.workPermission.title')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Work permission other-->
                <frm1035-form-group v-if="isWorkPermissionOther">
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.workPermissionOther')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.workPermissionOther"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.workPermissionOther')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>
              </template>
            </section>

            <!-- Primary address-->
            <section
              v-if="activeStep === 4 || summary"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.primaryAddress') }}
              </h1>

              <!-- Residence country-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceCountry')"
                  :rules="{required: true, max: 2}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrCountry"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceCountry')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Residence City-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceCity')"
                  rules="required"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrCity"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceCity')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Residence street-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceStreet')"
                  rules=""
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrStreet"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceStreet')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Residence street number-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceStreetNumber')"
                  :rules="{required: true, streetNo: true}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrStreetNo"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceStreetNumber')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Residence street number short-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceStreetNumberShort')"
                  rules=""
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrStreetNo2"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceStreetNumberShort')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Residence ZIP-->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.residenceZIP')"
                  rules="required"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.prAdrZip"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.residenceZIP')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <template v-if="isDifferentCountry">
                <h1
                  class="vue-section-title"
                  :class="classObject"
                >
                  {{ $t('onboarding.foreignerTempAddress') }}
                </h1>
                <!-- Temporary country-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryCountry')"
                    :rules="{required: true, max: 2}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrCountry"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryCountry')"
                      :class="classes"
                      :disabled="true"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Temporary City-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryCity')"
                    rules="required"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrCity"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryCity')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Temporary street-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryStreet')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrStreet"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryStreet')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Temporary street number-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryStreetNumber')"
                    :rules="{required: true, streetNo: true}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrStreetNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryStreetNumber')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Temporary street number short-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryStreetNumberShort')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrStreetNo2"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryStreetNumberShort')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Temporary ZIP-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.temporaryZIP')"
                    rules="required"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.tmpAdrZip"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.temporaryZIP')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>
              </template>
            </section>

            <!-- Health adn children-->
            <section
              v-if="activeStep === 5 || summary"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.healthAndChildren') }}
              </h1>

              <!-- Insurance Company -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.insuranceCompany.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.insuranceCompany"
                    :options="comboOptions($t('onboarding.person.insuranceCompany.options'))"
                    :placeholder="$i18n.t('onboarding.person.insuranceCompany.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.insuranceCompany.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Pension -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.pension.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.pension"
                    :options="comboOptions($t('onboarding.person.pension.options'))"
                    :placeholder="$i18n.t('onboarding.person.pension.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.pension.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <template v-if="isPersonPension">
                <!-- Pension type -->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.pensionType.title')"
                    rules="required"
                    tag="div"
                  >
                    <frm1028-advanced-combo
                      v-model="applicant.pensionType"
                      :options="comboOptions($t('onboarding.person.pensionType.options'))"
                      :placeholder="$i18n.t('onboarding.person.pensionType.title')"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.pensionType.title')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Pension date from-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="$i18n.t('onboarding.person.pensionDateFrom')"
                    :rules="{required: true, date: true}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="localPensionDateFrom"
                      :options="null"
                      :label="$i18n.t('onboarding.person.pensionDateFrom')"
                      :tooltip="false"
                      :disabled="isFormDisabled || summary"
                      @inputBlurEvent="applicant.pensionDateFrom = localeToISODate(localPensionDateFrom)"
                    />
                    <frm1006-button
                      :type="'internal'"
                      :icon-left="true"
                      class="vue-is-calendar"
                      :disabled="isFormDisabled || summary"
                      @buttonClickEvent="isPensionDateFromCalendarVisible = true"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>
              </template>

              <!-- ZTP -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.ztp.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.ztp"
                    :options="comboOptions($t('onboarding.person.ztp.options'))"
                    :placeholder="$i18n.t('onboarding.person.ztp.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.ztp.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Childrens Count -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.childrenCount')"
                  :rules="{required: true, numeric: true}"
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="personChildrenCount"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.childrenCount')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>
            </section>

            <!-- Work and taxes - DPP-->
            <section
              v-if="activeStep === 6 || (summary && !isHPP)"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.workAndTaxes') }}
              </h1>

              <!-- Education level -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.educationLevel.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.educationLevel"
                    :options="comboOptions($t('onboarding.person.educationLevel.options'))"
                    :placeholder="$i18n.t('onboarding.person.educationLevel.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.educationLevel.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Parallel Employer -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.parallelEmployer')"
                  rules=""
                  tag="div"
                >
                  <frm1001-input-field
                    v-model="applicant.parallelEmployer"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.parallelEmployer')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Self Employed -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.selfEmployed.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.selfEmployed"
                    :options="comboOptions($t('onboarding.person.selfEmployed.options'))"
                    :placeholder="$i18n.t('onboarding.person.selfEmployed.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.selfEmployed.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Employment Bureau -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.employmentBureau.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.employmentBureau"
                    :options="comboOptions($t('onboarding.person.employmentBureau.options'))"
                    :placeholder="$i18n.t('onboarding.person.employmentBureau.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.employmentBureau.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Insurance Deduction -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.insuranceDeduction.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.insuranceDeduction"
                    :options="comboOptions($t('onboarding.person.insuranceDeduction.options'))"
                    :placeholder="$i18n.t('onboarding.person.insuranceDeduction.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.insuranceDeduction.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>

              <!-- Insurance Payer State -->
              <frm1035-form-group>
                <ValidationProvider
                  v-slot="{ errors, classes }"
                  :name="$i18n.t('onboarding.person.insurancePayerState.title')"
                  rules="required"
                  tag="div"
                >
                  <frm1028-advanced-combo
                    v-model="applicant.insurancePayerState"
                    :options="comboOptions($t('onboarding.person.insurancePayerState.options'))"
                    :placeholder="$i18n.t('onboarding.person.insurancePayerState.title')"
                    :tooltip="false"
                    :label="$i18n.t('onboarding.person.insurancePayerState.title')"
                    :class="classes"
                    :disabled="isFormDisabled || summary"
                  />
                  <span class="vue-validation-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </frm1035-form-group>
            </section>

            <!-- Delivery address-->
            <section
              v-if="activeStep === 7 || summary"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.deliveryAddress') }}
              </h1>

              <frm1035-form-group>
                <frm1003-checkbox
                  v-model="applicant.dlvAdrSameAsPrimary"
                  :valueTrue="$i18n.t('onboarding.person.deliveryAddressIsTheSame.options[0]')"
                  :valueFalse="$i18n.t('onboarding.person.deliveryAddressIsTheSame.options[1]')"
                  :tooltip="false"
                  :disabled="isFormDisabled || summary"
                >
                  {{ $t('onboarding.person.deliveryAddressIsTheSame.title', { adrType: addressTypeLabel }) }}
                </frm1003-checkbox>
              </frm1035-form-group>

              <template v-if="!isDeliverySameAddress">
                <!-- Delivery country-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryCountry')"
                    :rules="{required: !isDeliverySameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrCountry"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryCountry')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Delivery City-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryCity')"
                    :rules="{required: !isDeliverySameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrCity"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryCity')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Delivery street-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryStreet')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrStreet"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryStreet')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Delivery street number-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryStreetNumber')"
                    :rules="{required: !isDeliverySameAddress, streetNo: true}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrStreetNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryStreetNumber')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Delivery street number short-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryStreetNumberShort')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrStreetNo2"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryStreetNumberShort')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Delivery ZIP-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.deliveryZIP')"
                    :rules="{required: !isDeliverySameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.dlvAdrZip"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.deliveryZIP')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>
              </template>
            </section>

            <!-- HO address-->
            <section
              v-if="isHPP && (activeStep === 8 || summary)"
            >
              <h1
                class="vue-section-title"
                :class="classObject"
              >
                {{ $t('onboarding.section.hoAddress') }}
              </h1>

              <frm1035-form-group>
                <frm1003-checkbox
                  v-model="applicant.hoAdrSameAsPrimary"
                  :valueTrue="$i18n.t('onboarding.person.homeOfficeAddressIsTheSame.options[0]')"
                  :valueFalse="$i18n.t('onboarding.person.homeOfficeAddressIsTheSame.options[1]')"
                  :tooltip="false"
                  :disabled="isFormDisabled || summary"
                >
                  {{ $t('onboarding.person.homeOfficeAddressIsTheSame.title', { adrType: addressTypeLabel }) }}
                </frm1003-checkbox>
              </frm1035-form-group>

              <template v-if="!isHomeOfficeSameAddress">
                <!-- Home office country-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeCountry')"
                    :rules="{required: !isHomeOfficeSameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrCountry"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeCountry')"
                      :class="classes"
                      :disabled="true"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Home office City-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeCity')"
                    :rules="{required: !isHomeOfficeSameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrCity"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeCity')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Home office street-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeStreet')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrStreet"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeStreet')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Home office street number-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeStreetNumber')"
                    :rules="{required: !isHomeOfficeSameAddress, streetNo: true}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrStreetNo"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeStreetNumber')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Home office street number short-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeStreetNumberShort')"
                    rules=""
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrStreetNo2"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeStreetNumberShort')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>

                <!-- Home office ZIP-->
                <frm1035-form-group>
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    :name="$i18n.t('onboarding.person.homeOfficeZIP')"
                    :rules="{required: !isHomeOfficeSameAddress}"
                    tag="div"
                  >
                    <frm1001-input-field
                      v-model="applicant.hoAdrZip"
                      :tooltip="false"
                      :label="$i18n.t('onboarding.person.homeOfficeZIP')"
                      :class="classes"
                      :disabled="isFormDisabled || summary"
                    />
                    <span class="vue-validation-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </frm1035-form-group>
              </template>

              <gen1006-info-panel
                v-if="!summary"
                type="neutral"
              >
                {{ $t('onboarding.person.homeOfficeNote') }}
              </gen1006-info-panel>
            </section>
          </ValidationObserver>
        </template>
      </template>
      <template v-else>
        <div class="vue-onboarding-confirmation">
          <h1 class="vue-section-title">
            {{ $i18n.t('onboarding.formSuccessMessage') }}
          </h1>
          <h3 class="vue-page-text">
            {{ $i18n.t('onboarding.formSuccessMessageContent') }}
          </h3>
        </div>
      </template>
    </template>

    <template v-slot:contentRest>
      <frm1031-button-container>
        <template v-if="!isFormSent">
          <frm1006-button
            v-if="activeStep < sections.length"
            type="primary"
            :title="$i18n.t('onboarding.continue')"
            @buttonClickEvent="updateApplicantDetail()"
          >
            {{ $t('onboarding.continue') }}
          </frm1006-button>
          <frm1006-button
            v-else-if="!isFormDisabled"
            type="primary"
            :title="$i18n.t('onboarding.send')"
            @buttonClickEvent="sendApplicantDetail()"
          >
            {{ $t('onboarding.send') }}
          </frm1006-button>
          <frm1006-button
            v-else
            type="primary"
            :title="$i18n.t('onboarding.continue')"
            @buttonClickEvent="goToView('onboarding-directory')"
          >
            {{ $t('onboarding.continue') }}
          </frm1006-button>
        </template>
        <template v-else>
          <frm1006-button
            class="vue-button-page-layout-fixed"
            type="primary"
            @buttonClickEvent="goToView('onboarding-directory')"
          >
            {{ $t('onboarding.continue') }}
          </frm1006-button>
        </template>
      </frm1031-button-container>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        v-if="!isEmpty(applicant)"
        ref="overlay"
        class="vue-is-date-picker"
        :active.sync="isDateOfBirthCalendarVisible"
        :buttonClose="true"
        :prevent-closing="'clickOutside'"
      >
        <v-date-picker
          v-model="applicant.birthDate"
          class="vue-date-picker"
          is-expanded
          locale="cs-CZ"
          :modelConfig="{ type: 'string', mask: 'YYYY-MM-DD' }"
        />
      </gen1009-overlay>
      <gen1009-overlay
        v-if="!isEmpty(applicant)"
        ref="overlay"
        class="vue-is-date-picker"
        :active.sync="isPensionDateFromCalendarVisible"
        :buttonClose="true"
        :prevent-closing="'clickOutside'"
      >
        <v-date-picker
          v-model="applicant.pensionDateFrom"
          class="vue-date-picker"
          is-expanded
          locale="cs-CZ"
          :modelConfig="{ type: 'string', mask: 'YYYY-MM-DD' }"
        />
      </gen1009-overlay>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  ONBOARDING_APPLICANT_FORM_SECTIONS, ONBOARDING_APPLICANT_FORM_SECTIONS_DPP, ONBOARDING_APPLICANT_STATUSES,
  ONBOARDING_DEFAULT_NATIONALITY
} from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1040Stepper from '@/components/prj1040-stepper/prj1040-stepper';
import Scroller from 'vue-scrollto';

import '@/utils/validation';
import logger from '@/utils/logger';
import dataLoader from '@/utils/data-loader';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

export default {
  name: 'OnboardingForm',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1040Stepper
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      applicant: {},
      activeStep: 1,
      summary: false,
      sections: ONBOARDING_APPLICANT_FORM_SECTIONS,
      localDateOfBirth: '',
      isDateOfBirthCalendarVisible: false,
      personChildrenCount: '',
      localPensionDateFrom: '',
      isPensionDateFromCalendarVisible: false,
      isFormSent: false,
      isFormDisabled: false
    }
  },

  computed: {
    pickerDateOfBirth() {
      return this.applicant ? this.applicant.birthDate : ''
    },

    isPersonPension() {
      return this.applicant.pension === this.$t('onboarding.person.pension.options[0]')
    },

    isPersonDisabled() {
      return this.applicant.disabled === this.$t('onboarding.person.disabled.options[0]')
    },

    pickerPensionDateFrom() {
      return this.applicant ? this.applicant.pensionDateFrom : ''
    },

    isDeliverySameAddress() {
      return this.applicant.dlvAdrSameAsPrimary === this.$t('onboarding.person.deliveryAddressIsTheSame.options[0]')
    },

    isHomeOfficeSameAddress() {
      return this.applicant.hoAdrSameAsPrimary === this.$t('onboarding.person.homeOfficeAddressIsTheSame.options[0]')
    },

    isForeigner() {
      return this.applicant.nationality !== ONBOARDING_DEFAULT_NATIONALITY
    },

    isDifferentCountry() {
      return this.applicant.prAdrCountry !== ONBOARDING_DEFAULT_NATIONALITY
    },

    isHPP() {
      return this.applicant.contractType === 'HIRE_INT';
    },

    isDPP() {
      return this.applicant.contractType === 'HIRE_DPP';
    },

    isWorkPermissionOther() {
      return this.applicant.workPermission === this.$t('onboarding.person.workPermission.options[3]')
    },

    addressTypeLabel() {
      return this.$i18n.t(this.applicant.prAdrCountry !== ONBOARDING_DEFAULT_NATIONALITY ? 'onboarding.person.adrTypeNonCZ' : 'onboarding.person.adrTypeCZ');
    },

    bankName() {
      const bankCode = this.applicant.accountNo?.split('/')[1];
      const banks = this.$t('onboarding.banks');
      if (bankCode && bankCode.length === 4) {
        const bank = banks.find(item => item.code === bankCode)
        return bank.name ? bank.name : ''
      }
      return ''
    },

    classObject() {
      return {
        'vue-is-summary': this.summary
      }
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    },

    pickerDateOfBirth(value) {
      this.localDateOfBirth = this.ISOToLocaleDate(value);
      this.isDateOfBirthCalendarVisible = false
    },

    pickerPensionDateFrom(value) {
      this.localPensionDateFrom = this.ISOToLocaleDate(value);
      this.isPensionDateFromCalendarVisible = false
    },

    personChildrenCount(value) {
      this.applicant.childrenCount = parseInt(value)
    },

    activeStep(value) {
      this.summary = value === this.sections.length;
    }
  },

  mounted() {
    this.getApplicantDetail().then(() => {
      this.initialized = true;
      this.personChildrenCount = this.applicant.childrenCount == null ? '' : this.applicant.childrenCount.toString();
      this.localDateOfBirth = this.ISOToLocaleDate(this.applicant.birthDate);
      this.localPensionDateFrom = this.ISOToLocaleDate(this.applicant.pensionDateFrom);
      this.applicant.nationality = this.applicant.nationality ?? ONBOARDING_DEFAULT_NATIONALITY;
      this.applicant.prAdrCountry = this.applicant.prAdrCountry ?? ONBOARDING_DEFAULT_NATIONALITY;
      this.applicant.tmpAdrCountry = ONBOARDING_DEFAULT_NATIONALITY;
      this.applicant.dlvAdrCountry = this.applicant.dlvAdrCountry ?? ONBOARDING_DEFAULT_NATIONALITY;
      this.applicant.hoAdrCountry = ONBOARDING_DEFAULT_NATIONALITY;
      if (this.applicant.status !== ONBOARDING_APPLICANT_STATUSES.regApproved && this.applicant.status !== ONBOARDING_APPLICANT_STATUSES.persdataRejected ) {
        this.isFormDisabled = true
      }
      if (this.isDPP) {
        this.sections = ONBOARDING_APPLICANT_FORM_SECTIONS_DPP;
      }
    })
  },

  methods: {
    setLoadingState(value) {
      if (value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    getApplicantDetail() {
      this.setLoadingState(true);

      return dataLoader.fetchApplicantDetail()
        .then((data) => {
          this.applicant = data;
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    async updateApplicantDetail() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$nextTick(function () {
          Scroller.scrollTo('.vue-component.failed', 500, {container: '.vue-b-page-content', offset: -10});
        })
      } else {
        if (!this.isFormDisabled) {
          this.sendApplicantDetail(false);  // update call after each page
        } else {
          this.setNextStep();
        }
      }
    },

    async sendApplicantDetail(lastStep=true) {
      const isValid = await this.$refs.observer.validate();

      if (!isValid) {
        this.$nextTick(function () {
          Scroller.scrollTo('.vue-component.failed', 500, {container: '.vue-b-page-content', offset: -10});
        })
        return
      }
      this.setLoadingState(true);

      return dataLoader.updateApplicantDetail(this.applicant, lastStep ? 'final' : '')
        .then(() => {
          if (lastStep) {
            this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.SET_APPLICANT_FORM_STATUS, this.getFormStatus(this.applicant));
            this.isFormSent = true;
          } else {
            this.setNextStep();
          }
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    setNextStep() {
      if (this.activeStep === 5 && this.isHPP) {  // skip DPP section when HIRE_INT
        this.activeStep++;
      }
      this.activeStep++;
    },

    comboOptions(options) {
      if (!options) return [];
      return options.map(item => {
        return {
          value: item,
          caption: item
        }
      })
    },

    buttonCustomEvent() {
      if (this.activeStep > 1) {
        this.activeStep--
        if (this.activeStep === 6 && this.isHPP) {  // skip DPP section when HIRE_INT
          this.activeStep--;
        }
      } else {
        this.$router.push({name: 'onboarding-directory'});
      }
    },

    getFormStatus(object) {
      const values = Object.values(object);
      if (values.every(value => value === null || value === '')) {
        return 'negative'
      }
      if (values.every(value => value !== null && value !== '')) {
        return 'positive'
      }
      return 'progress'
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    ISOToLocaleDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      if (date && !isNaN(date)) {
        return date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear();
      }
      return '';
    },

    localeToISODate(dateString) {
      if (!dateString) return '';
      const stringArray = dateString.replaceAll(' ','').split('.');
      const day = stringArray[0];
      const month = stringArray[1];
      const year = stringArray[2];
      return new Date(year + '-' + month.padStart(2,'0') + '-' + day.padStart(2,'0') + 'T00:00:00Z').toISOString().substring(0, 10);
    },
  },
}
</script>
