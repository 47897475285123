import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';

//============ TEMPLATES ===============================//
//======================================================//
import PageDefault from '@/templates/page-default';
import PageService from '@/templates/page-service';

//============ VIEWS ===================================//
//======================================================//
import NotFound404 from '@/views/errors/NotFound404';
//import Dashboard from '@/views/user/Dashboard';
import UserSettings from '@/views/user/UserSettings';
import PagesDetail from '@/views/pages/PagesDetail';
import Login from '@/views/login/Login';
import OnboardingDashboard from '@/views/onboarding/OnboardingDashboard';
import OnboardingDirectory from '@/views/onboarding/OnboardingDirectory';
import OnboardingDocuments from '@/views/onboarding/OnboardingDocuments';
import OnboardingForm from '@/views/onboarding/OnboardingForm';
import OnboardingDocumentUpload from '@/views/onboarding/OnboardingDocumentUpload';
import OnboardingDocumentConfirm from '@/views/onboarding/OnboardingDocumentConfirm';
import OnboardingPhotoUpload from '@/views/onboarding/OnboardingPhotoUpload';
import OnboardingFaq from '@/views/onboarding/OnboardingFaq';
import OnboardingContacts from '@/views/onboarding/OnboardingContacts';

import loginUtils from '@/utils/login-utils';
import {APP_ENTRY_PATH_STORAGE_KEY} from '@/constants/general';

//============ OPTIONS =================================//
//======================================================//
Vue.use(VueRouter);

//============ ROUTES ==================================//
//======================================================//
const routes = [
  {
    path: '/',
    redirect: {
      name: 'dashboard'
    }
  },
  {
    path: '/auth/:uuid?',
    name: 'login',
    component: PageDefault,
    props: {
      component: Login,
      modules: ['login', 'intro']
    },
    meta: {
      title: i18n.t('onboarding.title'),
      caption: i18n.t('onboarding.title'),
      navbarCaption: i18n.t('onboarding.title'),
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard',
    }
  },
  {
    path: '/user-settings/',
    name: 'user-settings',
    component: PageDefault,
    props: {
      component: UserSettings,
      modules: ['user-settings']
    },
    meta: {
      title: i18n.t('navigation.settings'),
      caption: i18n.t('navigation.settings'),
      navbarCaption: i18n.t('navigation.settings'),
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding'
    }
  },
  {
    path: '/pages/:id',
    name: 'pages',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules:  ['pages']
    },
    meta: {
      title: '',
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding',
    }
  },

  {
    path: '/onboarding',
    name: 'dashboard',
    component: PageDefault,
    props: {
      component: OnboardingDashboard,
      modules: ['onboarding', 'onboarding-dashboard']
    },
    meta: {
      title: i18n.t('onboarding.title'),
      caption: i18n.t('onboarding.title'),
      navbarCaption: '',
      navbarBuilding: true,
      navbarButton: 'none',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/onboarding/directory',
    name: 'onboarding-directory',
    component: PageDefault,
    props: {
      component: OnboardingDirectory,
      modules: ['onboarding', 'onboarding-directory']
    },
    meta: {
      title: i18n.t('onboarding.directoryTitle'),
      caption: i18n.t('onboarding.directoryTitle'),
      navbarCaption: i18n.t('onboarding.directoryTitle'),
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding',
    }
  },

  {
    path: '/onboarding/documents',
    name: 'onboarding-documents',
    component: PageDefault,
    props: {
      component: OnboardingDocuments,
      modules: ['onboarding', 'onboarding-directory', 'onboarding-documents']
    },
    meta: {
      title: i18n.t('onboarding.directoryTitle'),
      caption: i18n.t('onboarding.directoryTitle'),
      navbarCaption: i18n.t('onboarding.directoryTitle'),
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding/directory',
    }
  },

  {
    path: '/onboarding/photo',
    name: 'onboarding-photo',
    component: PageDefault,
    props: {
      component: OnboardingPhotoUpload,
      modules: ['onboarding', 'onboarding-photo']
    },
    meta: {
      title: i18n.t('onboarding.photo'),
      caption: i18n.t('onboarding.photo'),
      navbarCaption: i18n.t('onboarding.photo'),
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding/directory',
    }
  },

  {
    path: '/onboarding/document/:category',
    name: 'onboarding-document',
    component: PageDefault,
    props: {
      component: OnboardingDocumentUpload,
      modules: ['onboarding', 'onboarding-document']
    },
    meta: {
      title: i18n.t('onboarding.directoryTitle'),
      caption: i18n.t('onboarding.directoryTitle'),
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding/documents',
    }
  },

  {
    path: '/onboarding/document-confirm/:category',
    name: 'onboarding-document-confirm',
    component: PageDefault,
    props: {
      component: OnboardingDocumentConfirm,
      modules: ['onboarding', 'onboarding-document']
    },
    meta: {
      title: i18n.t('onboarding.directoryTitle'),
      caption: i18n.t('onboarding.directoryTitle'),
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding/documents',
    }
  },

  {
    path: '/onboarding/form',
    name: 'onboarding-form',
    component: PageDefault,
    props: {
      component: OnboardingForm,
      modules: ['onboarding', 'onboarding-form']
    },
    meta: {
      title: '',
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'custom',
    }
  },

  {
    path: '/onboarding/:categoryId-:categorySlug',
    name: 'onboarding-faq',
    component: PageDefault,
    props: {
      component: OnboardingFaq,
      modules: ['onboarding', 'onboarding-static']
    },
    meta: {
      title: '',
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding',
    }
  },

  {
    path: '/onboarding/:id-:categorySlug',
    name: 'onboarding-article',
    component: PageDefault,
    props: {
      component: PagesDetail,
      modules: ['onboarding', 'onboarding-static']
    },
    meta: {
      title: '',
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding',
    }
  },

  {
    path: '/onboarding/contacts',
    name: 'onboarding-contacts',
    component: PageDefault,
    props: {
      component: OnboardingContacts,
      modules: ['onboarding', 'onboarding-contacts']
    },
    meta: {
      title: '',
      caption: '',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'onboarding',
    }
  },

  { path: '*',
    component: PageService,
    props: {
      component: NotFound404,
      modules:  ['error-pages', '404']
    },
    meta: {
      title: 'Page not found',
      navbarCaption: '',
      navbarBuilding: false,
      navbarButton: 'back',
      navbarButtonMode: 'path',
      navbarButtonDestination: 'dashboard'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0};
    }
  },
});

router.beforeEach((to, from, next) => {
  let isLogged = loginUtils.checkLoggedState();
  const entryPath = window.location.pathname + window.location.search;

  if (!isLogged && entryPath.indexOf('login') === -1 && entryPath !== '/user-settings') {
    const entryPathFixed = entryPath.replace(new RegExp('^' + process.env.BASE_URL),'/'); // remove base path, e.g. /pilot
    localStorage.setItem(APP_ENTRY_PATH_STORAGE_KEY, entryPathFixed);
  }

  if (to.name !== 'login' && !isLogged) {
    next({ name: 'login' });
  } else {
    next();
  }
});

// ============================================================== //
// //////////////////////////// MISC //////////////////////////// //
// ============================================================== //

//============ PAGE TITLE / META =======================//
//======================================================//
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// https://alligator.io/vuejs/vue-router-modify-head/
// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  } else {
    document.title = 'ČSOB Nástup'
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router;
