<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div class="vue-component vue-c-slider" :class="classObject">
        <vue-slider v-if="!noRange" v-model="valueData" v-bind="optionsResult" />
        <vue-slider v-else v-model="valueData" v-bind="optionsResultNoRange" />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== LIB
import VueSlider from 'vue-slider-component';
//=== MISC
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1027Slider',
    components: {
        VueSlider
    },
    model: {
        prop: 'value',
        event: 'sliderChangeEvent'
    },
    props: {
        value: null,
        min: Number,
        max: Number,
        interval: Number,
        marks: null,
        marksSteps: Boolean,
        marksLabels: {
            default: true,
            type: Boolean
        },
        active: Boolean,
        disabled: {
            default: false,
            type: Boolean
        },
        contained: Boolean,
        handleNoRange: Boolean,
        keyboardControls: Boolean,
        linkedComponent: Boolean,
        // see options - https://nightcatsama.github.io/vue-slider-component/#/api/props
        options: null
    },
    data() {
        return {
            valueData: this.value
        };
    },
    computed: {
        //============ GENERAL =================================//
        //======================================================//
        classObject() {
            return [
                {
                    'vue-has-marks': this.optionsResult.marks,
                    'vue-has-marks-steps': this.optionsResult.marks && this.marksSteps,
                    'vue-has-marks-steps-hidden': this.optionsResult.marks && !this.marksSteps,
                    'vue-has-marks-labels': this.optionsResult.marks && this.marksLabels,
                    'vue-has-marks-labels-hidden': this.optionsResult.marks && !this.marksLabels,
                    'vue-is-contained': this.optionsResult.contained,
                    'vue-is-disabled': this.optionsResult.disabled || this.disabledNoRange,
                    'vue-is-active': this.active,
                    'vue-is-not-set': this.notSet,
                    'vue-has-no-range': this.noRange
                }
            ];
        },
        // result of config options merged with options passed via prop
        optionsComputed() {
            return { ...config.frm1027slider.options, ...this.options };
        },
        // options set directly via prop - assembled to object, that will merged with options computed
        optionsPassedDirectly() {
            return {
                min: this.min,
                max: this.max,
                interval: this.interval,
                marks: this.marks,
                disabled: this.disabled,
                contained: this.contained,
                useKeyboard: this.keyboardControls
            };
        },
        // resulting config
        // 1. framework config
        // 2. passed prop options
        // 3. direct override by passed props (disabled, min, max etc.)
        optionsResult() {
            return { ...this.optionsComputed, ...this.optionsPassedDirectly };
        },
        // bugfix for Vue (watch object) - see watcher for more info
        // uncomment & use if needed
        // optionsResultStringified() {
        //     return JSON.stringify(this.optionsResult);
        // },
        //============ NO RANGE ================================//
        //======================================================//
        // special options for no range case (min === max)
        // renders special slider, which is disabled with slider handle on the right
        // with mark with value to the right (optional)
        optionsPassedDirectlyNoRange() {
            if (this.handleNoRange && this.noRange) {
                return {
                    min: this.minNoRange,
                    max: this.max,
                    interval: 1,
                    marks: this.marksNoRange,
                    disabled: true,
                    contained: this.contained,
                    useKeyboard: this.keyboardControls
                };
            }

            return null;
        },
        optionsResultNoRange() {
            if (this.handleNoRange && this.noRange) {
                return { ...this.optionsComputed, ...this.optionsPassedDirectlyNoRange };
            }

            return null;
        },
        minNoRange() {
            if (this.handleNoRange && this.noRange) {
                return this.max - 1;
            }

            return null;
        },
        marksNoRange() {
            if (this.handleNoRange && this.noRange) {
                return this.optionsResult.marks;
            }

            return false;
        },
        disabledNoRange() {
            if (this.handleNoRange && this.noRange) {
                return this.optionsResultNoRange.disabled;
            }

            return false;
        },
        noRange() {
            if (this.handleNoRange) {
                if (
                    (this.optionsResult.min || this.optionsResult.min === 0) &&
                    (this.optionsResult.max || this.optionsResult.max === 0)
                ) {
                    return this.optionsResult.min === this.optionsResult.max;
                }
            }

            return false;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.value === null && this.value !== 0;
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        valueData(value) {
            this.$emit('sliderChangeEvent', value); // event for v-model
        },
        linkedComponent(value) {
            if (value) {
                this.attachEventsForLinkedComponent();
            } else {
                this.detachEventsForLinkedComponent();
            }
        }
        // bugfix for Vue (watch object) - watcher on prop, which is object triggers watch on v-model change on all instances
        // of component, that has the prop manually set, solution is to watch this stringified computed value
        // if there is need to work with this value, it can be unstringified
        // uncomment & use if needed
        // optionsResultStringified(value) {
        //     let optionsResultNew = JSON.parse(valueNew);
        // }
    },
    mounted() {
        // attach events only if linked component - just precaution because of prevent on mousedown
        if (this.linkedComponent) {
            this.attachEventsForLinkedComponent();
        }
    },
    methods: {
        click() {
            if (!this.optionsResult.disabled && !this.noRange) {
                this.$emit('clickEvent');
                this.$emit('interactionEvent');
            }
        },
        mouseDown(event) {
            this.$emit('interactionEvent');

            event.preventDefault();
            event.stopPropagation();
            this.$emit('mousedownEvent', event);
        },
        attachEventsForLinkedComponent() {
            // used to emit click event on slider handle / rail, usually to focus on linked component
            // mouseDown is used to prevent losing focus on linkedComponent when dragging the slider or clicking the rail
            // this is custom hook to Vue component internals
            let sliderDot = this.$el.querySelectorAll('.vue-slider-dot')[0];
            let sliderWrapper = this.$el.querySelectorAll('.vue-slider')[0];

            // TODO MBU: add events to marks - cycle through them, watch and update on marks changed
            // let sliderMarks = this.$el.querySelectorAll('.vue-slider-mark');
            // now focus is lost when clicked on mark

            if (sliderDot) {
                sliderDot.addEventListener('click', this.click);
                sliderDot.addEventListener('click', this.click);
                sliderDot.addEventListener('mousedown', this.mouseDown);
            }

            if (sliderWrapper) {
                sliderWrapper.addEventListener('click', this.click);
                sliderWrapper.addEventListener('mousedown', this.mouseDown);
            }
        },
        detachEventsForLinkedComponent() {
            let sliderDot = this.$el.querySelectorAll('.vue-slider-dot')[0];
            let sliderWrapper = this.$el.querySelectorAll('.vue-slider')[0];

            if (sliderDot) {
                sliderDot.addEventListener('click', this.click);
                sliderDot.addEventListener('mousedown', this.mouseDown);
            }

            if (sliderWrapper) {
                sliderWrapper.addEventListener('click', this.click);
                sliderWrapper.addEventListener('mousedown', this.mouseDown);
            }
        }
    }
};
</script>
