<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-date-picker" :class="classObjectComputed">
        <!--========== FORM FIELD =============================-->
        <!--===================================================-->
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.INPUT"
                class="vue-label"
                >{{ label }}</label
            >
            <input
                :id="inputId"
                ref="input"
                :value="selectedDateToString(valueComputed)"
                type="text"
                class="vue-input"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                :placeholder="placeholder"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="inputClick($event.target.value, $event)"
                @keyup="inputKeyUp($event.target.value, $event)"
                @keydown="inputKeyDown($event.target.value, $event)"
                @input="inputInput($event.target.value, $event)"
                @change="inputChange($event.target.value, $event)"
                @focus="inputFocus($event.target.value, $event)"
                @blur="inputBlur($event.target.value, $event)"
            />
            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>
            <frm1006-button
                ref="buttonCalendarToggle"
                type="internal"
                :disabled="disabled || readonly"
                :title="buttonCalendarToggleCaption"
                :tabindex="-1"
                class="vue-ci-button-calendar-toggle"
                @buttonClickEvent="buttonCalendarToggleClick"
            >
                {{ buttonCalendarToggleCaption }}
            </frm1006-button>

            <!--======= CONTEXT CONTAINER ===============-->
            <!--=========================================-->
            <gen1011-context-container
                ref="calendar"
                class="vue-ci-calendar-container"
                :buttonClose="false"
                :active.sync="expandedData"
                :whiteList="contextContainerWhiteListComputed"
                @update:active="contextContainerActiveChange"
            >
                <!-- TODO MBU: create separate component or helper -->
                <div class="vue-b-calendar" :class="['vue-is-' + calendarView]">
                    <!--==== CALENDAR HEADER ==========-->
                    <div class="vue-b-calendar-header">
                        <frm1006-button
                            class="vue-ci-button-calendar-prev"
                            type="internal"
                            mode="button"
                            :tabindex="-1"
                            :preventLosingFocus="true"
                            @buttonClickEvent="changeCurrentViewDate(-1)"
                            >Dříve
                        </frm1006-button>
                        <frm1006-button
                            v-if="selectMode === 'views'"
                            class="vue-ci-button-calendar-range"
                            type="internal"
                            mode="button"
                            :tabindex="-1"
                            :preventLosingFocus="true"
                            @buttonClickEvent="buttonCalendarRangeClick"
                        >
                            <span
                                v-if="calendarView === constants.calendarViews.days"
                                :key="calendarView === constants.calendarViews.days"
                                >{{ currentMonthIndex | formatMonth }}</span
                            >
                            <span
                                v-if="
                                    calendarView === constants.calendarViews.days ||
                                        calendarView === constants.calendarViews.months
                                "
                                :key="calendarView === constants.calendarViews.months"
                                >{{ currentYear }}</span
                            >
                            <span
                                v-else-if="calendarView === constants.calendarViews.years"
                                :key="calendarView === constants.calendarViews.years"
                                >{{ currentDecadeStartYear }} - {{ currentDecadeEndYear }}</span
                            >
                            <span
                                v-else-if="calendarView === constants.calendarViews.decades"
                                :key="calendarView === constants.calendarViews.decades"
                                >{{ currentCenturyStartYear }} - {{ currentCenturyEndYear }}</span
                            >
                        </frm1006-button>
                        <frm1006-button
                            class="vue-ci-button-calendar-next"
                            type="internal"
                            mode="button"
                            :tabindex="-1"
                            :preventLosingFocus="true"
                            @buttonClickEvent="changeCurrentViewDate(1)"
                            >Později
                        </frm1006-button>
                    </div>

                    <!--==== CALENDAR CONTENT =========-->
                    <div class="vue-b-calendar-content">
                        <!--=== DAYS ===-->
                        <div v-if="calendarView === constants.calendarViews.days" class="vue-calendar-view">
                            <table class="vue-calendar-table">
                                <thead>
                                    <tr class="vue-calendar-week">
                                        <th><span>po</span></th>
                                        <th><span>út</span></th>
                                        <th><span>st</span></th>
                                        <th><span>čt</span></th>
                                        <th><span>pá</span></th>
                                        <th><span>so</span></th>
                                        <th><span>ne</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, indexWeek) in weeks" :key="indexWeek" class="vue-calendar-week">
                                        <frm1010-datepicker-day
                                            v-for="(date, indexDay) in row"
                                            :key="indexDay"
                                            :date="dateToString(date)"
                                            :dateSelected="selectedDate ? dateToString(selectedDate) : ''"
                                            :otherMonth="date.getMonth() !== currentMonthIndex"
                                            :weekend="date.getDay() === 0 || date.getDay() === 6"
                                            :today="dateToString(today)"
                                            @calendarDayClickEvent="selectDate(date)"
                                        >
                                            {{ date.getDate() }}
                                        </frm1010-datepicker-day>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!--=== MONTHS ===-->
                        <div v-else-if="calendarView === constants.calendarViews.months" class="vue-calendar-view">
                            <ul class="vue-calendar-list">
                                <frm1010-datepicker-month
                                    v-for="(month, index) in months"
                                    :key="index"
                                    :month="index"
                                    :todayMonthIndex="todayMonthIndex"
                                    :todayYear="todayYear"
                                    :selectedMonthIndex="selectedMonthIndex"
                                    :selectedYear="selectedYear"
                                    :currentMonthIndex="currentMonthIndex"
                                    :currentYear="currentYear"
                                    @calendarMonthClickEvent="selectMonth(index)"
                                >
                                    <span>{{ month }}</span>
                                </frm1010-datepicker-month>
                            </ul>
                        </div>

                        <!--=== YEARS ===-->
                        <div v-else-if="calendarView === constants.calendarViews.years" class="vue-calendar-view">
                            <ul class="vue-calendar-list">
                                <li
                                    v-for="(year, i) in years"
                                    :key="year + i"
                                    class="vue-calendar-item"
                                    :class="{
                                        'vue-is-today': todayYear === year,
                                        'vue-is-other-decade':
                                            currentDecadeStartYear > year || currentDecadeEndYear < year,
                                        'vue-is-selected': selectedDate ? selectedDate.getFullYear() === year : false
                                    }"
                                    @click="selectYear(year)"
                                >
                                    <span>{{ year }}</span>
                                </li>
                            </ul>
                        </div>

                        <!--=== DECADES ===-->
                        <div v-else-if="calendarView === constants.calendarViews.decades" class="vue-calendar-view">
                            <ul class="vue-calendar-list">
                                <li
                                    v-for="(decade, i) in decades"
                                    :key="decade + i"
                                    class="vue-calendar-item"
                                    :class="{
                                        'vue-is-today':
                                            decade.firstYearInDecade <= todayYear &&
                                            decade.lastYearInDecade >= todayYear,
                                        'vue-is-other-century':
                                            decade.firstYearInDecade < currentCenturyStartYear ||
                                            decade.lastYearInDecade > currentCenturyEndYear,
                                        'vue-is-selected': selectedDate
                                            ? decade.firstYearInDecade <= selectedDate.getFullYear() &&
                                              decade.lastYearInDecade >= selectedDate.getFullYear()
                                            : false
                                    }"
                                    @click="selectDecade(decade)"
                                >
                                    <span>{{ decade.firstYearInDecade }} - {{ decade.lastYearInDecade }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!--========== FOOTER =================================-->
                    <!--===================================================-->
                    <div class="vue-b-calendar-footer">
                        <frm1006-button
                            class="vue-ci-button-calendar-today"
                            type="internal"
                            caption="Dnes"
                            :tabindex="-1"
                            :preventLosingFocus="true"
                            @buttonClickEvent="calendarTodayButtonClick"
                            >Dnes
                        </frm1006-button>
                        <frm1006-button
                            class="vue-ci-button-calendar-close"
                            type="internal"
                            caption="Zavřít"
                            :tabindex="-1"
                            :preventLosingFocus="true"
                            @buttonClickEvent="contextContainerClose('buttonClose')"
                            >Zavřít
                        </frm1006-button>
                    </div>
                </div>
            </gen1011-context-container>
        </div>

        <!--========== TOOLTIP ================================-->
        <!--===================================================-->
        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Gen1011ContextContainer from '../../gen/gen1011-context-container/gen1011-context-container';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';
import Frm1010DatepickerDay from '../../frm/frm1010-datepicker/frm1010-datepicker-day';
import Frm1010DatepickerMonth from '../../frm/frm1010-datepicker/frm1010-datepicker-month';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClear from '../../mixins/buttonClear';
import Tooltip from '../../mixins/tooltip';
import DigitalTracking from '../../mixins/digital-tracking';
import Localization from '../../mixins/localization';
import Position from '../../mixins/position';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1010';

let constants = {
    calendarViews: {
        days: 'days',
        months: 'months',
        years: 'years',
        decades: 'decades'
    }
};

//============ OPTIONS =================================//
//======================================================//
let options = {
    contextContainerPositionDefault: {
        x: 'right',
        y: 'bottom'
    },
    scrollDebounceTimeout: 150
};

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1010Datepicker',
    components: {
        Gen1010InformationTooltip,
        Gen1011ContextContainer,
        Frm1006Button,
        Frm1010DatepickerDay,
        Frm1010DatepickerMonth
    },
    filters: {
        // TODO duplicated array of months
        formatMonth(m) {
            return [
                'leden',
                'únor',
                'březen',
                'duben',
                'květen',
                'červen',
                'červenec',
                'srpen',
                'září',
                'říjen',
                'listopad',
                'prosinec'
            ][m];
        }
    },
    mixins: [Component, ButtonClear, Tooltip, Localization, Position, DigitalTracking],
    model: {
        prop: 'value',
        event: 'datePickerChangeEvent'
    },
    props: {
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        value: {
            default: null,
            type: Date
        },
        placeholder: String,
        selectMode: {
            default: 'views',
            type: String
        },
        buttonCalendarToggleOpenCaption: String,
        buttonCalendarToggleCloseCaption: String,
        selectValueOnFocus: Boolean,
        //=== ADDITIONAL ELEMENTS
        decorator: {
            default: false,
            type: Boolean
        },
        //=== CONTEXT CONTAINER
        contextContainerWhiteList: {
            default: () => {
                return [];
            },
            type: Array
        },
        contextContainerOpenOnFocus: Boolean,
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            valueData: '',
            valueDataStringLive: '',

            expandedData: false,
            contextContainerWhiteListInitial: [],
            contextContainerPosition: {
                x: options.contextContainerPositionDefault.x,
                y: options.contextContainerPositionDefault.y
            },
            //=== TODAY
            today: new Date(),
            //=== CURRENT
            currentYear: null, // initial value, will be set to selection
            currentMonthIndex: null, // initial value, will be set to selection
            currentDecadeStartYear: null,
            currentDecadeEndYear: null,
            currentCenturyStartYear: null,
            currentCenturyEndYear: null,
            // TODO MBU: VALIDATE
            //=== MONTHS
            months: [
                // TODO duplicate, use only one def
                'leden',
                'únor',
                'březen',
                'duben',
                'květen',
                'červen',
                'červenec',
                'srpen',
                'září',
                'říjen',
                'listopad',
                'prosinec'
            ],
            constants: {
                calendarViews: constants.calendarViews
            },
            calendarView: 'view-days',
            componentIsActive: false,
            focused: false
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                'vue-has-calendar-position-' + this.contextContainerPosition.x,
                'vue-has-calendar-position-' + this.contextContainerPosition.y,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-has-decorator': this.decorator,
                    'vue-is-expanded': this.expandedData,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused
                }
            ];
        },
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.updateInternalState(value);
            }
        },
        buttonCalendarToggleCaption() {
            if (this.expandedData) {
                return this.buttonCalendarToggleOpenCaption
                    ? this.buttonCalendarToggleOpenCaption
                    : this.i18n('buttonCalendarToggleClose');
            }

            return this.buttonCalendarToggleCloseCaption
                ? this.buttonCalendarToggleCloseCaption
                : this.i18n('buttonCalendarToggleOpen');
        },
        contextContainerWhiteListComputed() {
            let whiteList = [];

            // initial internal white list setting
            if (this.contextContainerWhiteListInitial.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteListInitial.length; i++) {
                    let element = this.contextContainerWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            // add passed items via whiteList prop
            if (this.contextContainerWhiteList.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteList.length; i++) {
                    let element = this.contextContainerWhiteList[i];
                    whiteList.push(element);
                }
            }

            return whiteList;
        },
        //============ DATE ====================================//
        //======================================================//

        //=== SELECTED
        // TODO MBU: basically just alias for valueData, remove?
        selectedDate() {
            return this.valueData;
        },
        selectedMonthIndex() {
            return this.selectedDate ? this.selectedDate.getMonth() : null;
        },
        selectedYear() {
            return this.selectedDate ? this.selectedDate.getFullYear() : null;
        },
        //=== TODAY
        todayMonthIndex() {
            return this.today.getMonth();
        },
        todayYear() {
            return this.today.getFullYear();
        },
        //=== CURRENT
        currentDecadeStartYearComputed() {
            if (this.currentYear) {
                return parseInt(this.currentYear.toString().replace(/.$/, '0'), 10);
            }
            return null;
        },
        currentDecadeEndYearComputed() {
            if (this.currentDecadeStartYearComputed) {
                return this.currentDecadeStartYearComputed + 9;
            }
            return null;
        },
        currentCenturyStartYearComputed() {
            if (this.currentYear) {
                return Math.floor(this.currentYear / 100) * 100;
            }
            return null;
        },
        currentCenturyEndYearComputed() {
            if (this.currentCenturyStartYearComputed) {
                return this.currentCenturyStartYearComputed + 99;
            }
            return null;
        },
        //=== DATES

        /**
         * Build dates Array
         * If the current month does not start with Monday, prepend days from previous month
         * If the current month does not end with Sunday, append days from next month
         *
         * @returns {Array}
         */
        dates() {
            let ret = [];
            let firstDayOfMonth = new Date(this.currentYear, this.currentMonthIndex, 1);
            let lastDayOfMonth = new Date(
                this.currentYear,
                this.currentMonthIndex,
                this.getLastDayOfMonth(this.currentMonthIndex)
            );
            ret.unshift(firstDayOfMonth);
            for (let d = this.prevDate(firstDayOfMonth); d.getDay() !== 0; d = this.prevDate(d)) {
                ret.unshift(d);
            }
            for (let d = this.nextDate(firstDayOfMonth); d <= lastDayOfMonth; d = this.nextDate(d)) {
                ret.push(d);
            }
            for (let d = this.nextDate(lastDayOfMonth); d.getDay() !== 1; d = this.nextDate(d)) {
                ret.push(d);
            }
            return ret;
        },
        weeks() {
            return this.dates.reduce(function(p, c, i) {
                if (i % 7 === 0) {
                    p[p.length] = [];
                }
                p[p.length - 1].push(c);
                return p;
            }, []);
        },
        years() {
            const DECADE = 10;
            let yearsArr = [];
            let yearsArrExpanded = [];

            let firstYearOfDecade = this.currentDecadeStartYear;

            for (let i = 0; i < DECADE; i++) {
                yearsArr.push(firstYearOfDecade + i);
            }

            yearsArrExpanded = [firstYearOfDecade - 1, ...yearsArr, firstYearOfDecade + DECADE];

            return yearsArrExpanded;
        },
        decades() {
            const DECADES_IN_CENTURY = 10;
            const YEARS_IN_DECADE = 10;

            let decadesArr = [];
            let decadesArrExpanded = [];

            let firstDecadeStart = this.currentCenturyStartYear;

            for (let i = 0; i < DECADES_IN_CENTURY; i++) {
                let currentDecadeStartYear = firstDecadeStart + i * YEARS_IN_DECADE;
                let currentDecadeEndYear = currentDecadeStartYear + 9;

                decadesArr.push({
                    firstYearInDecade: currentDecadeStartYear,
                    lastYearInDecade: currentDecadeEndYear
                });
            }

            decadesArrExpanded = [
                {
                    firstYearInDecade: firstDecadeStart - 10,
                    lastYearInDecade: firstDecadeStart - 1
                },
                ...decadesArr,
                {
                    firstYearInDecade: firstDecadeStart + 100,
                    lastYearInDecade: firstDecadeStart + 109
                }
            ];

            return decadesArrExpanded;
        },
        leapYear() {
            if (this.currentYear % 100 === 0) {
                return this.currentYear % 400 === 0;
            } else {
                return this.currentYear % 4 === 0;
            }
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        inputId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.INPUT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === '';
        }
    },
    watch: {
        value(date) {
            this.updateInternalState(date);
        },
        expandedData(value) {
            if (value) {
                // wait until contextContainer is really rendered
                this.$nextTick().then(() => {
                    this.updateContextContainerPosition();
                    this.createViewportChangeListeners();
                });
                // close tooltip on context container open
                this.tooltipExpandedData = false;
                this.calendarView = this.constants.calendarViews.days;
                this.today = new Date();
            } else {
                this.resetContextContainerPosition();
                this.removeViewportChangeListeners();
            }
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.input.focus();
            }
        },
        componentIsActive(value) {
            this.$emit('componentIsActiveEvent', value);
        },
        focused(value) {
            this.$emit('inputFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            this.$emit('tooltipWhiteListInitial', value);
        },
        currentDecadeStartYearComputed(value) {
            if (value) {
                this.currentDecadeStartYear = this.currentDecadeStartYearComputed;
            }
        },
        currentDecadeEndYearComputed(value) {
            if (value) {
                this.currentDecadeEndYear = this.currentDecadeEndYearComputed;
            }
        },
        currentCenturyStartYearComputed(value) {
            if (value) {
                this.currentCenturyStartYear = this.currentCenturyStartYearComputed;
            }
        },
        currentCenturyEndYearComputed(value) {
            if (value) {
                this.currentCenturyEndYear = this.currentCenturyEndYearComputed;
            }
        }
    },
    mounted() {
        //=== DATE
        this.valueDataStringLive = '';
        this.updateInternalState(this.value);
        this.today = new Date();
        this.currentYear = this.today.getFullYear();
        this.currentMonthIndex = this.today.getMonth();
        this.currentDecadeStartYear = parseInt(this.currentYear.toString().replace(/.$/, '0'), 10);
        this.currentDecadeEndYear = this.currentDecadeStartYear + 9;
        this.currentCenturyStartYear = Math.floor(this.currentYear / 100) * 100;
        this.currentCenturyEndYear = this.currentCenturyStartYear + 99;

        //=== TOOLTIP
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        //=== CONTEXT CONTAINER WHITE LIST
        this.setContextContainerWhiteListInitial();
    },
    methods: {
        //========= GENERAL ==========================//
        //============================================//
        inputSetFocus() {
            this.$refs.input.focus();
        },
        inputSetBlur() {
            this.$refs.input.blur();
        },
        setComponentActiveState() {
            if (!this.readonly) {
                this.componentIsActive = document.activeElement === this.$refs.input || this.expandedData;
            }
        },
        setInputFocusState() {
            this.focused = document.activeElement === this.$refs.input;
        },
        //========= EVENTS ===========================//
        //============================================//

        //=== INPUT
        inputClick(value, event) {
            this.$emit('inputClickEvent', value, event);
        },
        inputChange(value, event) {
            let date = this.stringToDate(value);
            this.updateInternalState(date);
            this.$emit('inputChangeEvent', value, event);
        },
        inputInput(value, event) {
            let date = this.stringToDate(value);
            this.updateInternalStateLive(date, value);
            this.$emit('inputInputEvent', value, event);
        },
        inputFocus(value, event) {
            this.$emit('inputFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setInputFocusState();
            // context container
            if (this.contextContainerOpenOnFocus && !this.disabled && !this.readonly) {
                if (this.tooltipOpenOnFocus === 'all' && !this.tooltipContent) {
                    this.contextContainerOpen('focus');
                } else if (this.tooltipOpenOnFocus === 'invalidOnly' && this.state !== 'invalid') {
                    this.contextContainerOpen('focus');
                } else if (this.tooltipOpenOnFocus === 'off') {
                    this.contextContainerOpen('focus');
                }
            }
            // tooltip
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                !this.tooltipDisabled &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
            // select value on focus
            if (this.selectValueOnFocus) {
                this.$refs.input.select();
            }
        },
        inputBlur(value, event) {
            this.$emit('inputBlurEvent', value, event);
            this.valueDataStringLive = '';
            this.updateInternalState();
            this.setComponentActiveState();
        },
        inputKeyDown(value, event) {
            this.$emit('inputKeyDownEvent', value, event);

            // TODO MBU: keyboard control
            //=== KEYS
            if (event.key === 'ArrowDown') {
                // TODO MBU: open on arrow down
            } else if (event.key === 'ArrowUp') {
                // TODO MBU: open on arrow up
            } else if (event.key === 'Enter') {
                // TODO MBU: open on enter
            } else if (event.key === 'Tab') {
                // TODO MBU: on first tab close context container if open? Or move closing on blur event?
                this.contextContainerClose('tabbed out');
            }
        },
        inputKeyUp(value, event) {
            this.$emit('inputKeyUpEvent', value, event);
        },
        //========= CALENDAR =========================//
        //============================================//

        //=== BUTTON CALENDAR TOGGLE
        buttonCalendarToggleClick(event) {
            this.$emit('buttonCalendarToggleClickEvent', event);
            this.contextContainerToggle('buttonCalendarToggle');
            this.inputSetFocus();
        },
        //=== BUTTON CALENDAR TODAY
        calendarTodayButtonClick() {
            this.selectDate(this.today);
        },
        //=== BUTTON CALENDAR RANGE
        buttonCalendarRangeClick() {
            switch (this.calendarView) {
                case this.constants.calendarViews.days:
                    this.calendarView = this.constants.calendarViews.months;
                    break;
                case this.constants.calendarViews.months:
                    this.calendarView = this.constants.calendarViews.years;
                    break;
                case this.constants.calendarViews.years:
                    this.calendarView = this.constants.calendarViews.decades;
                    break;
                case this.constants.calendarViews.decades:
                    this.calendarView = this.constants.calendarViews.days;
                    break;
                default:
                    this.calendarView = this.constants.calendarViews.days;
            }
        },

        //============ TOOLTIP =================================//
        //======================================================//
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // input
            this.tooltipWhiteListInitial.push(this.$refs.input);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        },
        //============ CONTEXT CONTAINER =======================//
        //======================================================//
        contextContainerToggle(trigger) {
            this.expandedData = !this.expandedData;
            this.$emit('contextContainerToggleEvent', trigger, this.expandedData);
        },
        contextContainerOpen(trigger) {
            this.expandedData = true;
            this.$emit('contextContainerOpenEvent', trigger);
        },
        contextContainerClose(trigger) {
            this.expandedData = false;
            this.$emit('contextContainerCloseEvent', trigger);
        },
        contextContainerActiveChange(value) {
            this.$emit('update:expanded', value);
            this.$emit('contextContainerExpandedChangeEvent', value);
        },
        setContextContainerWhiteListInitial() {
            this.contextContainerWhiteListInitial = [];

            // input
            if (this.$refs.input !== undefined) {
                this.contextContainerWhiteListInitial.push(this.$refs.input);
            }

            // button calendar toggle
            if (this.$refs.buttonCalendarToggle !== undefined) {
                let buttonCalendarToggleElements = this.$refs.buttonCalendarToggle.$el.querySelectorAll('*');
                for (let element of buttonCalendarToggleElements) {
                    this.contextContainerWhiteListInitial.push(element);
                }
            }
        },
        //============ POSITION ================================//
        //======================================================//
        updateContextContainerPosition() {
            // set current position for calculation
            let positionData = this.getPositionRelativeTo(this.$refs.calendar.$el, this.contextContainerPosition);
            // update only when needed
            this.contextContainerPosition = positionData.position;
        },
        resetContextContainerPosition() {
            this.contextContainerPosition.x = options.contextContainerPositionDefault.x;
            this.contextContainerPosition.y = options.contextContainerPositionDefault.y;
        },
        windowScrolled() {
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
            }
            this.scrollDebounce = setTimeout(() => {
                this.updateContextContainerPosition();
                this.scrollDebounce = null;
            }, options.scrollDebounceTimeout);
        },
        windowResized() {
            if (this.expandedData) {
                this.updateContextContainerPosition();
            }
        },
        createViewportChangeListeners() {
            window.addEventListener('scroll', this.windowScrolled);
            window.addEventListener('resize', this.windowResized);
        },
        removeViewportChangeListeners() {
            window.removeEventListener('scroll', this.windowScrolled);
            window.removeEventListener('scroll', this.windowResized);
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
                this.scrollDebounce = null;
            }
        },
        //============ DATE ====================================//
        //======================================================//
        updateInternalState(date) {
            let dateEval = this.evaluateDateString(this.dateToString(date));

            if (dateEval) {
                this.$emit('datePickerChangeEvent', date); // event for v-model
                this.today = new Date();
                this.valueData = date;
                this.currentMonthIndex = date.getMonth();
                this.currentYear = date.getFullYear();
            } else {
                if (this.selectedDate) {
                    this.$forceUpdate(); // force update if the value doesn't change and it won't trigger prop change automatically
                    this.$emit('datePickerChangeEvent', this.selectedDate); // event for v-model
                } else {
                    this.$forceUpdate(); // force update if the value doesn't change and it won't trigger prop change automatically
                    this.$emit('datePickerChangeEvent', null); // event for v-model
                }
            }
        },
        updateInternalStateLive(date, value) {
            let dateEval = this.evaluateDateString(this.dateToString(date));
            this.valueDataStringLive = value;

            if (dateEval) {
                this.$emit('datePickerChangeEvent', date); // event for v-model
                this.today = new Date();
                this.valueData = date;
                this.currentMonthIndex = date.getMonth();
                this.currentYear = date.getFullYear();
            }
        },
        evaluateDateString(dateString) {
            // TODO MBU: apply regex leap year validation
            // regex leap year (^(((0[1-9]|1[0-9]|2[0-8])[\\.](0[1-9]|1[012]))|((29|30|31)[\\.](0[13578]|1[02]))|((29|30)[\\.](0[4,6,9]|11)))[\\.](19|[2-9][0-9])\d\d$)|(^29[\\.]02[\\.](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)
            let reg = new RegExp('^\\s*(3[01]|[12][0-9]|0?[1-9])\\.(1[012]|0?[1-9])\\.((?:19|20)\\d{2})\\s*$');
            let evalResult = reg.exec(dateString);
            return evalResult;
        },
        //========= PARSERS ==========================//
        dateToString(date) {
            if (date && !isNaN(date)) {
                let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
                let month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
                let year = date.getFullYear();

                return `${day}.${month}.${year}`;
            }
            return '';
        },
        selectedDateToString(date) {
            if (this.valueDataStringLive === '') {
                if (date && !isNaN(date)) {
                    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
                    let month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
                    let year = date.getFullYear();

                    return `${day}.${month}.${year}`;
                }
                return '';
            } else {
                return this.valueDataStringLive;
            }
        },
        stringToDate(string) {
            let dateEval = this.evaluateDateString(string);

            if (dateEval) {
                let stringArray = string.split('.');
                let day = stringArray[0];
                let monthIndex = stringArray[1] - 1;
                let year = stringArray[2];
                return new Date(year, monthIndex, day);
            } else {
                return null;
            }
        },
        //========= SELECT ===========================//
        selectDate(date) {
            this.updateInternalState(date);
            this.expandedData = false;
        },
        selectMonth(monthIndex) {
            this.currentMonthIndex = monthIndex;
            this.calendarView = this.constants.calendarViews.days;
        },
        selectYear(year) {
            this.currentYear = year;
            this.calendarView = this.constants.calendarViews.months;
        },
        selectDecade(decade) {
            this.currentDecadeStartYear = decade.firstYearInDecade;
            this.currentDecadeEndYear = decade.lastYearInDecade;
            this.calendarView = this.constants.calendarViews.years;
        },
        //========= CHANGE CURRENT ===================//
        changeCurrentViewDate(n) {
            switch (this.calendarView) {
                case this.constants.calendarViews.days:
                    this.changeCurrentMonth(n);
                    break;
                case this.constants.calendarViews.months:
                    this.changeCurrentYear(n);
                    break;
                case this.constants.calendarViews.years:
                    this.changeCurrentDecade(n);
                    break;
                case this.constants.calendarViews.decades:
                    this.changeCurrentCentury(n);
            }
        },
        changeCurrentMonth(n) {
            let temp = this.currentMonthIndex + n;
            if (temp < 0) {
                this.currentYear -= 1;
            } else if (temp >= 12) {
                this.currentYear += 1;
            }
            this.currentMonthIndex = (temp + 12) % 12;
        },
        changeCurrentYear(n) {
            this.currentYear += n;
        },
        changeCurrentDecade(n) {
            let nDecade = n * 10;
            this.currentYear = this.currentYear + nDecade;
        },
        changeCurrentCentury(n) {
            let nCentury = n * 100;
            this.currentYear = this.currentYear + nCentury;
        },
        //========= BUILD CALENDAR ===================//
        /**
         * Build calendar days
         * Consequent days in month
         *
         */
        nextDate(d) {
            let ret = new Date(d.valueOf());
            ret.setDate(d.getDate() + 1);
            return ret;
        },
        /**
         * Build calendar days
         * days in previous month to fill table
         *
         */
        prevDate(d) {
            let ret = new Date(d.valueOf());
            ret.setDate(d.getDate() - 1);
            return ret;
        },
        /**
         * Build calendar days
         * days in next month to fill table
         *
         */
        getLastDayOfMonth(m) {
            let ret = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (this.leapYear) {
                ret[1] = 29;
            }
            return ret[m];
        }
    }
};
</script>
