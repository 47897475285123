// GENERAL
export const ONE_YEAR_IN_MS = 31556952e3;
export const TWO_WEEKS_IN_MS = 12096e5;
export const HOUR_IN_MS = 1000 * 60 * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;
export const SUCCESS_MODAL_TIMEOUT = 2000;
export const ERROR_MODAL_TIMEOUT = 5000;
export const SHOW_CART_TIMEOUT = 15000;
export const MOBILE_BREAKPOINT_SIZE = 580;
export const DESKTOP_APP_BORDER_SIZE = 50;
export const DESKTOP_BREAKPOINT_SIZE = 1024;

// SYNC
export const LINKED = 'linked';
export const SYNC_STATUS = Object.freeze({
  ACTIVE: 1,
  PENDING: 2,
  EXPIRED: 3
});
export const SYNC_METHOD_SET = 'set';
export const SYNC_METHOD_REMOVE = 'remove';

// LISTS
export const DEFAULT_LIST_PAGINATION = 20;

// FORVADINO
export const SYNC_FORVARDINO_LESSON_ID = 'forvardinoLessonId';
export const FORVARDINO_EVENT_STATUS = Object.freeze({
  EVENT_ACTIVE: 1,
  EVENT_INACTIVE_WITH_RATING: 0,
  EVENT_INACTIVE: -1
});

// EVENTS
export const SYNC_EVENT_ID = 'eventId';
export const DEFAULT_EVENT_PAGINATION = 20;
export const EVENT_SPOT_ONLINE = 'online';
export const EVENT_SPOT_NHQ = 'nhq';
export const EVENT_SPOT_SHQ = 'shq';
export const EVENT_SPOT_OTHER = 'other';

// RESTAURANTS
export const RESTAURANT_NHQ = 'NHQ';
export const RESTAURANT_SHQ = 'SHQ';
export const RESTAURANT_HHQ = 'HHQ';

// SURVEY
export const SURVEY_KEY_PREFIX = 'SURVEY_'

// DATA LISTS IDS
export const ORGANIZATION_STRUCTURE_ID = 1;
export const CSOB_BUILDINGS_LIST_ID = 2;
export const CSOB_BRANCHES_LIST_ID = 3;
export const FAULT_REPORTING_CATEGORIES_LIST_ID = 4;
export const COVID_DASHBOARD_CATEGORIES_LIST = 5;
export const HHQ_DASHBOARD_ID = 7;
export const MOTOR_DASHBOARD_ID = 8;
export const PARENTS_DASHBOARD_ID = 9;
export const IDEAS_CATEGORIES_LIST_ID = 10;
export const ONBOARDING_DASHBOARD_ID = 11;

// ANNUAL REPORT
export const KUULA_ID = 'KUULA_IFRAME_POST_ID';

// COVID19 DASHBOARD
export const COVID19_KEY_PREFIX = 'COVID19_';

// FOOD ESHOP
export const FOOD_ESHOP_KEY_PREFIX = 'FOOD_ESHOP';
export const FOOD_ESHOP_AUTH_KEY = 'ca922433aeba4edfa07f84924a3d310e';
export const FOOD_ESHOP_ALLOWED_MINIMAL_ORDER = 300;
export const FOOD_ESHOP_PACKAGE_PRICE = 10;
export const FOOD_ESHOP_REFUNDABLE_PACKAGE = 100;
export const FOOD_ESHOP_DEFAULT_PAGINATION = 20;

export const FOOD_ESHOP_PAYMENTS = Object.freeze({
  DELIVERY_CASH: 1,
  DELIVERY_CARD: 2,
  CARD_ONLINE: 3,
  BENEFIT_CARD_ONLINE: 4,
  BENEFIT_CARD_DELIVERY: 6
});

export const FOOD_ESHOP_ORDER_STATES = Object.freeze({
  WAITING_FOR_SEND: 0,
  WAITING_FOR_PAYMENT: 5,
  PAYMENT_APPROVED: 1,
  ORDER_SENT: 4,
  ORDER_CANCELLED: 8
});

export const FOOD_ESHOP_PERSONAL_DELIVERY = Object.freeze({
  TRUE: 1,
  FALSE: 0
});

export const FOOD_ESHOP_PERSONAL_DELIVERY_BRANCH = Object.freeze({
  NONE: 0,
  NHQ: 1,
  SHQ: 2
});

export const FOOD_ESHOP_RETURN_BOX = Object.freeze({
  TRUE: 1,
  FALSE: 0
});

export const FOOD_ESHOP_GROUP_SALE = Object.freeze({
  TRUE: 1,
  FALSE: 0
});

export const FOOD_ESHOP_GROUP_SALE_COUNT = 6;

// AUCTION DASHBOARD
export const AUCTION_KEY_PREFIX = 'AUCTION_';

// ADVENT DASHBOARD
export const ADVENT_KEY_PREFIX = 'ADVENT_';

// STRATEGY
export const STRATEGY_DEFAULT_LANG = 'en';
export const STRATEGY_COMPETITION_ACTIVE = 1;
export const STRATEGY_KEY_PREFIX = 'STRATEGY_';

// BUILDING HHQ
export const BUILDING_HHQ_COORDINATES = Object.freeze({
  LAT: '50.2167893',
  LON: '15.8294072'
});
export const BUILDING_HHQ_ID = 'ChIJ2Z6AeRUrDEcRHOi1kESCNL8';

// Sustainability
export const SUSTAINABILITY_KEY_PREFIX = 'SUSTAINABILITY_';

// Onboarding
export const ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE = Object.freeze({
  medical: 'medical',
  criminal: 'criminal',
  education: 'education',
  declaration: 'declaration',
  certification: 'certification',
  pension: 'pension',
  children: 'children',
  study: 'study',
  foreigner: 'foreigner',
  workhistory: 'workhistory',
  disabled: 'disabled',
});
export const ONBOARDING_APPLICANT_DOC_CATEGORIES = [
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.medical,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.criminal,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.education,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.declaration,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.certification,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.pension,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.disabled,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.children,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.study,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.foreigner,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_TYPE.workhistory,
];
export const ONBOARDING_APPLICANT_DOC_CATEGORIES_REQUIRED = [
  ONBOARDING_APPLICANT_DOC_CATEGORIES[2]
];
export const ONBOARDING_APPLICANT_DOC_FILE_STATUSES = Object.freeze({
  uploaded: 'uploaded',
  approved: 'approved',
  rejected: 'rejected',
  icapError: 'icapError',
  icapOK: 'icapOK'
});
export const ONBOARDING_APPLICANT_FORM_SECTIONS = [
  'baseData',
  'contactData',
  'personalData',
  'primaryAddress',
  'healthAndChildren',
  'workAndTaxes',
  'deliveryAddress',
  'hoAddress',
  'summary',
];
export const ONBOARDING_APPLICANT_FORM_SECTIONS_DPP = [
  'baseData',
  'contactData',
  'personalData',
  'primaryAddress',
  'healthAndChildren',
  'workAndTaxes',
  'deliveryAddress',
  'summary'
];
export const ONBOARDING_DEFAULT_NATIONALITY = 'ČR';
export const ONBOARDING_APPLICANT_DOC_CATEGORIES_STATUSES = Object.freeze({
  pending: 'pending',
  uploaded: 'uploaded',
  approved: 'approved',
  unused: 'unused',
});
export const ONBOARDING_APPLICANT_STATUSES = Object.freeze({
  entered: 'entered',
  regRequested: 'regRequested',
  regApproved: 'regApproved',
  accActivated: 'accActivated',
  persdataFilled: 'persdataFilled',
  persdataApproved: 'persdataApproved',
  sentToSAP: 'sentToSAP',
  rejectedInSAP: 'rejectedInSAP',
  loadedInSAP: 'loadedInSAP',
  completed: 'completed',
  persdataRejected: 'persdataRejected'
});
export const ONBOARDING_APPLICANT_DOC_STATUSES = Object.freeze({
  pendingUpload: 'pendingUpload',
  pendingApproval: 'pendingApproval',
  someRejected: 'someRejected',
  approved: 'approved',
  sentToBank: 'sentToBank'
});
export const MAIN_APP_URL = 'https://zapka.csob.cz/';
export const ONBOARDING_UPLOAD_FILE_SIZE_LIMIT = 10;
export const ONBOARDING_INTERVAL_DURATIONS = [30, 60, 90];
