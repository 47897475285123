<script type="application/javascript">

//=== IMPORT ======================================//
//=================================================//

//=== UTIL
import client from '../utils/data-loader/index';
import logger from '../utils/logger';
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';

//=== EXPORT ======================================//
//=================================================//
export default {
  methods: {
    /**
     * @param disableDebounce boolean send stats immediately, do not use debounce
     * @param contentId       String  ID of article that was visited
     * @param moduleName      String  module name from statisticModules.js constants
     */
    sendStatistics(moduleName, contentId, disableDebounce) {
      let isOnline = (window.navigator && window.navigator.onLine) || !window.navigator;
      if (!moduleName || !isOnline) {
        return;
      }

      let statsSentTimestamp = this.$store.state.general.statistics.timestamps[moduleName];
      let debounceTimeout = 5 * 60 * 1000; // 5 minutes debounce
      let currentTimestamp = new Date().getTime();
      if ((statsSentTimestamp + debounceTimeout < currentTimestamp) || disableDebounce) {
        logger.info('Sending usage stats of ' + moduleName + '; current timestamp ' + currentTimestamp + '; ' +
        ' last send timestamp ' + statsSentTimestamp);
        client.updateModuleUsageStatistics(this.$store.state.user.publicKey, moduleName, contentId);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_STATISTICS_TIMESTAMP, {
          module: moduleName,
          timestamp: currentTimestamp
        })
      }
    }
  }
}
</script>
