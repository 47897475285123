<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->

<!-- TODO MBU: component rework under way CONCEPTS-1497 -->
<template>
    <div :id="id" class="vue-c-data-row" :class="classObject">
        <div v-if="captionHasContent" class="vue-b-data-row-caption">
            <label class="vue-caption">{{ caption }}</label>
        </div>

        <div class="vue-b-data-row-content">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1018DataRow',
    props: {
        caption: {
            default: null,
            type: String
        },
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                'vue-has-no-caption': !this.captionHasContent
            };
        },
        captionHasContent() {
            return this.caption !== null && this.caption !== '';
        }
    }
};
</script>
