import * as MUTATIONS_CONSTANTS from '../constants/mutations';

// ========================================================= //
// == MODULE FOR AUTHORIZED MODE ONLY                     == //
// ========================================================= //
const state = {
  user: {
    authorized: false,
    authorizedTimestamp: null,
    uuid: null,
    smsRequestTimestamp: null,
    smsRequestState: false,
    firstName: null,
    lastName: null
  },
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED](state, value) {
    state.user.authorized = value;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED_TIMESTAMP](state, timestamp) {
    state.user.authorizedTimestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_UUID](state, uuid) {
    state.user.uuid = uuid;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_SMS_TIMESTAMP](state, timestamp) {
    state.user.smsRequestTimestamp = timestamp;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_SMS_STATE](state, value) {
    state.user.smsRequestState = value;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_FIRST_NAME](state, firstName) {
    state.user.firstName = firstName;
  },

  [MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_LAST_NAME](state, lastName) {
    state.user.lastName = lastName;
  },
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
