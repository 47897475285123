import { strings } from './localization/strings';

let localization = {
    language: 'cz',
    locale: 'cs-CZ',
    strings: strings // TODO MBU: configurable loading of languages - merge with localization strings
};

export default localization;

export const setOptionsLocalization = optionsLocalization => {
    localization = optionsLocalization;
};
