<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-dropdown-button" :class="classObject">
        <frm1006-button
            ref="buttonDropdownToggle"
            :type="type"
            :importance="importance"
            :disabled="disabled"
            :active="expandedData"
            :title="captionComputed"
            class="vue-ci-button-dropdown-toggle"
            @buttonClickEvent="buttonDropdownToggleClick"
        >
            {{ captionComputed }}
        </frm1006-button>
        <gen1011-context-container
            ref="contextContainer"
            class="vue-ci-dropdown vue-is-menu"
            :class="contextContainerClassObject"
            :active.sync="expandedData"
            :whiteList="contextContainerWhiteListComputed"
            :preventLosingFocus="true"
            :positionCustom="contextContainerClickPosition"
            @removedEvent="contextContainerRemovedEvent"
        >
            <gen1011-context-container-menu
                :items="optionsComputed"
                :itemSelectedValue="optionPreselectedValue"
                :active="expandedData"
                @itemSelectEvent="contextContainerMenuItemSelect"
                @keyDown="contextContainerKeyDown"
                @menuMountedEvent="contextContainerMenuMounted"
            >
                <template v-if="$scopedSlots.item" v-slot:contextContainerMenu="props">
                    <slot name="item" :item="props.item" :index="props.index" />
                </template>
            </gen1011-context-container-menu>
        </gen1011-context-container>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1011ContextContainer from '../../gen/gen1011-context-container/gen1011-context-container';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== HELPERS
import Gen1011ContextContainerMenu from '../../helpers/gen1011-context-container/gen1011-context-container-menu';

//=== MIXINS
import Component from '../../mixins/component';
import Position from '../../mixins/position';
import Localization from '../../mixins/localization';

//=== MISC
import config from '../../../config';

//============ OPTIONS =================================//
//======================================================//
let options = {
    scrollDebounceTimeout: 150
};

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1033';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1033DropdownButton',
    components: {
        Gen1011ContextContainer,
        Gen1011ContextContainerMenu,
        Frm1006Button
    },
    mixins: [Component, Localization, Position],
    props: {
        options: {
            type: Array,
            required: true
        },
        optionPreselectedValue: String,
        type: {
            default: config.buttonTypes[0],
            type: String,
            validator: value => {
                return config.buttonTypes.includes(value);
            }
        },
        importance: {
            default: config.buttonImportances[0],
            type: String,
            validator: value => {
                return config.buttonImportances.includes(value);
            }
        },
        disabled: {
            default: false,
            type: Boolean
        },
        expanded: {
            default: false,
            type: Boolean
        },
        caption: String,
        //=== CONTEXT CONTAINER
        contextContainerCustomCss: String,
        contextContainerPositionDefault: {
            default: () => {
                return {
                    x: 'right',
                    y: 'bottom'
                };
            },
            type: Object
        },
        contextContainerWhiteList: {
            default: () => {
                return [];
            },
            type: Array
        },
        contextContainerClickPosition: {
          default: false,
          type: [Boolean, Object]
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            expandedData: this.expanded,
            contextContainerWhiteListInitial: [],
            contextContainerPosition: {
                x: this.contextContainerPositionDefault.x,
                y: this.contextContainerPositionDefault.y
            },
            menuMounted: false
        };
    },
    computed: {
        classObject() {
            return [
                'vue-has-dropdown-position-' + this.contextContainerPosition.x,
                'vue-has-dropdown-position-' + this.contextContainerPosition.y,
                {
                    [`vue-is-${this.type}`]: this.type,
                    [`vue-is-${this.importance}`]: this.importance,
                    'vue-is-disabled': this.disabled,
                    'vue-is-expanded': this.expandedData
                }
            ];
        },
        captionComputed() {
            if (!this.caption) {
                return this.i18n('buttonDropdown');
            }

            return this.caption;
        },
        optionsComputed() {
            // TODO MBU: do deep copy correctly - prepare methods in utilsGeneral
            let optionsStringified = JSON.stringify(this.options);
            let optionsComputed = JSON.parse(optionsStringified);

            for (let i = 0; i < optionsComputed.length; i++) {
                let option = optionsComputed[i];

                if (!option.mode) {
                    optionsComputed[i].mode = 'simple';
                }

                if (!option.action) {
                    optionsComputed[i].action = 'default';
                }

                if (option.component) {
                    optionsComputed[i].component = this.options[i].component;
                }
            }

            return optionsComputed;
        },
        //============ CONTEXT CONTAINER =======================//
        //======================================================//
        contextContainerClassObject() {
            return [this.contextContainerCustomCss, { 'vue-is-searchable': this.searchable }];
        },
        contextContainerWhiteListComputed() {
            let whiteList = [];

            // initial internal white list setting
            if (this.contextContainerWhiteListInitial.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteListInitial.length; i++) {
                    let element = this.contextContainerWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            // add passed items via whiteList prop
            if (this.contextContainerWhiteList.length > 0) {
                for (let i = 0; i < this.contextContainerWhiteList.length; i++) {
                    let element = this.contextContainerWhiteList[i];
                    whiteList.push(element);
                }
            }

            return whiteList;
        }
    },
    watch: {
        expanded(value) {
            this.expandedData = value;
        },
        expandedData(value) {
            this.$emit('update:expanded', value);
            this.$emit('contextContainerExpandedChangeEvent', value);

            if (!value) {
                this.menuMounted = false;
            }
        }
    },
    mounted() {
        this.setContextContainerWhiteListInitial();
    },
    methods: {
        //============ EVENTS ==================================//
        //======================================================//
        buttonDropdownToggleClick(event) {
            this.$emit('buttonDropdownToggleClickEvent', event);
            this.contextContainerToggle('buttonDropdownToggle');
        },
        contextContainerKeyDown(value, event) {
            if (event.key === 'Tab') {
                this.contextContainerClose('tab');
            }
        },
        //========= CONTEXT CONTAINER ================//
        //============================================//
        contextContainerToggle(trigger) {
            this.expandedData = !this.expandedData;
            this.$emit('contextContainerToggleEvent', trigger, this.expandedData);
        },
        contextContainerOpen(trigger) {
            this.expandedData = true;
            this.$emit('contextContainerOpenEvent', trigger);
        },
        contextContainerClose(trigger) {
            this.expandedData = false;
            this.$emit('contextContainerCloseEvent', trigger);
        },
        contextContainerRemovedEvent() {
            this.resetContextContainerPosition();
            this.removeViewportChangeListeners();
        },
        contextContainerMenuMounted() {
            // wait until contextContainer is really rendered
            this.updateContextContainerPosition();
            this.createViewportChangeListeners();
            // context container white list
            this.setContextContainerWhiteListInitial();
            this.menuMounted = true;
        },
        contextContainerMenuItemSelect(item, itemIndex) {
            this.$emit('contextContainerMenuItemSelectEvent', item, itemIndex);
            this.contextContainerClose('itemSelected');
        },
        setContextContainerWhiteListInitial() {
            this.contextContainerWhiteListInitial = [];

            if (this.$refs.buttonDropdownToggle.$el !== undefined) {
                let buttonDropdownToggleChildren = this.$refs.buttonDropdownToggle.$el.querySelectorAll('*');
                if (buttonDropdownToggleChildren) {
                    for (let child of buttonDropdownToggleChildren) {
                        this.contextContainerWhiteListInitial.push(child);
                    }
                }
            }
        },
        //============ POSITION ================================//
        //======================================================//
        updateContextContainerPosition() {
            // once the context container is closed and scroll is performed
            // contextContainer.$el was just empty comment and getPositionRelativeTo function failed
            // this is safeguard to prevent it
            if (this.$refs.contextContainer.$el.nodeName !== '#comment') {
                // set current position for calculation
                let positionData = this.getPositionRelativeTo(
                    this.$refs.contextContainer.$el,
                    this.contextContainerPosition
                );
                // update only when needed
                this.contextContainerPosition = positionData.position;
            }
        },
        resetContextContainerPosition() {
            this.contextContainerPosition.x = this.contextContainerPositionDefault.x;
            this.contextContainerPosition.y = this.contextContainerPositionDefault.y;
        },
        windowScrolled() {
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
            }
            this.scrollDebounce = setTimeout(() => {
                this.updateContextContainerPosition();
                this.scrollDebounce = null;
            }, options.scrollDebounceTimeout);
        },
        windowResized() {
            if (this.expandedData) {
                this.updateContextContainerPosition();
            }
        },
        createViewportChangeListeners() {
            window.addEventListener('scroll', this.windowScrolled);
            window.addEventListener('resize', this.windowResized);
        },
        removeViewportChangeListeners() {
            window.removeEventListener('scroll', this.windowScrolled);
            window.removeEventListener('scroll', this.windowResized);
            if (this.scrollDebounce) {
                clearTimeout(this.scrollDebounce);
                this.scrollDebounce = null;
            }
        }
    }
};
</script>
