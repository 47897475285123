import Gen1005TagContainer from './gen1005-tag-container';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1005TagContainer);
    }
};

use(Plugin);

export default Plugin;

export { Gen1005TagContainer };
