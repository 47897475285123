import Vue from 'vue';
import i18n from '../i18n';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import { configure } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { email, required, numeric, max} from 'vee-validate/dist/rules';
import cs from 'vee-validate/dist/locale/cs.json';
import utilsGeneral from '@/utils/utils-general';

localize('cs', cs);

extend('email', email);
extend('required', required);
extend('numeric', numeric);
extend('max', max);
extend('phone', {
  validate: (value) => value.match(/^\+420[0-9]{9}$/),
  message: i18n.t('onboarding.phoneValidationMessage')
});

extend('decimal', {
  validate: (value) => value.match(/^\d*\.?\d*$/),
  message: i18n.t('onboarding.decimalValidationMessage')
});

extend('identification', {
  validate: (value) => utilsGeneral.isValidPersonalNumber(value, 15),
  message: i18n.t('onboarding.identificationValidationMessage')
});

extend('date', {
  validate: (value) => value.match(/^([1-9]{1}|0[1-9]|1\d|2\d|3[01]).\s?([1-9]{1}|(0[0-9]|1[0-2])).\s?\d{4}$/),
  message: i18n.t('onboarding.identificationValidationMessage')
});

extend('account', {
  validate: (value) => value.match(/^([0-9]{2,6}-)?[0-9]{2,10}\/[0-9]{4}$/),
  message: i18n.t('onboarding.accountValidationMessage')
});

extend('streetNo', {
  validate: (value) => value.match(/^[\d/]{1,10}$/),
  message: i18n.t('onboarding.streetNoValidationMessage')
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})
