<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaptionCustom"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <gen1006-info-panel
        v-if="isDocumentationClosed"
        type="success"
      >
        <span v-html="$t('onboarding.documentationClosed')" />
      </gen1006-info-panel>
      <div
        class="vue-onboarding-section-intro"
        v-html="$t('onboarding.documentsDescription.' + documentCategory)"
      />
      <gen1006-info-panel
        class="vue-is-info-panel"
        type="neutral"
      >
        {{ $t('onboarding.fileUploadWarning') }}
      </gen1006-info-panel>
      <div
        v-for="(document, index) in documents"
        :key="index"
        class="vue-event-list-item"
      >
        <div
          class="vue-camera-canvas"
        >
          <div
            class="vue-document-file"
          >
            <a
              :href="getDocumentContentUrl(document)"
              target="_blank"
            >
              <span class="vue-document-file-name">
                {{ document.filename }}
              </span>
            </a>
            <frm1006-button
              v-if="!isDocumentationClosed"
              class="vue-button-remove"
              type="internal"
              :iconLeft="true"
              :captionHidden="true"
              @buttonClickEvent="removeDocument(document.id)"
            >
              {{ $t('faultReporting.removePhoto') }}
            </frm1006-button>
          </div>
          <div
            v-if="document.subcategory"
            class="vue-event-list-item-tag"
          >
            {{ $t('onboarding.subcategory.' + document.subcategory) }}
          </div>
        </div>
        <gen1006-info-panel
          v-if="document.note"
          type="error"
        >
          {{ document.note }}
        </gen1006-info-panel>
      </div>

      <div
        class="vue-onboarding-controls"
      >
        <div
          class="vue-c-photo-upload"
        >
          <prj1045-file-upload
            v-if="!isDocumentationClosed"
            :acceptFiles="acceptFiles"
            :sizeLimitMB="ONBOARDING_UPLOAD_FILE_SIZE_LIMIT"
            @uploadedFile="completeBeforeSubmit"
          />
        </div>
      </div>
    </template>

    <!--========== CONTENT FOOTER =========================-->
    <!--===================================================-->
    <template v-slot:footer>
      <frm1031-button-container v-if="!isDocumentationClosed">
        <frm1006-button
          type="primary"
          :disabled="documents.length === 0"
          :title="$i18n.t('onboarding.continue')"
          @buttonClickEvent="goToView('onboarding-documents')"
        >
          {{ $t('onboarding.continue') }}
        </frm1006-button>
        <frm1006-button
          v-if="!isDocumentRequired(documentCategory) && documents.length === 0"
          type="secondary"
          :title="$i18n.t('onboarding.haveNothingToUpload')"
          @buttonClickEvent="rejectDocumentUpload(documentCategory)"
        >
          {{ $t('onboarding.haveNothingToUpload') }}
        </frm1006-button>
      </frm1031-button-container>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1009-overlay
        :active.sync="isCertificateTypeVisible"
        @closeEvent="cancelUpload"
      >
        <p>{{ $t('onboarding.chooseCertificateType') }}</p>

        <!-- Certificate type -->
        <frm1028-advanced-combo
          v-model="certificateType"
          :options="comboOptions($t('onboarding.certificateType.options'))"
          :placeholder="$i18n.t('onboarding.certificateType.title')"
          :tooltip="false"
          :label="$i18n.t('onboarding.certificateType.title')"
        />

        <p>
          <span class="vue-document-file-name">{{ uploadedFileName }}</span>
        </p>
        <frm1006-button
          type="primary"
          :disabled="!certificateType"
          @buttonClickEvent="uploadCertificate"
        >
          {{ $t('onboarding.send') }}
        </frm1006-button>
      </gen1009-overlay>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import {
  ERROR_MODAL_TIMEOUT,
  ONBOARDING_APPLICANT_DOC_CATEGORIES_REQUIRED,
  ONBOARDING_APPLICANT_DOC_FILE_STATUSES,
  ONBOARDING_APPLICANT_DOC_STATUSES,
  ONBOARDING_UPLOAD_FILE_SIZE_LIMIT
} from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1045FileUpload from '@/components/prj1045-file-upload/prj1045-file-upload';
import i18n from '@/i18n';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import * as envConfig from 'env-config';

export default {
  name: 'OnboardingDocumentUpload',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1045FileUpload
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      documents: [],
      certificateType: null,
      isCertificateTypeVisible: false,
      uploadedFileObject: {},
      acceptFiles: [
        'application/pdf',
        'image/jpeg',
        'image/png',
      ],
      ONBOARDING_UPLOAD_FILE_SIZE_LIMIT
    };
  },

  computed: {
    navbarCaptionCustom() {
      return i18n.t('onboarding.documentsName')[this.$route.params.category]
    },

    documentCategory() {
      return this.$route.params.category;
    },

    docsStatus() {
      return this.$route.params.docsStatus;
    },

    isDocumentationClosed() {
      return this.docsStatus === ONBOARDING_APPLICANT_DOC_STATUSES.sentToBank;
    },

    uploadedFileName() {
      return this.uploadedFileObject?.fileBinary?.name;
    }
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.fetchOnboardingDocuments();
    this.initialized = true;
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    comboOptions(options) {
      if (!options) return [];
      return options.map(item => {
        const opt = item.split('-');
        return {
          value: opt[0],
          caption: opt[1]
        }
      })
    },

    completeBeforeSubmit(fileObject) {
      if (this.documentCategory === 'certification') {  // show overlay to fill certificate type
        this.uploadedFileObject = fileObject;
        this.isCertificateTypeVisible = true;
      } else {
        this.submitDocument(fileObject);
      }
    },

    uploadCertificate() {
      this.isCertificateTypeVisible = false;
      this.submitDocument(this.uploadedFileObject);
    },

    cancelUpload() {
      this.isCertificateTypeVisible = false;
      this.certificateType = null;
      this.uploadedFileObject = {};
      this.fetchOnboardingDocuments();
    },

    removeDocument(documentId) {
      this.setLoadingState(true);

      return dataLoader.deleteDocument(documentId)
        .then(() => {
          this.fetchOnboardingDocuments();
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

    submitDocument(fileObject) {
      if (fileObject) {
        return dataLoader.sendDocument(
          fileObject.fileBinary.name,
          fileObject.fileBinary.type,
          this.documentCategory,
          this.certificateType,
          fileObject.fileBinary
        )
          .then(() => {
            this.fetchOnboardingDocuments();
          })
          .catch(error => {
            logger.error(error);
            this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
            this.$nextTick(() => {
              this.hasError = true
            });
            if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
              setTimeout(() => {
                this.$router.push({name: 'login'});
              }, 5000);
            }
          })
          .finally(() => {
            this.certificateType = null;
            this.uploadedFileObject = {};
            this.setLoadingState(false);
          });
      }
    },

    getDocumentContentUrl(document) {
      return document.status !== ONBOARDING_APPLICANT_DOC_FILE_STATUSES.icapError ? envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/' + document.id + '/content' : null;
    },

    rejectDocumentUpload(documentId) {
      this.setLoadingState(true);

      return dataLoader.setDocumentCategoryStatusUnused(documentId)
        .then(() => {
          this.setLoadingState(false);
          this.goToView('onboarding-documents')
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true;
            this.setLoadingState(false);
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
        })
    },

    isDocumentRequired(documentCategory) {
      return ONBOARDING_APPLICANT_DOC_CATEGORIES_REQUIRED.includes(documentCategory)
    },

    fetchOnboardingDocuments() {
      this.setLoadingState(true);

      dataLoader.fetchApplicantDocuments()
        .then((data) => {
          this.documents = data.filter((document) => document.category === this.documentCategory);
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        })
    }
  },
}
</script>
