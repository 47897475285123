<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div
        :id="`${idComputed}-panel`"
        class="vue-b-tab-panel"
        :class="classObject"
        role="tabpanel"
        :aria-labelledby="`${idComputed}-control`"
        :aria-hidden="!active"
    >
        <slot></slot>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GENERIC COMPONENTS

//============ EXPORT ==================================//
//======================================================//

export default {
    name: 'Gen1008TabPanelItem',
    props: {
        activeTabId: {
            type: String,
            required: true
        },
        idPrefix: String,
        id: String
    },
    computed: {
        classObject() {
            return {
                'vue-is-active': this.active
            };
        },
        idComputed() {
            return this.idPrefix ? this.idPrefix + '-' + this.id : this.id;
        },
        active() {
            return this.id === this.activeTabId;
        }
    }
};
</script>
