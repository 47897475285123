import Gen1018DataRow from './gen1018-data-row';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1018DataRow);
    }
};

use(Plugin);

export default Plugin;

export { Gen1018DataRow };
