const checkLoggedState = () => {
  const localStorageStoreObject = JSON.parse(localStorage.getItem('nastup-localstorage'));

  /*
  if (process.env.VUE_APP_MODE === 'local') {
    return true;
  }
  */

  if (localStorage.getItem('nastup-localstorage') != null) {
    let logged = localStorageStoreObject.onboarding.user.authorized;
    let loggedTimeout = Math.round(new Date().getTime() / 1000) - Math.round(localStorageStoreObject.onboarding.user.authorizedTimestamp) <= 1800;

    return logged && loggedTimeout;
  } else {
    return false;
  }
}

export default {
  checkLoggedState
}
