import Gen1008TabPanel from './gen1008-tab-panel';
import Gen1008TabPanelItem from './gen1008-tab-panel-item';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1008TabPanel);
        registerComponent(Vue, Gen1008TabPanelItem);
    }
};

use(Plugin);

export default Plugin;

export { Gen1008TabPanel, Gen1008TabPanelItem };
