export const BASE_API_URL = 'https://onboardingcms.zapka.csob.cz/';
export const VERSION_CHECK = '/version.json';
export const AUTHORIZATION_CODE = BASE_API_URL + 'auth';
export const LOGOUT = BASE_API_URL + 'api/logout';
export const ONBOARDING_APPLICANT = BASE_API_URL + 'api/my/applicant';
export const ONBOARDING_DOCUMENTS = BASE_API_URL + 'api/my/documents';
export const ONBOARDING_CATEGORIES = BASE_API_URL + 'api/my/categories';

export const CMS_API_DATA_LIST = BASE_API_URL + 'api/cms/jp8-pwa-config/v1/data-lists/';
export const CMS_SUB_PAGES = BASE_API_URL + 'api/cms/jp8-ws-content-api/v1/pages/sub';
export const CMS_POST_DETAIL = BASE_API_URL + 'api/cms/post/detail';

// ============================================================== //
// ///////////////////// PUSH NOTIFICATIONS ///////////////////// //
// ============================================================== //
export const PUSH_SERVER_API_SUBSCRIPTION_CREATE = BASE_API_URL + 'api/my/push/subscription/create';
export const PUSH_SERVER_API_SUBSCRIPTION_DELETE = BASE_API_URL + 'api/my/push/subscription/delete';
export const PUSH_SERVER_API_READ = BASE_API_URL + 'api/my/push/read';
export const PUSH_SERVER_API_CLICKED = BASE_API_URL + 'api/my/push/click';

// ============================================================== //
// /////////////////// APP NOTIFICATIONS //////////////////////// //
// ============================================================== //
export const APP_NOTIFICATIONS = BASE_API_URL + 'api/my/notices';
export const APP_NOTIFICATIONS_READ_ALL = BASE_API_URL + 'api/my/notices/read';
export const APP_NOTIFICATIONS_READ = BASE_API_URL + 'api/my/notice/read';
