<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-currency-input" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <div class="vue-b-label">
                <label v-if="label" :id="idComputed + ID_EXTENSIONS.LABEL" class="vue-label">{{ label }}</label>
            </div>
            <div class="vue-b-amount">
                <frm1001-input-field
                    :id="amountIdComputed"
                    ref="amount"
                    v-model="valueComputed.amount"
                    :idPrefix="null"
                    :name="amountName"
                    :state="amountStateComputed"
                    :required="amountRequiredComputed"
                    :readonly="amountReadonlyComputed"
                    :disabled="amountDisabledComputed"
                    :label="amountLabel"
                    :labeledBy="amountLabeledByComputed"
                    :describedBy="amountDescribedByComputed"
                    :placeholder="amountPlaceholder"
                    :buttonClear="amountButtonClear"
                    :tooltip="false"
                    :liveUpdate="liveUpdate"
                    @inputClickEvent="amountClick"
                    @inputKeyDownEvent="amountKeyDown"
                    @inputKeyUpEvent="amountKeyUp"
                    @inputInputEvent="amountInput"
                    @inputChangeEvent="amountChange"
                    @inputFocusEvent="amountFocus"
                    @inputBlurEvent="amountBlur"
                    @componentIsActiveEvent="amountIsActiveSet"
                    @tooltipWhiteListInitial="amountTooltipWhiteListInitialSet"
                />
            </div>
            <div class="vue-b-currency">
                <!--=== INPUT (SINGLE CURRENCY OR READONLY) ===-->
                <frm1001-input-field
                    v-if="isSingleCurrency || currencyReadonlyComputed"
                    :id="currencyIdComputed"
                    ref="currencyInput"
                    :idPrefix="null"
                    :name="currencyName"
                    :state="currencyStateComputed"
                    :required="currencyRequiredComputed"
                    :readonly="currencyReadonlyComputed"
                    :disabled="currencyDisabledComputed"
                    :label="currencyLabel"
                    :labeledBy="currencyLabeledByComputed"
                    :describedBy="currencyDescribedByComputed"
                    :value="currencyInputValue"
                    :liveUpdate="liveUpdate"
                    :placeholder="currencyPlaceholder"
                    :tooltip="false"
                    @inputClickEvent="currencyClick"
                    @inputKeyDownEvent="currencyKeyDown"
                    @inputKeyUpEvent="currencyKeyUp"
                    @inputChangeEvent="currencyChange"
                    @inputFocusEvent="currencyFocus"
                    @inputBlurEvent="currencyBlur"
                    @componentIsActiveEvent="currencyIsActiveSet"
                    @tooltipWhiteListInitial="currencyTooltipWhiteListInitialSet"
                />
                <!--=== COMBO ===-->
                <frm1002-combo
                    v-else
                    :id="currencyIdComputed"
                    ref="currencyCombo"
                    v-model="valueComputed.currency"
                    :idPrefix="null"
                    :name="currencyName"
                    :state="currencyStateComputed"
                    :required="currencyRequiredComputed"
                    :readonly="currencyReadonlyComputed"
                    :disabled="currencyDisabledComputed"
                    :label="currencyLabel"
                    :labeledBy="currencyLabeledByComputed"
                    :describedBy="currencyDescribedByComputed"
                    :options="currencyOptions"
                    :placeholder="currencyPlaceholder"
                    :tooltip="false"
                    @comboClickEvent="currencyClick"
                    @comboKeyDownEvent="currencyKeyDown"
                    @comboKeyUpEvent="currencyKeyUp"
                    @comboChangeEvent="currencyChange"
                    @comboFocusEvent="currencyFocus"
                    @comboBlurEvent="currencyBlur"
                    @componentIsActiveEvent="currencyIsActiveSet"
                    @tooltipWhiteListInitial="currencyTooltipWhiteListInitialSet"
                />
            </div>
        </div>

        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1001InputField from '../frm1001-input-field/frm1001-input-field';
import Frm1002Combo from '../frm1002-combo/frm1002-combo';

//=== MIXINS
import Component from '../../mixins/component';
import Tooltip from '../../mixins/tooltip';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1012';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1012CurrencyInput',
    components: {
        Gen1010InformationTooltip,
        Frm1001InputField,
        Frm1002Combo
    },
    mixins: [Component, Tooltip],
    model: {
        prop: 'value',
        event: 'currencyInputUpdateEvent'
    },
    props: {
        name: {
            type: String
        },
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: {
            default: false,
            type: Boolean
        },
        readonly: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        },
        label: String,
        value: {
            default: () => ({ amount: '', currency: '' }),
            type: Object
        },
        liveUpdate: {
            default: () => {
                return config.inputLiveUpdate;
            },
            type: Boolean
        },
        //=== AMOUNT
        amountState: {
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        amountRequired: {
            default: false,
            type: Boolean
        },
        amountReadonly: {
            default: false,
            type: Boolean
        },
        amountDisabled: {
            default: false,
            type: Boolean
        },
        amountLabel: String,
        amountLabeledBy: String,
        amountDescribedBy: String,
        amountPlaceholder: {
            default: null,
            type: String
        },
        amountButtonClear: {
            default: () => {
                return config.globalInputButtonClear;
            },
            type: Boolean
        },
        amountId: String,
        //=== CURRENCY
        currencyState: {
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        currencyRequired: {
            default: false,
            type: Boolean
        },
        currencyReadonly: {
            default: false,
            type: Boolean
        },
        currencyDisabled: {
            default: false,
            type: Boolean
        },
        currencyLabel: String,
        currencyLabeledBy: String,
        currencyDescribedBy: String,
        currencyOptions: {
            default: null,
            type: Array,
            required: true
        },
        currencyPlaceholder: {
            default: null,
            type: String
        },
        currencyId: String,
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data: function() {
        return {
            valueData: this.value,
            amountIsActive: false,
            currencyIsActive: false,
            amountTooltipWhiteListInitial: [],
            currencyTooltipWhiteListInitial: []
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-has-single-currency': this.isSingleCurrency,
                    'vue-has-amount-readonly': this.amountReadonlyComputed,
                    'vue-has-amount-disabled': this.amountDisabledComputed,
                    'vue-has-currency-readonly': this.currencyReadonlyComputed,
                    'vue-has-currency-disabled': this.currencyDisabledComputed,
                    'vue-is-component-active': this.componentIsActive
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip];
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                if (this.liveUpdate) {
                    this.$emit('currencyInputUpdateEvent', value);
                }
            }
        },
        isSingleCurrency() {
            return this.currencyOptions.length === 1;
        },
        componentIsActive() {
            // FIXME MBU: between focus / blur cycle between amount / currency is componentIsActive set to true > false > true, use timeOut?
            return this.amountIsActive || this.currencyIsActive;
        },
        //=== AMOUNT
        amountName() {
            return this.name ? this.name + 'amount' : null;
        },
        amountStateComputed() {
            return this.amountState ? this.amountState : this.state;
        },
        amountRequiredComputed() {
            return this.amountRequired ? this.amountRequired : this.required;
        },
        amountReadonlyComputed() {
            return this.amountReadonly && !this.readonly ? this.amountReadonly : this.readonly;
        },
        amountDisabledComputed() {
            return this.amountDisabled && !this.disabled ? this.amountDisabled : this.disabled;
        },
        amountIdComputed() {
            return this.idComputed ? this.idComputed + this.ID_EXTENSIONS.AMOUNT : null;
        },
        amountLabeledByComputed() {
            if (this.label && this.amountLabel && !this.amountLabeledBy) {
                return (
                    this.idComputed + this.ID_EXTENSIONS.LABEL + ' ' + this.amountIdComputed + this.ID_EXTENSIONS.LABEL
                );
            }

            return this.amountLabeledBy;
        },
        amountDescribedByComputed() {
            if (!this.amountDescribedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.amountDescribedBy;
        },
        //=== CURRENCY
        currencyName() {
            return this.name ? this.name + 'amount' : null;
        },
        currencyStateComputed() {
            return this.currencyState ? this.amountState : this.state;
        },
        currencyRequiredComputed() {
            return this.currencyRequired ? this.currencyRequired : this.required;
        },
        currencyReadonlyComputed() {
            if (this.isSingleCurrency && !this.disabled) {
                return true;
            } else {
                return this.currencyReadonly && !this.readonly ? this.currencyReadonly : this.readonly;
            }
        },
        currencyDisabledComputed() {
            return this.currencyDisabled && !this.disabled ? this.currencyDisabled : this.disabled;
        },
        currencyInputValue() {
            // match value to label
            if (this.valueComputed.currency) {
                let item = this.currencyOptions.filter(item => item.value === this.valueComputed.currency);
                return item[0].caption;
            }

            return '';
        },
        currencyIdComputed() {
            return this.idComputed ? this.idComputed + this.ID_EXTENSIONS.CURRENCY : null;
        },
        currencyLabeledByComputed() {
            if (this.label && this.currencyLabel && !this.currencyLabeledBy) {
                return (
                    this.idComputed +
                    this.ID_EXTENSIONS.LABEL +
                    ' ' +
                    this.currencyIdComputed +
                    this.ID_EXTENSIONS.LABEL
                );
            }

            return this.currencyLabeledBy;
        },
        currencyDescribedByComputed() {
            if (!this.currencyDescribedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.currencyDescribedBy;
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        currencyInputValue() {
            // if there is single currency, enforce it's value to v-model so it doesn't have to be explicitly defined in view
            if (this.isSingleCurrency) {
                this.valueData.currency = this.currencyOptions[0].value;
            }
        },
        tooltipExpandedData() {
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.amount.$refs.input.focus();
            }
        },
        amountTooltipWhiteListInitial() {
            this.setTooltipWhiteListInitial();
        },
        currencyTooltipWhiteListInitial() {
            this.setTooltipWhiteListInitial();
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        if (this.isSingleCurrency) {
            // FIXME REVIEW: fix this, do not modify other data inside computed properties!
            this.valueData['currency'] = this.currencyOptions[0].value;
        }
    },
    methods: {
        //=== GENERAL
        amountIsActiveSet(value) {
            this.amountIsActive = value;
        },
        currencyIsActiveSet(value) {
            this.currencyIsActive = value;
        },
        amountTooltipWhiteListInitialSet(value) {
            this.amountTooltipWhiteListInitial = value;
        },
        currencyTooltipWhiteListInitialSet(value) {
            this.currencyTooltipWhiteListInitial = value;
        },
        //=== AMOUNT
        amountClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountClickEvent', value, event);
        },
        amountKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountKeyDownEvent', value, event);
        },
        amountKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountKeyUpEvent', value, event);
        },
        amountChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountChangeEvent', value, event);

            let currencyInputValue = {
                amount: value,
                currency: this.valueData.currency
            };

            if (!this.liveUpdate) {
                this.$emit('currencyInputUpdateEvent', currencyInputValue);
            }
        },
        amountInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountInputEvent', value, event);
        },
        amountFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountFocusEvent', value, event);
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        amountBlur(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('amountBlurEvent', value, event);
        },
        //=== CURRENCY
        currencyClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyClickEvent', value, event);
        },
        currencyKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyKeyDownEvent', value, event);
        },
        currencyKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyKeyUpEvent', value, event);
        },
        currencyChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyChangeEvent', value, event);

            if (!this.liveUpdate) {
                this.$emit('currencyInputUpdateEvent', value);
            }
        },
        currencyFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyFocusEvent', value, event);
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        currencyBlur(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('currencyBlurEvent', value, event);
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            // always reset it
            this.tooltipWhiteListInitial = [];

            let whiteList = [];

            //=== AMOUNT
            if (this.amountTooltipWhiteListInitial.length > 0) {
                for (let i = 0; i < this.amountTooltipWhiteListInitial.length; i++) {
                    let element = this.amountTooltipWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            //=== CURRENCY
            if (this.currencyTooltipWhiteListInitial.length > 0) {
                for (let i = 0; i < this.currencyTooltipWhiteListInitial.length; i++) {
                    let element = this.currencyTooltipWhiteListInitial[i];
                    whiteList.push(element);
                }
            }

            this.tooltipWhiteListInitial = whiteList;
        }
    }
};
</script>
