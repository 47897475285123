<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-input-number" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.INPUT"
                class="vue-label"
                >{{ label }}</label
            >
            <input
                :id="inputId"
                ref="input"
                v-model="valueComputed"
                type="tel"
                class="vue-input"
                :name="name"
                :readonly="readonlyComputed"
                :disabled="disabledComputed"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="inputClick($event.target.value, $event)"
                @keydown="inputKeyDown($event.target.value, $event)"
                @keyup="inputKeyUp($event.target.value, $event)"
                @input="inputInput($event.target.value, $event)"
                @change="inputChange($event.target.value, $event)"
                @focus="inputFocus($event.target.value, $event)"
                @blur="inputBlur($event.target.value, $event)"
            />
            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="buttonsAdjustment"
                ref="buttonIncrement"
                type="internal"
                class="vue-ci-button-increment"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                :disabled="noRange || disabled || readonly"
                @buttonClickEvent="buttonIncrementClick"
                >{{ i18n('inputNumberIncrement') }}
            </frm1006-button>
            <frm1006-button
                v-if="buttonsAdjustment"
                ref="buttonDecrement"
                type="internal"
                class="vue-ci-button-decrement"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                :disabled="noRange || disabled || readonly"
                @buttonClickEvent="buttonDecrementClick"
                >{{ i18n('inputNumberDecrement') }}
            </frm1006-button>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>
        </div>

        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :idPrefix="null"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClearNumber from '../../mixins/buttonClearNumber';
import Tooltip from '../../mixins/tooltip';
import DigitalTracking from '../../mixins/digital-tracking';
import Localization from '../../mixins/localization';

//=== MISC
import config from '../../../config';
import localization from '../../../localization';
import { getLocalizedNumberSeparators, getUnit, round } from '../../../utils/utils-general';

//============ CONSTANTS ===============================//
//======================================================//

//========= GENERAL ==========================//
let COMPONENT_ID = 'frm1034';

//========= CSS SELECTORS ====================//
const ADJUSTMENT_MEDIUM_EXTRA_KEY = 'shiftKey';
const ADJUSTMENT_LARGE_EXTRA_KEY = 'ctrlKey';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1034InputNumber',
    components: {
        Gen1010InformationTooltip,
        Frm1006Button
    },
    mixins: [Component, ButtonClearNumber, Tooltip, DigitalTracking, Localization],
    model: {
        prop: 'value',
        // TODO MBU: rename to inputUpdateEvent
        event: 'update'
    },
    props: {
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        autocomplete: String,
        placeholder: {
            default: null,
            type: String
        },
        value: {
            default: null,
            type: Number
        },
        selectValueOnFocus: Boolean,
        //=== NUMBER
        min: Number,
        max: Number,
        localized: Boolean,
        locale: {
            default: () => {
                return localization.locale;
            },
            type: String
        },
        toLocaleStringOptions: {
            default() {
                return {
                    maximumFractionDigits: 2
                };
            },
            type: Object
        },
        rounding: Number,
        unit: null,
        unitSeparator: {
            default: ' ',
            type: String
        },
        buttonsAdjustment: Boolean,
        adjustmentUnits: {
            default: 'absolute',
            type: String,
            validator: value => {
                return ['absolute', 'relative'].includes(value);
            }
        },
        adjustmentSmall: {
            default: 1,
            type: Number
        },
        adjustmentMedium: {
            default: 10,
            type: Number
        },
        adjustmentLarge: {
            default: 100,
            type: Number
        },
        liveUpdate: {
            default: () => {
                return config.inputLiveUpdate;
            },
            type: Boolean
        },
        noRangeMode: {
            default: 'readonly',
            type: String,
            validator: value => {
                return ['readonly', 'disabled'].includes(value);
            }
        },
        keyboardControl: {
            type: Boolean,
            default: true
        },
        setValueOnEmptyString: {
            default: null,
            type: [Object, Number]
        },
        //=== ADDITIONAL ELEMENTS
        decorator: {
            default: false,
            type: Boolean
        },
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            valueData: this.value,
            // valueDataTemporary variable holds internal value of number input - it is not synchronized with v-model
            // it is shown in valueComputed getter in some cases
            valueDataTemporary: null,
            // valueDataStringTemporary is different value than the actual state of v-model (it is not automatically emitted on change)
            // - it is set based on valueDataTemporary on its change
            // - it is set by user input (user typing into input)
            // this string is used to show this value in input field in valueComputed in certain cases
            valueDataStringTemporary: '',
            // live evaluation of user input (user typing into input)
            valueDataLiveEvaluation: '',
            // when user is typing into the field - set to false on enter, tab or value adjustment (buttons / shortcuts) etc.
            userInputInProgress: false, // set to true when typing
            adjustmentMediumExtraKeyPressed: false,
            adjustmentLargeExtraKeyPressed: false,
            // holds info if the adjustment button was clicked when input field is not focused
            // the adjustment amount is evaluated later (once input field is programmatically focused),
            // based on whether some extra key was pressed when click occurred (shift / ctrl)
            adjustmentInQueue: null,
            componentIsActive: false,
            focused: false
        };
    },
    computed: {
        //=== GENERAL
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-has-decorator': this.decorator,
                    'vue-has-buttons-adjustment': this.buttonsAdjustment,
                    'vue-has-no-range': this.noRange,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        valueDataTemporaryIsSet() {
            return this.valueDataTemporary !== null || this.valueDataTemporary === 0;
        },
        valueComputed: {
            get() {
                // if liveUpdate && userInputInProgress is true (user is typing into input field), then show
                // what the user is typing in the field
                if (this.liveUpdate && this.userInputInProgress) {
                    return this.valueDataStringTemporary;
                }
                // if liveUpdate is turned off, show computed values in input (regular or temporary)
                else {
                    // if temporary values are not set, show regular values (based on v-model)
                    if (!this.valueDataTemporaryIsSet) {
                        if (this.valueData === null) {
                            return '';
                        }
                        if (!this.unitComputed) {
                            return this.valueComputedString;
                        } else {
                            return this.valueComputedStringWithUnit;
                        }
                    }
                    // if temporary values are set, show them instead
                    else {
                        if (!this.unitComputedTemporary) {
                            return this.valueComputedTemporaryString;
                        } else {
                            return this.valueComputedTemporaryStringWithUnit;
                        }
                    }
                }
            },
            set(value) {
                this.userInputInProgress = true;
                this.valueDataStringTemporary = value;
                this.valueDataLiveEvaluation = this.valueValidator(value);

                // if live update is turned on, emit the updated value
                if (this.liveUpdate && this.valueDataLiveEvaluation !== this.valueData) {
                    this.$emit('update', this.valueDataLiveEvaluation);
                }
            }
        },
        readonlyComputed() {
            return this.readonly || (this.noRange && this.noRangeMode === 'readonly');
        },
        disabledComputed() {
            return this.disabled || (this.noRange && this.noRangeMode === 'disabled');
        },
        minOrMaxLimitSet() {
            return this.max || this.max === 0 || this.min || this.min === 0;
        },
        //=== NUMBER
        localizedNumberSeparators() {
            if (this.localeComputed) {
                return getLocalizedNumberSeparators(this.localeComputed);
            }

            return null;
        },
        localeComputed() {
            if (this.locale && this.locale !== '') {
                return this.locale;
            }

            return navigator.language;
        },
        unitComputed() {
            return getUnit(this.valueData, this.unit);
        },
        unitComputedTemporary() {
            return getUnit(this.valueDataTemporary, this.unit);
        },
        valueComputedString() {
            if (this.valueData || this.valueData === 0) {
                if (this.localized) {
                    return this.valueData.toLocaleString(this.localeComputed, this.toLocaleStringOptions);
                } else {
                    return this.valueData.toString();
                }
            }

            return null;
        },
        valueComputedStringWithUnit() {
            if ((this.valueComputedString || this.valueComputedString === 0) && this.unitComputed) {
                return this.valueComputedString + this.unitSeparator + this.unitComputed;
            }

            return null;
        },
        valueComputedTemporaryString() {
            if (this.valueDataTemporary || this.valueDataTemporary === 0) {
                if (this.localized) {
                    return this.valueDataTemporary.toLocaleString(this.localeComputed, this.toLocaleStringOptions);
                } else {
                    return this.valueDataTemporary.toString();
                }
            }

            return null;
        },
        valueComputedTemporaryStringWithUnit() {
            if (this.valueComputedTemporaryString && this.unit) {
                return this.valueComputedTemporaryString + this.unitSeparator + this.unitComputedTemporary;
            }

            return null;
        },
        // bugfix for Vue (watch object) - watcher on prop, which is object triggers watch on v-model change on all instances
        // of component, that has the prop manually set, solution is to watch this stringified computed value
        // if there is need to work with this value, it can be unstringified
        toLocaleStringOptionsComputed() {
            return JSON.stringify(this.toLocaleStringOptions);
        },
        range() {
            if (this.max && this.min) {
                return Math.abs(this.max) + Math.abs(this.min);
            }

            return null;
        },
        noRange() {
            if ((this.min || this.min === 0) && (this.max || this.max === 0)) {
                return this.min === this.max;
            }

            return false;
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        inputId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.INPUT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === null;
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        valueDataTemporary() {
            this.setValueDataStringTemporary();
        },
        rounding() {
            this.updateData();
        },
        locale() {
            this.updateData();
        },
        toLocaleStringOptionsComputed() {
            this.updateData();
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.input.focus();
            }
        },
        componentIsActive(value) {
            if (!this.readonly) {
                this.$emit('componentIsActiveEvent', value);
            }
        },
        focused(value) {
            this.$emit('inputFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            this.$emit('tooltipWhiteListInitial', value);
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        this.updateData();
    },
    methods: {
        //=== GENERAL
        inputSetFocus() {
            this.$refs.input.focus();
        },
        inputSetBlur() {
            this.$refs.input.blur();
        },
        setComponentActiveState() {
            if (!this.readonly) {
                this.componentIsActive = document.activeElement === this.$refs.input;
            }
        },
        setInputFocusState() {
            this.focused = document.activeElement === this.$refs.input;
        },
        //=== EVENTS
        inputClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputClickEvent', value, event);
        },
        inputKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputKeyDownEvent', value, event);

            this.adjustmentMediumExtraKeyPressed = event[ADJUSTMENT_MEDIUM_EXTRA_KEY];
            this.adjustmentLargeExtraKeyPressed = event[ADJUSTMENT_LARGE_EXTRA_KEY];

            // process queued click to find out, if some extra key was pressed
            this.buttonsAdjustmentProcessQueue();
            if (this.keyboardControl && !this.readonly) {
                // INCREMENT
                if (
                    event.key === 'ArrowUp' &&
                    !(this.adjustmentMediumExtraKeyPressed || this.adjustmentLargeExtraKeyPressed)
                ) {
                    // prevent jumping to beginning of the text on arrow up
                    // no need to prevent it on arrow down, because the cursor jumps to end on redraw anyway
                    // NTH MBU: keep cursor position and return cursor there on redraw
                    event.preventDefault();
                    this.adjustValue(this.adjustmentSmall);
                }

                if (event.key === 'ArrowUp' && this.adjustmentMediumExtraKeyPressed) {
                    // prevent selecting all text on shift up
                    if (ADJUSTMENT_MEDIUM_EXTRA_KEY === 'shiftKey') {
                        event.preventDefault();
                    }
                    this.adjustValue(this.adjustmentMedium);
                }

                if (event.key === 'ArrowUp' && this.adjustmentLargeExtraKeyPressed) {
                    // prevent selecting all text on shift up
                    if (ADJUSTMENT_LARGE_EXTRA_KEY === 'shiftKey') {
                        event.preventDefault();
                    }
                    this.adjustValue(this.adjustmentLarge);
                }
                //=== DECREMENT
                if (
                    event.key === 'ArrowDown' &&
                    !(this.adjustmentMediumExtraKeyPressed || this.adjustmentLargeExtraKeyPressed)
                ) {
                    this.adjustValue(-this.adjustmentSmall);
                }

                if (event.key === 'ArrowDown' && this.adjustmentMediumExtraKeyPressed) {
                    this.adjustValue(-this.adjustmentMedium);
                }

                if (event.key === 'ArrowDown' && this.adjustmentLargeExtraKeyPressed) {
                    this.adjustValue(-this.adjustmentLarge);
                }
            }

            if (event.key === 'Enter') {
                let inputValue = this.$refs.input.value;

                if (inputValue === '') {
                    let value = this.setValueOnEmptyString;

                    if (this.max || this.min) {
                        if (value > this.max) {
                            value = this.max;
                        } else if (value < this.min) {
                            value = this.min;
                        }
                    }

                    this.triggerChange(value);
                }

                this.updateDataFull();
            }

            if (event.key === 'Tab') {
                let inputValue = this.$refs.input.value;

                if (inputValue === '') {
                    let value = this.setValueOnEmptyString;

                    if (this.max || this.min) {
                        if (value > this.max) {
                            value = this.max;
                        } else if (value < this.min) {
                            value = this.min;
                        }
                    }

                    this.triggerChange(value);
                }

                this.updateDataFull();
            }
        },
        inputKeyUp(value, event) {
            this.adjustmentMediumExtraKeyPressed = event[ADJUSTMENT_MEDIUM_EXTRA_KEY];
            this.adjustmentLargeExtraKeyPressed = event[ADJUSTMENT_LARGE_EXTRA_KEY];

            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputKeyUpEvent', value, event);
        },
        inputChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputChangeEvent', value, event);
            this.track(this.trackingId, 'change', this.name, this.value, this.trackEvents);

            this.updateDataFull();
        },
        inputInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputInputEvent', value, event);
        },
        inputFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setInputFocusState();
            // tootip
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
            // select value on focus
            if (this.selectValueOnFocus) {
                this.$refs.input.select();
            }
            this.track(this.trackingId, 'focus', this.name, this.value, this.trackEvents);
        },
        // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
        inputBlur(value, event) {
            this.$emit('inputBlurEvent', value, event);
            this.setComponentActiveState();
            this.setInputFocusState();

            this.updateDataFull();
            this.track(this.trackingId, 'blur', this.name, this.value, this.trackEvents);
        },
        //=== NUMBER
        valueValidator(value) {
            let valueCleaned;
            let valueExtracted;
            let valueValidated;
            let sign = '';

            // if value is empty string, set value to null
            if (value === '') {
                return null;
            }

            valueCleaned = this.cleanNumberStringLocalization(value);
            valueExtracted = this.extractNumberFromString(valueCleaned);

            // if there is no recognized number in value, return previous valid value
            if (valueExtracted === null) {
                return this.valueData;
            }

            // regex match sign before number for + / - negotiation
            // match all before sequence /.+?(?=abc)/
            // match character before sequence /.?(?=abc)/
            // https://stackoverflow.com/questions/7124778/how-to-match-anything-up-until-this-sequence-of-characters-in-a-regular-expres
            let regExSignQuery = '.?(?=' + valueExtracted + ')';
            let regExSign = new RegExp(regExSignQuery, 'g');
            sign = regExSign.exec(valueCleaned)[0];

            //=== rounding manual
            if (!this.rounding || this.rounding === 0) {
                valueValidated = parseFloat(valueExtracted);
            } else {
                let valueExtractedNumber = parseFloat(valueExtracted);
                valueValidated = round(valueExtractedNumber, this.rounding);
            }

            //=== rounding (additional formatting) via toLocaleString
            if (this.localized) {
                let valueLocalized = valueValidated.toLocaleString(this.localeComputed, this.toLocaleStringOptions);

                let valueLocalizedCleaned = this.cleanNumberStringLocalization(valueLocalized);
                let valueLocalizedExtracted = this.extractNumberFromString(valueLocalizedCleaned);

                valueValidated = parseFloat(valueLocalizedExtracted);
            }

            //=== min / max
            if (this.minOrMaxLimitSet) {
                if (valueValidated > this.max) {
                    valueValidated = this.max;
                } else if (valueValidated < this.min) {
                    valueValidated = this.min;
                }
            }

            //=== sign
            if (sign === '-') {
                return -valueValidated;
            } else {
                return valueValidated;
            }
        },
        cleanNumberStringLocalization(numberString) {
            // handle thousandsSeparator removal vie regex
            let thousandSeparatorRegExCharacter = this.localizedNumberSeparators.thousandsSeparator;

            // fromCharCode(160) equals to &nbsp;, simple ' ' didn't work in condition below
            // this is special condition for whitespace, in regex, it has special character - escaped \\s
            if (this.localizedNumberSeparators.thousandsSeparator === (String.fromCharCode(160) || ' ')) {
                thousandSeparatorRegExCharacter = '\\s';
            }

            // construct regex query
            let regExThousandsSeparatorQuery = '[' + thousandSeparatorRegExCharacter + ']';

            // construct regex
            let regExThousandsSeparator = new RegExp(regExThousandsSeparatorQuery, 'g');

            return numberString
                .replace(regExThousandsSeparator, '')
                .replace(this.localizedNumberSeparators.decimalSeparator, '.');
        },
        extractNumberFromString(numberString) {
            let valueExtractedRegEx;
            // regex - find first number with decimals in string
            // https://stackoverflow.com/questions/25528095/regex-to-extract-first-number-with-decimals-in-string
            let regExExtract = /^\D*(\d+(?:\.\d+)?)/g;

            let valueCleaned = this.cleanNumberStringLocalization(numberString);

            valueExtractedRegEx = regExExtract.exec(valueCleaned);

            // if there is no recognized number in value, return previous valid value
            if (valueExtractedRegEx === null) {
                return null;
            }

            return valueExtractedRegEx[1].toString();
        },
        triggerChange(valueValidated) {
            if (valueValidated === this.valueData) {
                // force redraw if result is the same to update the value
                // for example 1000 CZK string is changed to XXX1000YYY string
                // the value is the same, so without force update it wouldn't redraw
                // TODO MBU: consider just resetting temporaries
                this.$forceUpdate();
            }

            this.valueDataTemporary = null;
            this.valueDataStringTemporary = '';
            this.$emit('update', valueValidated); // event for v-model
        },
        // updateData, for example change when component is initialized, rounding or locale options are changed etc.
        // this change is computed just from valueData
        // use updateDataFull for user input change / adjustment based on valueDataStringTemporary where user input is kept
        updateData() {
            let valueToValidate;

            if (this.valueData !== null) {
                if (this.localized) {
                    valueToValidate = this.valueData.toLocaleString(this.localeComputed, this.toLocaleStringOptions);
                } else {
                    valueToValidate = this.valueData.toString();
                }

                let valueValidated = this.valueValidator(valueToValidate);
                this.triggerChange(valueValidated);
            }
        },
        updateDataFull() {
            this.userInputInProgress = false;

            if (this.valueDataStringTemporary) {
                let valueValidated = this.valueValidator(this.valueDataStringTemporary);
                this.triggerChange(valueValidated);
            } else {
                // if there is no valueDataStringTemporary set, there was no change, so we want to display original data
                // this is done via resetting valueDataTemporary, no need to emit anything
                this.valueDataTemporary = null;
                this.valueDataStringTemporary = '';
            }
        },
        setValueDataStringTemporary() {
            if (!this.valueDataTemporaryIsSet) {
                if (this.valueComputedStringWithUnit) {
                    this.valueDataStringTemporary = this.valueComputedStringWithUnit;
                } else if (this.valueComputedString) {
                    this.valueDataStringTemporary = this.valueComputedString;
                } else {
                    this.valueDataStringTemporary = '';
                }
            } else {
                if (this.valueComputedTemporaryStringWithUnit) {
                    this.valueDataStringTemporary = this.valueComputedTemporaryStringWithUnit;
                } else if (this.valueComputedTemporaryString) {
                    this.valueDataStringTemporary = this.valueComputedTemporaryString;
                } else {
                    this.valueDataStringTemporary = '';
                }
            }
        },
        adjustValue(adjustment) {
            let adjustmentAmount = this.getAdjustmentAmount(adjustment);

            this.userInputInProgress = false;

            if (this.valueDataLiveEvaluation) {
                this.valueDataTemporary = this.valueDataLiveEvaluation;
            } else if (this.valueDataTemporary === null) {
                this.valueDataTemporary = this.valueData;
            }

            this.valueDataLiveEvaluation = null;

            let valueAdjusted = this.valueDataTemporary + adjustmentAmount;

            if (this.minOrMaxLimitSet) {
                if (valueAdjusted > this.max) {
                    this.valueDataTemporary = this.max;
                } else if (valueAdjusted < this.min) {
                    this.valueDataTemporary = this.min;
                } else {
                    this.valueDataTemporary = valueAdjusted;
                }
            } else {
                this.valueDataTemporary = valueAdjusted;
            }

            let valueValidated = this.valueValidator(this.valueComputedTemporaryString);

            if (valueValidated !== this.valueData) {
                this.triggerChange(valueValidated);
            } else {
                // solution for adjustmentStep being smaller than rounding option - value data temporary
                this.valueDataTemporary = this.valueData;
                // NTH MBU: adjust the steps programatically, so the step is made to next rounding if its setting is too low
                if (this.valueData !== this.min && this.valueData !== this.max) {
                    console.warn(
                        'FRM1034: Number input - adjusted value (' +
                            valueAdjusted +
                            ') after rounding results is the same as currently set value (' +
                            this.valueData +
                            '), number input value not set! Please set adjustmentUnit to match the current rounding options (rounding = ' +
                            this.rounding +
                            ')'
                    );
                }
            }
        },
        getAdjustmentAmount(value) {
            if (this.adjustmentUnits === 'absolute') {
                return value;
            } else if (this.adjustmentUnits === 'relative') {
                if (this.range) {
                    return (this.range / 100) * value;
                } else {
                    console.warn(
                        "FRM1034: Number input - when adjustmentUnits are set to relative, the min / max must be set. Adjustment couldn't be calculated."
                    );
                    return 0;
                }
            }
        },
        buttonIncrementClick() {
            this.buttonsAdjustmentClick('increment');
        },
        buttonDecrementClick() {
            this.buttonsAdjustmentClick('decrement');
        },
        buttonsAdjustmentClick(buttonType) {
            if (!this.componentIsActive) {
                // if the component is not focused, add the click to adjustment queue
                // then the input is focused - now the event listener on keyup / keydown is active
                // the queued adjustment is then solved later in buttonsAdjustmentProcessQueue function
                // in that listener
                // TODO MBU: minor - could be possibly rewritten to use valueDataTemporary in order not to trigger
                // 2 changes, sometimes the small adjustment can be seen momentarily before extra key
                // in buttonsAdjustmentProcessQueue is processed
                // could be done so, that valueDataTemporary won't be shown in getter if adjustmentInQueue is true
                this.adjustmentInQueue = buttonType;

                // pressed keys cannot be detected yet, add adjustmentSmall - it will be subtracted later,
                // if we find out, some of the extra keys were pressed buttonsAdjustmentProcessQueue function
                if (buttonType === 'increment') {
                    this.adjustValue(this.adjustmentSmall);
                } else if (buttonType === 'decrement') {
                    this.adjustValue(-this.adjustmentSmall);
                }

                this.inputSetFocus();
            } else {
                if (!(this.adjustmentMediumExtraKeyPressed || this.adjustmentLargeExtraKeyPressed)) {
                    if (buttonType === 'increment') {
                        this.adjustValue(this.adjustmentSmall);
                    } else if (buttonType === 'decrement') {
                        this.adjustValue(-this.adjustmentSmall);
                    }
                }

                if (this.adjustmentMediumExtraKeyPressed) {
                    if (buttonType === 'increment') {
                        this.adjustValue(this.adjustmentMedium);
                    } else if (buttonType === 'decrement') {
                        this.adjustValue(-this.adjustmentMedium);
                    }
                }

                if (this.adjustmentLargeExtraKeyPressed) {
                    if (buttonType === 'increment') {
                        this.adjustValue(this.adjustmentLarge);
                    } else if (buttonType === 'decrement') {
                        this.adjustValue(-this.adjustmentLarge);
                    }
                }
            }
        },
        buttonsAdjustmentProcessQueue() {
            if (this.adjustmentInQueue) {
                if (this.adjustmentMediumExtraKeyPressed) {
                    // regular queued click adds / subtracts adjustmentSmall, so when medium / large is resolved
                    // the small adjustment is added already, subtract it from the value
                    if (this.adjustmentInQueue === 'increment') {
                        this.adjustValue(this.adjustmentMedium - this.adjustmentSmall);
                    } else if (this.adjustmentInQueue === 'decrement') {
                        this.adjustValue(-this.adjustmentMedium + this.adjustmentSmall);
                    }
                }

                if (this.adjustmentLargeExtraKeyPressed) {
                    // regular queued click adds / subtracts adjustmentSmall, so when medium / large is resolved
                    // the small adjustment is added already, subtract it from the value
                    if (this.adjustmentInQueue === 'increment') {
                        this.adjustValue(this.adjustmentLarge - this.adjustmentSmall);
                    } else if (this.adjustmentInQueue === 'decrement') {
                        this.adjustValue(-this.adjustmentLarge + this.adjustmentSmall);
                    }
                }

                this.adjustmentInQueue = null;
            }
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // input
            this.tooltipWhiteListInitial.push(this.$refs.input);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        }
    }
};
</script>
