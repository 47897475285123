// === GENERAL ==============================//
export const PUSH_BROWSER_SUPPORT = 'PUSH_BROWSER_SUPPORT';

// === PERSISTENT STORAGE ===================//

// === DATA STORE ===========================//

// === USER ================================//
export const GET_USER_NOTICES = 'GET_USER_NOTICES';

// === DASHBOARD ===========================//

// === POPUP ===============================//

// === EVENTS ==============================//

// === RECRUITMENT =========================//

// === RESTAURANTS =========================//

// === FOOD ESHOP ==========================//
export const GET_FOOD_ESHOP_CART_SUBTOTAL_NO_PACKAGING = 'GET_FOOD_ESHOP_CART_SUBTOTAL_NO_PACKAGING';
export const GET_FOOD_ESHOP_CART_SUBTOTAL = 'GET_FOOD_ESHOP_CART_SUBTOTAL';
export const GET_FOOD_ESHOP_CART_PACKAGES_PRICE = 'GET_FOOD_ESHOP_CART_PACKAGES_PRICE';
export const GET_FOOD_ESHOP_CART_TOTAL_ITEMS = 'GET_FOOD_ESHOP_CART_TOTAL_ITEMS';
// FIXME: rename getter to GetFoodEshopSaledIds
export const GET_FOOD_ESHOP_SALE_OBJECT = 'GET_FOOD_ESHOP_SALE_OBJECT';

// === ONBOARDING =========================//
export const GET_USER_ROLE = 'GET_USER_ROLE'
