<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-textarea" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.INPUT"
                class="vue-label"
                >{{ label }}</label
            >
            <textarea
                :id="textAreaId"
                ref="textArea"
                v-model="valueComputed"
                class="vue-textarea"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :rows="rowsCurrent"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="textAreaClick($event.target.value, $event)"
                @keydown="textAreaKeyDown($event.target.value, $event)"
                @keyup="textAreaKeyUp($event.target.value, $event)"
                @input="textAreaInput($event.target.value, $event)"
                @change="textAreaChange($event.target.value, $event)"
                @focus="textAreaFocus($event.target.value, $event)"
                @blur="textAreaBlur($event.target.value, $event)"
            />
            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>
        </div>
        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClear from '../../mixins/buttonClear';
import Tooltip from '../../mixins/tooltip';
import Localization from '../../mixins/localization';

// TODO MBU: move to separate folder which is included with components package.
// project needs structure refactor to separate components from the catalogue
import config from '../../../config';

//=== MISC
import { debounce } from '../../../utils/utils-general';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1013';

//============ OPTIONS =================================//
//======================================================//
// TODO MBU: global config
let options = {
    rowsAutoResizeDebounce: 100
};

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1013Textarea',
    components: {
        Gen1010InformationTooltip,
        Frm1006Button
    },
    mixins: [Component, ButtonClear, Tooltip, Localization],
    model: {
        prop: 'value',
        event: 'textAreaUpdateEvent'
    },
    props: {
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        resizeable: Boolean,
        autocomplete: String,
        placeholder: String,
        value: {
            default: '',
            type: String
        },
        liveUpdate: {
            default: () => {
                return config.inputLiveUpdate;
            },
            type: Boolean
        },
        selectValueOnFocus: Boolean,
        rows: Number,
        rowsAutoExpand: Boolean,
        rowsAutoExpandMinRows: {
            default: 2,
            type: Number
        },
        rowsAutoExpandMaxRows: {
            default: null,
            type: Number
        },
        //=== ADDITIONAL ELEMENTS
        decorator: Boolean,
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            valueData: this.value,
            componentIsActive: false,
            focused: false,
            rowsAutoExpandRows: null
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-is-resizeable': this.resizeable,
                    [`vue-has-rows-${this.rows}`]: this.rows,
                    'vue-is-auto-expand': this.rowsAutoExpand,
                    'vue-is-auto-expand-with-min-rows': this.rowsAutoExpand && this.rowsAutoExpandMinRows > 1,
                    'vue-is-auto-expand-with-max-rows': this.rowsAutoExpand && this.rowsAutoExpandMaxRows !== null,
                    'vue-has-decorator': this.decorator,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                if (this.liveUpdate) {
                    this.$emit('textAreaUpdateEvent', value); // event for v-model
                }
            }
        },
        rowsCurrent() {
            if (!this.rowsAutoExpand) {
                return this.rows;
            } else {
                // ensure that js prop textarea.rows is manifested correctly in html attribute
                return this.rowsAutoExpandRows;
            }
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        textAreaId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.INPUT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === '';
        }
    },
    watch: {
        value(value) {
            this.valueData = value;

            if (this.rowsAutoExpand) {
                this.autoExpandCalculateHeight();
            }
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.textArea.focus();
            }
        },
        componentIsActive(value) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('componentIsActiveEvent', value);
        },
        focused(value) {
            this.$emit('textAreaFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('tooltipWhiteListInitial', value);
        },
        rowsAutoExpand() {
            if (this.rowsAutoExpand) {
                this.attachWindowResizeEvent();
            } else {
                this.removeWindowResizeEvent();
            }
        },
        rowsAutoExpandMinRows() {
            this.autoExpandCalculateHeight();
        },
        rowsAutoExpandMaxRows() {
            this.autoExpandCalculateHeight();
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }

        if (this.rowsAutoExpand) {
            this.autoExpandCalculateHeight();
            this.attachWindowResizeEvent();
        }
    },
    destroyed() {
        if (this.rowsAutoExpand) {
            this.removeWindowResizeEvent();
        }
    },
    methods: {
        //=== GENERAL
        textAreaSetFocus() {
            this.$refs.textArea.focus();
        },
        textAreaSetBlur() {
            this.$refs.textArea.blur();
        },
        setComponentActiveState() {
            if (!this.readonly) {
                this.componentIsActive = document.activeElement === this.$refs.textArea;
            }
        },
        setTextAreaFocusState() {
            this.focused = document.activeElement === this.$refs.textArea;
        },
        //=== EVENTS
        textAreaClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaClickEvent', value, event);
        },
        textAreaKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaKeyDownEvent', value, event);
        },
        textAreaKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaKeyUpEvent', value, event);
        },
        textAreaChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaChangeEvent', value, event);

            if (!this.liveUpdate) {
                this.$emit('textAreaUpdateEvent', value); // event for v-model
            }
        },
        textAreaInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaInputEvent', value, event);
            this.$emit('input', value); // event for v-model
        },
        textAreaFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setTextAreaFocusState();
            // tooltip
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
            // select value on focus
            if (this.selectValueOnFocus) {
                this.$refs.textArea.select();
            }
        },
        textAreaBlur(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('textAreaBlurEvent', value, event);
            this.setComponentActiveState();
            this.setTextAreaFocusState();
        },
        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // input
            this.tooltipWhiteListInitial.push(this.$refs.textArea);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        },
        //=== AUTO-EXPAND
        // TODO REVIEW: too long and complex method - split and refactor
        autoExpandCalculateHeight() {
            let textArea = this.$refs.textArea;
            let textAreaBoxSizing = window.getComputedStyle(textArea).getPropertyValue('box-sizing');
            let textAreaHeightTotal;
            let textAreaBorderTop = parseFloat(window.getComputedStyle(textArea).getPropertyValue('border-top-width'));
            let textAreaBorderBottom = parseFloat(
                window.getComputedStyle(textArea).getPropertyValue('border-bottom-width')
            );
            let textAreaPaddingTop;
            let textAreaPaddingBottom;
            let textAreaLineHeight;
            let textAreaLineNumberCurrent;
            let textAreaStyleBackup;
            let textAreaHeightAdditionalValue = 1; // add 1px to height - on some browsers, there was overflow appearing
            let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

            // firefox workaround
            if (isFirefox) {
                // TODO MBU: firefox calculates textarea scroll height wrongly, also empty line vs line with content has different heights (probably font-family dependent)
                // TODO MBU: 2px additional height works for normal font sizes
                // TODO MBU: it might be neccessary to compute the value in future with different font-size, line-height, padding etc. if this is problem in real usage
                if (this.rowsAutoExpandMinRows > 1) {
                    textAreaHeightAdditionalValue = 2;
                }
            }

            // TODO MBU: validate edge cases, if this cannot happen, remove this check
            if (isNaN(textAreaBorderTop)) {
                throw "Text area css property border-top didn't return number.";
            }
            if (isNaN(textAreaBorderBottom)) {
                throw "Text area css property border-bottom didn't return number.";
            }

            //========= CALCULATE TEXTAREA LINE HEIGHT ===//
            // only calculate in cases line-height value is required
            if (this.rowsAutoExpandMaxRows || textAreaBoxSizing === 'content-box' || isFirefox) {
                // set rows and inline styles and THEN calculate line height for case it's needed (when rowsAutoExpandMaxRows is set or textarea has content-box sizing)
                textAreaPaddingTop = parseFloat(window.getComputedStyle(textArea).getPropertyValue('padding-top'));
                textAreaPaddingBottom = parseFloat(
                    window.getComputedStyle(textArea).getPropertyValue('padding-bottom')
                );

                // TODO MBU: validate edge cases, if this cannot happen, remove this check
                if (isNaN(textAreaPaddingTop)) {
                    throw "Text area css property padding-top didn't return number.";
                }
                if (isNaN(textAreaPaddingBottom)) {
                    throw "Text area css property padding-bottom didn't return number.";
                }

                // backup inline styles
                textAreaStyleBackup = textArea.style.cssText;

                // set and overwrite all styles / rows to do calculation
                // overflow hidden / height auto / resetting rows param causes firefox to to break scroll to current line, must be fixed manually, see WORKAROUND - FIREFOX SCROLL below
                textArea.style.cssText =
                    'height:auto!important;padding:0!important;min-height:0!important;border:none!important;overflow:hidden!important;'; // overflow hidden causes firefox to to break scroll to current line, must be fixed manually, see workaround below

                // WORKAROUND: calculate from 2 rows, 1 row calculation might be wrong due to various issues (placeholder line-height etc.)
                textArea.rows = 2;
                this.rowsAutoExpandRows = 2; // set also data for HTML attribute
                textAreaLineHeight = textArea.getBoundingClientRect().height / 2;
                textArea.rows = this.rowsAutoExpandMinRows; // reset to min rows
                this.rowsAutoExpandRows = this.rowsAutoExpandMinRows; // set also data for HTML attribute

                // return original inline styles
                textArea.style.cssText = textAreaStyleBackup;
            }

            //========= CALCULATE TEXTAREA HEIGHT ========//
            // backup inline styles
            textAreaStyleBackup = textArea.style.cssText;

            // set styles / rows to do calculation
            // overflow hidden / height auto / resetting rows param causes firefox to to break scroll to current line, must be fixed manually, see WORKAROUND - FIREFOX SCROLL below
            textArea.style.setProperty('height', 'auto', 'important');
            textArea.style.setProperty('overflow', 'hidden', 'important');
            textArea.rows = this.rowsAutoExpandMinRows;
            this.rowsAutoExpandRows = this.rowsAutoExpandMinRows; // set also data for HTML attribute

            let textAreaScrollHeight = textArea.scrollHeight;

            // return original inline styles
            textArea.style.cssText = textAreaStyleBackup;

            // calculate resulting textarea height
            //=== BORDER BOX
            if (textAreaBoxSizing === 'border-box') {
                if (
                    this.rowsAutoExpandMaxRows &&
                    textAreaScrollHeight >
                        textAreaLineHeight * this.rowsAutoExpandMaxRows + textAreaPaddingTop + textAreaPaddingBottom
                ) {
                    textAreaHeightTotal =
                        textAreaLineHeight * this.rowsAutoExpandMaxRows +
                        textAreaBorderTop +
                        textAreaBorderBottom +
                        textAreaPaddingTop +
                        textAreaPaddingBottom +
                        textAreaHeightAdditionalValue;
                } else {
                    textAreaHeightTotal =
                        textAreaScrollHeight + textAreaBorderTop + textAreaBorderBottom + textAreaHeightAdditionalValue;
                }
            }

            //=== CONTENT BOX
            else if (textAreaBoxSizing === 'content-box') {
                if (
                    this.rowsAutoExpandMaxRows &&
                    (textAreaScrollHeight >
                        textAreaLineHeight * this.rowsAutoExpandMaxRows + textAreaPaddingTop + textAreaPaddingBottom) +
                        textAreaHeightAdditionalValue
                ) {
                    textAreaHeightTotal = textAreaLineHeight * this.rowsAutoExpandMaxRows;
                } else {
                    textAreaHeightTotal =
                        textAreaScrollHeight -
                        textAreaPaddingTop -
                        textAreaPaddingBottom +
                        textAreaHeightAdditionalValue;
                }
            }

            // set final calculated height
            textArea.style.height = textAreaHeightTotal + 'px';

            // WORKAROUND - FIREFOX SCROLL: firefox (maybe also other browsers) automatic scroll was impacted by calculation of current line height (set overflow hidden, set rows to 2, custom inline styles etc.)
            // manually set scroll position to current line
            // TODO MBU: scroll when typing is not behaving exactly as natively implemented in browser with this workaround
            // TODO MBU: calculate visible rows on current scroll postition, set scroll only if current line number is not first or last (must be precise - if line is partially in view, set scroll), nice to have
            if (isFirefox) {
                let scrollLineOffset = -textAreaBorderTop; // TODO MBU: instead textAreaBorderTop there should be some other value, probably tied to font-family, font-size, line-height, padding etc.
                textAreaLineNumberCurrent = textArea.innerHTML = textArea.value
                    .substr(0, textArea.selectionStart)
                    .split('\n').length;
                textArea.scrollTop =
                    textAreaLineNumberCurrent * textAreaLineHeight - textAreaLineHeight + scrollLineOffset;
            }
        },
        // FIXME MBU: create utils for vue components, this is general utils for catalogue
        // TODO MBU: debounce delay via config
        // TODO MBU: add toggling debounce as parameter + default config value?
        attachWindowResizeEvent() {
            window.addEventListener(
                'resize',
                debounce(this.autoExpandCalculateHeight, options.rowsAutoResizeDebounce, false)
            );
        },
        removeWindowResizeEvent() {
            window.removeEventListener(
                'resize',
                debounce(this.autoExpandCalculateHeight, options.rowsAutoResizeDebounce, false)
            );
        }
    }
};
</script>
