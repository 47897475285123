<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-image">
        <img v-if="src" class="vue-img" :src="src" :alt="alt" />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1020Image',
    props: {
        src: {
            default: null,
            type: String
        },
        alt: {
            default: '',
            type: String
        },
        id: {
            default: null,
            type: String
        }
    }
};
</script>
