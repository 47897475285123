<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-pagination" :class="classObject">
        <ul class="vue-b-pagination-items">
            <PaginationItem
                itemType="buttonPrev"
                :buttonType="buttonPrevType"
                :disabled="buttonPrevDisabled"
                @paginationItemButtonPrevClickEvent="paginationButtonPrevClickEmit"
                >{{ i18n('paginationPrev') }}</PaginationItem
            >

            <template v-if="input">
                <PaginationItem
                    itemType="input"
                    :pageCurrent="pageCurrent"
                    :pagesTotal="pagesTotal"
                    @paginationItemInputChangePageEvent="paginationInputChangeEmit"
                />
            </template>

            <template v-if="buttons">
                <!-- Ellipsis before -->
                <template v-if="hasEllipsisBefore">
                    <PaginationItem
                        itemType="buttonPage"
                        :buttonType="buttonPageType"
                        :page="1"
                        :disabled="disabled"
                        @paginationItemButtonPageClickEvent="paginationButtonPageClickEmit"
                        >1</PaginationItem
                    >
                    <PaginationItem itemType="ellipsis">{{ ellipsisContent }}</PaginationItem>
                </template>

                <!-- Surrounding pages -->
                <template v-for="(n, key) in range(radiusIndexMin, radiusIndexMax)">
                    <!-- Page current -->
                    <template v-if="n == pageCurrent">
                        <PaginationItem
                            :key="key"
                            itemType="buttonPage"
                            :buttonType="buttonPageType"
                            :disabled="true"
                            class="vue-is-current"
                            >{{ pageCurrent }}</PaginationItem
                        >
                    </template>
                    <!-- Other pages -->
                    <template v-else>
                        <PaginationItem
                            :key="key"
                            itemType="buttonPage"
                            :buttonType="buttonPageType"
                            :page="n"
                            :disabled="disabled"
                            @paginationItemButtonPageClickEvent="paginationButtonPageClickEmit"
                            >{{ n }}</PaginationItem
                        >
                    </template>
                </template>

                <!-- Ellipsis before -->
                <template v-if="hasEllipsisAfter">
                    <PaginationItem itemType="ellipsis">{{ ellipsisContent }}</PaginationItem>
                    <PaginationItem
                        itemType="buttonPage"
                        :buttonType="buttonPageType"
                        :page="pagesTotal"
                        :disabled="disabled"
                        @paginationItemButtonPageClickEvent="paginationButtonPageClickEmit"
                        >{{ pagesTotal }}</PaginationItem
                    >
                </template>
            </template>

            <PaginationItem
                itemType="buttonNext"
                :buttonType="buttonNextType"
                :disabled="buttonNextDisabled"
                @paginationItemButtonNextClickEvent="paginationButtonNextClickEmit"
                >{{ i18n('paginationNext') }}</PaginationItem
            >
        </ul>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script itemType="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import PaginationItem from './tbl1002-pagination-item.vue';

//=== MIXINS
import Component from '../../mixins/component';
import Localization from '../../mixins/localization';

//============ CONSTANTS ===============================//
//======================================================//

//========= GENERAL ==========================//
let COMPONENT_ID = 'tbl1002';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Tbl1002Pagination',
    components: {
        PaginationItem
    },
    mixins: [Component, Localization],
    props: {
        input: {
            default: false,
            type: Boolean
        },
        buttons: {
            default: true,
            type: Boolean
        },
        buttonPrevType: {
            default: 'internal',
            type: String
        },
        buttonPageType: {
            default: 'internal',
            type: String
        },
        buttonNextType: {
            default: 'internal',
            type: String
        },
        pagesTotal: {
            required: true,
            type: Number
        },
        pageCurrent: {
            default: 1,
            type: Number
        },
        pageCurrentRadius: {
            default: 2,
            type: Number
        },
        disabled: {
            default: false,
            type: Boolean
        },
        ellipsisContent: {
            default: '...',
            type: String
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    computed: {
        classObject: function() {
            return {
                'vue-has-buttons': this.buttons === true,
                'vue-has-input': this.input === true,
                'vue-has-single-page': this.pagesTotal === 1,
                'vue-is-page-first': this.pageCurrent === 1,
                'vue-is-page-last': this.pageCurrent === this.pagesTotal,
                'vue-is-disabled': this.disabled === true
            };
        },
        hasEllipsisBefore: function() {
            return this.pageCurrent - this.pageCurrentRadius - 1 > 1;
        },
        hasEllipsisAfter: function() {
            return this.pageCurrent + this.pageCurrentRadius + 1 < this.pagesTotal;
        },
        radiusIndexMin: function() {
            if (this.hasEllipsisBefore) {
                return this.pageCurrent - this.pageCurrentRadius;
            } else {
                return 1;
            }
        },
        radiusIndexMax: function() {
            if (this.hasEllipsisAfter) {
                return this.pageCurrent + this.pageCurrentRadius;
            } else {
                return this.pagesTotal;
            }
        },
        buttonPrevDisabled: function() {
            return this.pageCurrent === 1 || this.pagesTotal < 1 || this.disabled;
        },
        buttonNextDisabled: function() {
            return this.pageCurrent === this.pagesTotal || this.pagesTotal < 1 || this.disabled;
        }
    },
    methods: {
        range: function(start, end) {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        },
        paginationButtonPrevClickEmit() {
            this.$emit('paginationButtonPrevClickEvent');
            this.$emit('update:pageCurrent', this.pageCurrent - 1);
        },
        paginationButtonPageClickEmit(page) {
            this.$emit('paginationButtonPageClickEvent', page);
            this.$emit('update:pageCurrent', page);
        },
        paginationInputChangeEmit(page) {
            this.$emit('paginationInputChangeEvent', page);
            this.$emit('update:pageCurrent', page);
        },
        paginationButtonNextClickEmit() {
            this.$emit('paginationButtonNextClickEvent');
            this.$emit('update:pageCurrent', this.pageCurrent + 1);
        }
    }
};
</script>
