import Frm1026FormRowSet from './frm1026-form-row-set';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1026FormRowSet);
    }
};

use(Plugin);

export default Plugin;

export { Frm1026FormRowSet };
