<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-controls" :class="classObject">
        <frm1031-button-container v-if="hasPrimaryActions" class="vue-primary-actions">
            <slot name="primaryActions"></slot>
        </frm1031-button-container>

        <frm1031-button-container v-if="hasSecondaryActions" class="vue-secondary-actions">
            <slot name="secondaryActions"></slot>
        </frm1031-button-container>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->

<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1031ButtonContainer from '../../frm/frm1031-button-container/frm1031-button-container';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1017Controls',
    components: {
        Frm1031ButtonContainer
    },
    props: {
        id: String
    },
    computed: {
        classObject() {
            return {
                'vue-has-primary-actions': this.hasPrimaryActions,
                'vue-has-secondary-actions': this.hasSecondaryActions
            };
        },
        hasPrimaryActions() {
            return !!this.$slots.primaryActions;
        },
        hasSecondaryActions() {
            return !!this.$slots.secondaryActions;
        }
    }
};
</script>
