<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-tag-label" :class="classObject">
        <span class="vue-caption">
            <slot>{{ caption }}</slot>
        </span>
        <frm1006-button
            v-if="buttonDelete"
            type="internal"
            :title="buttonDeleteCaptionComputed"
            class="vue-ci-button-delete"
            @buttonClickEvent="buttonDeleteClick"
        >
            {{ buttonDeleteCaptionComputed }}
        </frm1006-button>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Localization from '../../mixins/localization';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1004TagLabel',
    components: {
        Frm1006Button
    },
    mixins: [Localization],
    props: {
        type: {
            default: 'default',
            type: String
        },
        caption: {
            default: null,
            type: String
        },
        value: {
            default: null,
            type: String
        },
        buttonDelete: {
            default: false,
            type: Boolean
        },
        buttonDeleteCaption: String,
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject: function() {
            return [
                'vue-is-' + this.type,
                {
                    'vue-has-button-delete': this.buttonDelete
                }
            ];
        },
        buttonDeleteCaptionComputed() {
            if (!this.buttonDeleteCaption) {
                return this.i18n('buttonDelete');
            }

            return this.buttonDeleteCaption;
        }
    },
    methods: {
        buttonDeleteClick() {
            this.$emit('buttonDeleteClickEvent', this.id, this.value);
        }
    }
};
</script>
