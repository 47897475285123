<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template
        v-if="!isDocumentSent"
      >
        <template
          v-if="!modeEdit"
        >
          <gen1006-info-panel
            v-if="isDocumentationClosed"
            type="success"
          >
            <span v-html="$t('onboarding.documentationClosed')" />
          </gen1006-info-panel>
          <div
            class="vue-onboarding-section-intro"
            v-html="$t('onboarding.photoUploadNote')"
          >
          </div>
          <gen1006-info-panel
            class="vue-is-info-panel"
            type="neutral"
          >
            {{ $t('onboarding.fileUploadWarning') }}
          </gen1006-info-panel>
          <img
            v-if="(!croppedImageConfirmed && !uploadedPhotoUrl)"
            class="vue-onboarding-photo-example"
            :alt="$t('onboarding.photo')"
            :src="examplePhotoUrl"
          >
          <img
            v-if="(!croppedImageConfirmed && uploadedPhotoUrl)"
            class="vue-onboarding-avatar"
            :alt="$t('onboarding.photo')"
            :src="uploadedPhotoUrl"
          >
          <img
            v-if="croppedImageConfirmed"
            :src="croppedImageBase64"
            class="vue-onboarding-avatar"
          />
          <div class="vue-onboarding-controls">
            <div
              class="vue-c-photo-upload"
            >
              <input
                ref="photoUpload"
                class="vue-image-upload-input"
                type="file"
                accept="image/jpeg, image/png"
                @change="uploadPhoto"
              >
              <frm1006-button
                v-if="!isDocumentationClosed"
                class="vue-image-upload-button"
                type="app-button"
                @buttonClickEvent="loadImage"
              />
              <frm1006-button
                v-if="croppedImageConfirmed"
                class="vue-image-edit-button"
                type="app-button"
                @buttonClickEvent="editLoadedImage"
              />
            </div>
          </div>
        </template>
        <div
          v-show="modeEdit"
        >
          <p>{{ $i18n.t('onboarding.fileUploadHint') }}</p>
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            :zoomable="true"
            :aspectRatio="1"
            :viewMode="Number(0)"
            :autoCropArea="1"
            :dragMode="'move'"
          />
          <div class="vue-onboarding-edit-controls">
            <frm1006-button
              type="primary"
              :title="$i18n.t('onboarding.save')"
              @buttonClickEvent="confirmImage"
            >
              {{ $t('onboarding.save') }}
            </frm1006-button>
            <frm1006-button
              type="secondary"
              :title="$i18n.t('onboarding.cancel')"
              @buttonClickEvent="cancelUpload"
            >
              {{ $t('onboarding.cancel') }}
            </frm1006-button>
          </div>
        </div>
      </template>
      <div
        v-else
        class="vue-document-upload-confirm"
      >
        <prj1017-heading
          :header="$i18n.t('onboarding.uploadDocumentConfirmHeader')"
          :subheader="$i18n.t('onboarding.uploadPhotoConfirmSubheader')"
        />
      </div>
    </template>

    <!--========== CONTENT FOOTER =========================-->
    <!--===================================================-->
    <template v-slot:footer>
      <template v-if="!isDocumentSent">
        <template v-if="!modeEdit">
          <frm1031-button-container>
            <frm1006-button
              v-if="!isDocumentationClosed"
              type="primary"
              :title="$i18n.t('onboarding.send')"
              :disabled="!croppedImageConfirmed"
              @buttonClickEvent="submitDocument"
            >
              {{ $t('onboarding.send') }}
            </frm1006-button>
          </frm1031-button-container>
        </template>
      </template>
      <template v-else>
        <frm1006-button
          type="primary"
          :title="$i18n.t('onboarding.continue')"
          @buttonClickEvent="goToView('onboarding-directory')"
        >
          {{ $t('onboarding.continue') }}
        </frm1006-button>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { ERROR_MODAL_TIMEOUT, ONBOARDING_APPLICANT_DOC_STATUSES } from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1017Heading from '@/components/prj1017-heading/prj1017-heading';
import VueCropper from 'vue-cropperjs';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';
import * as envConfig from 'env-config';

export default {
  name: 'OnboardingDocumentUpload',
  components: {
    PageLayout,
    Prj1002Navbar,
    VueCropper,
    Prj1017Heading,
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      croppedImageConfirmed: false,
      modeEdit: false,
      // cropper
      croppedImage: '',
      croppedImageBase64: '',
      selectedFile: '',
      selectedFileName: '',
      selectedFileType: '',
      croppedImageHeight: 512,
      cropperAspectRatio: 1,
      isDocumentSent: false,
      uploadedPhotoUrl: null,
      examplePhotoUrl: '../../../img/photo/user-photo-example.png'
    };
  },

  computed: {
    docsStatus() {
      return this.$route.params.docsStatus;
    },

    isDocumentationClosed() {
      return this.docsStatus === ONBOARDING_APPLICANT_DOC_STATUSES.sentToBank
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.initialized = true;
    this.fetchUploadedPhotoUrl();
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    confirmImage() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas({
        width: this.croppedImageHeight * this.cropperAspectRatio,
        height: this.croppedImageHeight,
        imageSmoothingQuality: 'high',
        fillColor: '#fff'
      });
      this.croppedImageBase64 = this.croppedImage.toDataURL('image/jpeg');
      this.modeEdit = false;
      this.croppedImageConfirmed = true;
    },

    uploadPhoto(event) {
      const file = event.target.files[0]

      if (file) {
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = event => {
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
              this.selectedFileName = file.name;
              this.selectedFileType = file.type;
              this.selectedFile = event.target.result;
              this.$refs.cropper.replace(this.selectedFile);
              this.modeEdit = true;
            }
          };
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      } else {
        this.cancelUpload();
      }
    },

    cancelUpload() {
      this.selectedFile = '';
      this.$refs.cropper.replace(null);
      this.modeEdit = false;
      this.croppedImageConfirmed = false;
    },

    editLoadedImage() {
      this.modeEdit = true;
    },

    loadImage() {
      this.$refs.photoUpload.click();
    },

    submitDocument() {
      this.setLoadingState(true);

      this.croppedImage.toBlob((blob) => {
        return dataLoader.sendDocument(
          this.selectedFileName,
          this.selectedFileType,
          'photo',
          null,
          blob
        )
          .then(() => {
            this.isDocumentSent = true;
          })
          .catch(error => {
            logger.error(error);
            this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
            this.$nextTick(() => {
              this.hasError = true
            });
            if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
              setTimeout(() => {
                this.$router.push({name: 'login'});
              }, 5000);
            }
          })
          .finally(() => {
            this.setLoadingState(false);
          });
      });

    },

    fetchUploadedPhotoUrl() {
      this.setLoadingState(true);

      dataLoader.fetchApplicantDocuments()
        .then(documents => {
          const uploadedPhoto = documents.find(doc => doc.category === 'photo');
          if (uploadedPhoto) {
            this.uploadedPhotoUrl = envConfig.default.webServices.ONBOARDING_DOCUMENTS + '/' + uploadedPhoto.id + '/content';
          }
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },

  },
}
</script>
