<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" ref="component" class="vue-component vue-c-option-group" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <!--========== MULTISELECT ============================-->
            <!--===================================================-->
            <template v-if="multiselect">
                <!-- TODO MBU: ul > li? -->
                <div v-for="item in options" :key="item.value" class="vue-b-option-item">
                    <!--======= OPTIONS =========================-->
                    <!-- name attr received from option group ITEM - grouping is optional -->
                    <frm1003-checkbox
                        :id="item.id"
                        v-model="valueComputed"
                        :valueNative="item.value"
                        :name="checkboxItemName(item.name, item.namePrefix)"
                        :state="item.state"
                        :indeterminate="item.indeterminate"
                        :required="required ? required : item.required"
                        :disabled="item.disabled"
                        :tooltip="itemHasTooltip(item.tooltip)"
                        :tooltipContent="item.tooltipContent"
                        :idPrefix="item.idPrefix"
                        @checkboxClickEvent="itemClick"
                        @checkboxKeyDownEvent="itemKeyDown"
                        @checkboxKeyUpEvent="itemKeyUp"
                        @checkboxChangeEvent="itemChange"
                        @checkboxFocusEvent="itemFocus"
                        @checkboxBlurEvent="itemBlur"
                        @checkboxCheckEvent="itemCheck"
                        @componentIsActiveEvent="optionsActiveSet"
                        @tooltipWhiteListInitial="optionsTooltipWhiteListInitialSet"
                    >
                        {{ item.caption }}
                    </frm1003-checkbox>
                </div>
            </template>
            <template v-else>
                <!-- name attr received from option group - radios must be grouped -->
                <div v-for="item in options" :key="item.value" class="vue-b-option-item">
                    <frm1004-radio
                        :id="item.id"
                        v-model="valueComputed"
                        :valueNative="item.value"
                        :name="nameComputed"
                        :state="item.state"
                        :required="required ? required : item.required"
                        :disabled="item.disabled"
                        :tooltip="itemHasTooltip(item.tooltip)"
                        :tooltipContent="item.tooltipContent"
                        :idPrefix="item.idPrefix"
                        @radioClickEvent="itemClick"
                        @radioKeyDownEvent="itemKeyDown"
                        @radioKeyUpEvent="itemKeyUp"
                        @radioChangeEvent="itemChange"
                        @radioFocusEvent="itemFocus"
                        @radioBlurEvent="itemBlur"
                        @radioCheckEvent="itemCheck"
                        @componentIsActiveEvent="optionsActiveSet"
                        @tooltipWhiteListInitial="optionsTooltipWhiteListInitialSet"
                    >
                        {{ item.caption }}
                    </frm1004-radio>
                </div>
            </template>
        </div>
        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1003Checkbox from '../frm1003-checkbox/frm1003-checkbox';
import Frm1004Radio from '../frm1004-radio/frm1004-radio';

//=== MIXINS
import Tooltip from '../../mixins/tooltip';

// TODO MBU: move to separate folder which is included with components package.
// project needs structure refactor to separate components from the catalogue
import config from '../../../config';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1025OptionGroup',
    components: {
        Gen1010InformationTooltip,
        Frm1003Checkbox,
        Frm1004Radio
    },
    mixins: [Tooltip],
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        name: {
            type: String
        },
        namePrefix: {
            default: 'option-group',
            type: String
        },
        options: {
            default: function() {
                return [];
            },
            type: Array
        },
        multiselect: {
            default: false,
            type: Boolean
        },
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        },
        value: {
            type: [String, Array, Boolean]
        },
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        id: {
            default: null,
            type: String
        }
    },
    data: function() {
        return {
            valueData: this.value,
            componentIsActive: false,
            optionsActive: {},
            optionsTooltipWhiteListInitial: []
        };
    },
    computed: {
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-disabled': this.disabled,
                    'vue-has-tooltip': this.tooltipComputed,
                    'vue-has-tooltip-empty':
                        this.tooltipContent === '' || (this.tooltipContent === null && this.tooltipComputed),
                    'vue-is-empty': this.options.length < 1
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip];
        },
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                this.$emit('change', value);
            }
        },
        nameComputed() {
            // manual name generation
            if (this.namePrefix && this.name) {
                return this.namePrefix + '-' + this.name;
            }
            // enforce exact name (must set name prefix to falsy value manually)
            else if (!this.namePrefix && this.name) {
                return this.name;
            }
            // automatic name generation
            return this.namePrefix + '-' + this._uid;
        }
    },
    watch: {
        optionsActive: {
            deep: true,
            handler: function() {
                this.componentIsActive = false;

                for (let optionId in this.optionsActive) {
                    if (this.optionsActive.hasOwnProperty(optionId) && this.optionsActive[optionId] === true) {
                        this.componentIsActive = true;
                        break;
                    }
                }
            }
        },
        value(value) {
            this.valueData = value;
            this.$emit('optionGroupChangeEvent', value);
        },
        tooltipExpandedData() {
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.itemSetFocus();
            }
        }
    },
    methods: {
        itemSetFocus() {
            if (this.multiselect) {
                this.$refs.component
                    // TODO REVIEW: create constant with selector
                    .querySelector('.vue-b-form-field .vue-b-option-item:first-child .vue-c-checkbox .vue-checkbox')
                    .focus();
            } else {
                this.$refs.component
                    // TODO REVIEW: create constant with selector
                    .querySelector('.vue-b-form-field .vue-b-option-item:first-child .vue-c-radio .vue-radio')
                    .focus();
            }
        },
        // TODO MBU: blur focused item
        // TODO REVIEW: unused empty method - implement or remove
        itemSetBlur() {},
        optionsActiveSet(value, id) {
            this.$set(this.optionsActive, id, value);
        },
        optionsTooltipWhiteListInitialSet(optionGroupItemWhiteListArray) {
            if (optionGroupItemWhiteListArray.length > 0) {
                for (let element of optionGroupItemWhiteListArray) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        },
        //=== EVENTS
        itemClick(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemClickEvent', valueNative, event);
        },
        itemKeyDown(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemKeyDownEvent', valueNative, event);
        },
        itemKeyUp(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemKeyUpEvent', valueNative, event);
        },
        itemChange(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemChangeEvent', valueNative, event);
        },
        itemFocus(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemFocusEvent', valueNative, event);
            // TODO REVIEW: improve readability, extract into several named statements
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
        },
        itemBlur(valueNative, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemBlurEvent', valueNative, event);
        },
        itemCheck(valueNative, checked) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('itemCheckEvent', valueNative, checked);
        },
        checkboxItemName(itemName, itemNamePrefix) {
            if (itemName && itemNamePrefix === null) {
                return itemName;
            } else if (itemName) {
                return this.nameComputed + '-' + itemName;
            } else {
                return null;
            }
        },
        // TODO REVIEW: returns mixed value. Improve construction.
        // set tooltip to false by default for convenience
        itemHasTooltip(itemTooltip) {
            return itemTooltip || false;
        }

        // TODO MBU: keyboard navigation for checkboxes so they behave same way the radios does?
        // TODO MBU: item tooltip open on focus? This would cause 2 tooltips at once. Set tooltipOpenOnFocus="off" to prevent it?
    }
};
</script>
