import Gen1016LoadingIndicator from './gen1016-loading-indicator';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1016LoadingIndicator);
    }
};

use(Plugin);

export default Plugin;

export { Gen1016LoadingIndicator };
