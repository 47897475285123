<script>
export default {
  data() {
    return {
      serverError: ''
    }
  },
  methods: {
    getErrorMessageDetail(errorCode) {
      if (!errorCode) return ''
      return this.$t('error.serverDetail')[errorCode] || ''
    }
  }
}
</script>
