<template>
  <div
    class="vue-c-navbar"
    :class="classObject"
  >
    <template
      v-if="isDesktopLayout"
    >
      <transition name="vue-navigation">
        <frm1006-button
          class="vue-navbar-button vue-is-menu"
          :class="navbarButtonDesktopClassObject"
          :active="isMenuOpened"
          :iconLeft="true"
          :captionHidden="true"
          type="app-button"
          @buttonClickEvent="processNavbarDesktopButton"
        >
          <template v-slot:iconLeft>
            <span class="vue-icon-inner" />
          </template>
        </frm1006-button>
      </transition>

      <frm1006-button
        v-if="this.$route.name !== 'dashboard'"
        class="vue-navbar-button vue-is-back vue-content-back-button"
        :active="this.$route.name !== 'dashboard'"
        :iconLeft="true"
        :captionHidden="true"
        type="app-button"
        @buttonClickEvent="processNavbarButton"
      >
        <template v-slot:iconLeft>
          <span class="vue-icon-inner" />
        </template>
      </frm1006-button>

      <div class="vue-b-caption">
        <frm1006-button
          v-if="navigationButton && isDesktopLayout"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-navbar-navigation-icon"
          @buttonClickEvent="goToDashboard"
        />
        <div
          class="vue-caption-container"
        >
          <span
            v-if="caption"
            class="vue-caption"
          >
            {{ caption }}
          </span>
          <frm1006-button
            v-if="infoButton"
            type="internal"
            :iconLeft="true"
            :captionHidden="true"
            class="vue-navbar-info-icon"
            @buttonClickEvent="showInfoOverlay"
          >
            {{ $t('general.showInfo') }}
          </frm1006-button>
        </div>
      </div>

      <prj1002-navbar-notifications />

      <prj1002-navbar-profile />

      <gen1009-overlay
        :active.sync="infoVisibility"
        :buttonClose="true"
        class="vue-is-full-screen"
      >
        <div v-html="infoText" />
      </gen1009-overlay>
    </template>
    <template
      v-else
    >
      <transition name="vue-navigation">
        <frm1006-button
          class="vue-navbar-button vue-is-menu"
          :class="navbarButtonClassObject"
          :active="isMenuOpened"
          :iconLeft="true"
          :captionHidden="true"
          type="app-button"
          @buttonClickEvent="processNavbarButton"
        >
          <template v-slot:iconLeft>
            <span class="vue-icon-inner" />
          </template>
        </frm1006-button>
      </transition>

      <div class="vue-b-caption">
        <span
          v-if="caption"
          class="vue-caption"
        >
          {{ caption }}
        </span>
        <frm1006-button
          v-if="infoButton"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-navbar-info-icon"
          @buttonClickEvent="showInfoOverlay"
        >
          {{ $t('general.showInfo') }}
        </frm1006-button>
        <frm1006-button
          v-if="navigationButton && isDesktopLayout"
          type="internal"
          :iconLeft="true"
          :captionHidden="true"
          class="vue-navbar-navigation-icon"
          @buttonClickEvent="goToDashboard"
        />
      </div>

      <prj1002-navbar-notifications />

      <prj1002-navbar-profile />

      <gen1009-overlay
        :active.sync="infoVisibility"
        :buttonClose="true"
        class="vue-is-full-screen"
      >
        <div v-html="infoText" />
      </gen1009-overlay>
    </template>
  </div>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GLOBAL_EVENTS from '@/event-bus/global-events';

import router from '@/router';
import {mapState} from 'vuex';
import EVENT_BUS from '@/event-bus';

import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import Prj1002NavbarProfile from '@/components/prj1002-navbar/prj1002-navbar-profile.vue';
import Prj1002NavbarNotifications from '@/components/prj1002-navbar/prj1002-navbar-notifications.vue';

//=== CONSTANTS ===================================//
//=================================================//
const COMPONENT_EVENTS = Object.freeze({
  ACTIVE_VIEW: 'activeView',
});

export default {
  name: 'Prj1002Navbar',

  components: {
    Prj1002NavbarNotifications,
    Prj1002NavbarProfile
  },

  mixins: [mxDetectDesktop],

  props: {
    buttonType: String,
    buttonMode: {
      default: 'default',
      type: String
    },
    buttonDestination: [String, Number],
    // TODO MBU: deprecate buttonBackType when ready
    buttonBackType: {
      default: 'back',
      type: String
    },
    caption: {
      type: String,
      required: false
    },
    building: {
      default: true,
      type: Boolean
    },
    infoButton: {
      type: Boolean,
      required: false
    },
    infoText: {
      type: String,
      required: false
    },
    infoButtonDestination: {
      type: String,
      required: false
    },
    infoButtonDestinationParams: {
      type: Object,
      required: false
    },
    contentDrawerFullScreen: Boolean,
    navigationButton: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  data() {
    return {
      infoVisibility: false,
      // FIXME: move this settings into router metadata and use page layouts
      backButtonEnabled: [
        'restaurants',
        'search',
        'user-settings',
        'strategy',
        'strategy-detail',
        'strategy-competition',
        'strategy-summary',
        'strategy-main-objectives',
        'workouts',
        'workout-detail',
        'workout-registration',
        'annual-innovation-report',
        'client-satisfaction',
      ],
      // FIXME: move this settings into router metadata and use page layouts
      buildingSelectorDisabled: [
        'restaurants',
        'search',
        'workouts',
        'workout-detail',
        'workout-registration',
        'annual-innovation-report',
        'strategy',
        'strategy-detail',
        'strategy-competition',
        'strategy-summary',
        'strategy-main-objectives',
        'client-satisfaction',
        'client-satisfaction-info',
      ],
      goDashboardPage: [
        'restaurants',
        'annual-innovation-report'
      ]
    }
  },

  computed: {
    ...mapState('general', [
      'isMenuOpened',
      'isSubmenuOpened',
      'isDrawerOpened',
      'applicationConfig',
      'applicationConfigLoaded'
    ]),
    ...mapState('dashboard', {
      storedSelectedBuilding: state => state.selectedBuilding
    }),
    ...mapState('user', [
      'publicKey'
    ]),

    classObject() {
      return [
        {
          'vue-is-desktop': this.isDesktopLayout,
          'vue-is-navigation-open': this.isMenuOpened,
          'vue-is-content-drawer-open': this.isDrawerOpened,
          'vue-has-building': this.building,
          'vue-has-caption': this.caption,
          'vue-has-button': true // all pages have button
        }
      ];
    },

    navbarButtonClassObject() {
      return [
        {
          ['vue-is-' + this.buttonType]: this.buttonType,
          'vue-is-back-legacy': this.$route.name !== 'dashboard' && this.buttonBackType,
        }
      ];
    },

    navbarButtonDesktopClassObject() {
      return [
        {
          'vue-is-hamburger': !this.isMenuOpened && !this.isSubmenuOpened,
          'vue-is-close': this.isMenuOpened && !this.isSubmenuOpened,
          'vue-is-back': this.isMenuOpened && this.isSubmenuOpened,
        }
      ];
    },

    pageId() {
      return this.$route.name;
    },

    buildingSelectorHidden() {
      return this.buildingSelectorDisabled.includes(this.pageId) || !this.building;
    },

    convertedLocalities() {
      let localitiesSettings = [];

      this.applicationConfig.localities.forEach(item => {
        let itemObject = {};

        itemObject.caption = item.name;
        itemObject.alias = item.alias;
        itemObject.value = item.id;
        itemObject.navigationId = item.navigationId;
        itemObject.customCss = item.cssClass;

        localitiesSettings.push(itemObject);
      });

      return localitiesSettings;
    },

    selectedBuilding: {
      get() {
        return this.storedSelectedBuilding;
      },
      set(value) {
        // this.selectedBuilding must be a string
        this.$store.commit(STORE_MODULES.DASHBOARD + '/' + MUTATIONS_CONSTANTS.SET_SELECTED_BUILDING, value.toString());
      }
    },

    dashboardMenuClosed() {
      return !this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardMenuTopLevelOpen() {
      return this.isMenuOpened && !this.isSubmenuOpened;
    },

    dashboardSubmenuOpen() {
      return this.isMenuOpened && this.isSubmenuOpened
    },
  },

  methods: {
    updateBuilding() {
      const options = {
        publicKey: this.publicKey,
        selectedBuilding: this.selectedBuilding
      };

      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.GET_APPLICATION_SETTINGS, options);
      EVENT_BUS.$emit(GLOBAL_EVENTS.RELOAD_ARTICLES);
    },

    processNavbarButton() {
      // TODO MBU: new layouts functionality - expand this, will replace buttonBackType
      //=== MODE PATH
      if (this.buttonMode === 'path') {
        router.push({path: '/' + this.buttonDestination});
        return;
      }

      //=== MODE CUSTOM
      if (this.buttonMode === 'custom') {
        this.$emit('buttonCustomEvent');
      }

      //=== MODE HISTORY
      if (this.buttonMode === 'history') {
        router.go(this.buttonDestination);
      }

      //=== MODE BACK TO NAVIGATION
      if (this.buttonMode === 'backToNavigation') {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, true);
        router.push({ name: this.buttonDestination });
        return;
      }

      //=== LEGACY
      // TODO MBU: remove buttonBackType, kept for now for compatibility with new system
      if (this.buttonBackType === 'back') {
        if (this.goDashboardPage.includes(this.pageId)) {
          this.goToDashboard();
        }

        // FIXME MSE: these conditions look strange, check and refactor
        if (this.pageId === 'workout-detail') {
          router.push({name: 'workouts'});
          return;
        }

        if (this.pageId === 'workouts') {
          router.push('/dashboard');
          return;
        }

        if (this.pageId === 'strategy') {
          router.push('/dashboard');
          return;
        }

        if (this.pageId === 'strategy-detail') {
          router.push('/strategy/ambition');
          return;
        }

        if (this.pageId === 'strategy-summary') {
          router.push('/strategy/');
          return;
        }

        if (this.pageId === 'strategy-main-objectives') {
          router.push('/strategy/');
          return;
        }
      } else if (this.buttonBackType === 'none' || this.buttonMode === 'custom') {
        this.$emit(COMPONENT_EVENTS.ACTIVE_VIEW);
        this.$emit('buttonCustomEvent');
      }
    },

    processNavbarDesktopButton() {
      // menu top level open - 2 conditions to prevent open & close with same click
      if (!this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, true);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU_CATEGORY, null);
      } else if (this.dashboardMenuTopLevelOpen) {
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, false);
      }
      if (this.dashboardSubmenuOpen) {
        // submenuopen
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_BACK_TO_NAVIGATION, false);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_SUBMENU, false);
        this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DASHBOARD_MENU, true);
      }
      return false;
    },

    goToDashboard() {
      router.push({name: 'dashboard'});
    },

    closeDrawer() {
      this.$store.commit(STORE_MODULES.GENERAL + '/' + MUTATIONS_CONSTANTS.SET_DRAWER_IS_OPENED, false);
      EVENT_BUS.$emit(GLOBAL_EVENTS.CLOSE_DRAWER);
    },

    showInfoOverlay(event) {
      if(this.infoButtonDestination) {
        router.push({name: this.infoButtonDestination,  params:  this.infoButtonDestinationParams});
      } else {
        event.customProps = {};
        event.customProps.overlayPreventClickOutside = true;
        this.infoVisibility = true;
      }
    },
  }
}
</script>
