<template>
  <div class="vue-navigation-info">
    <prj1020-separator />
    <frm1006-button
      class="vue-navigation-info-btn"
      type="app-button"
      @buttonClickEvent="goToPage('/user-settings')"
    >
      {{ $t('navigation.settings') }}
    </frm1006-button>
  </div>
</template>

<script>
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';

export default {
  name: 'Prj1005NavigationInfo',
  components: {
    Prj1020Separator
  },

  methods: {
    goToPage(target) {
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_DASHBOARD_MENU);
      this.$router.push(target);
    }
  }
}
</script>
