<template>
  <page-layout
    ref="pageLayout"
    :waitForAsyncData="true"
  >
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaptionCustom"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <template v-if="applicant.mgrName">
        <h2 class="vue-onboarding-header">{{ $t('onboarding.person.yourManager') }}</h2>
        <h3 class="vue-onboarding-subheader">{{ $t('onboarding.person.yourManagerNote') }}</h3>
        <div class="wcm-contact wcm-contact-personal">
          <div class="wcm-contact-content">
            <p class="wcm-contact-title">{{ applicant.mgrName }}</p>
            <p
              v-if="applicant.mgrEmail"
              class="wcm-contact-mail"
            >
              <a :href="`mailto:${applicant.mgrEmail}`">{{ applicant.mgrEmail }}</a>
            </p>
            <p
              v-if="applicant.mgrPhone"
              class="wcm-contact-phone"
            >
              <a :href="`tel:${applicant.mgrPhone}`">{{ applicant.mgrPhone }}</a>
            </p>
          </div>
        </div>
      </template>
      <template v-if="applicant.perName">
        <h2 class="vue-onboarding-header">{{ $t('onboarding.person.yourHrManager') }}</h2>
        <h3 class="vue-onboarding-subheader">{{ $t('onboarding.person.yourHrManagerNote') }}</h3>
        <div class="wcm-contact wcm-contact-personal">
          <div class="wcm-contact-content">
            <p class="wcm-contact-title">{{ applicant.perName }}</p>
            <p
              v-if="applicant.perEmail"
              class="wcm-contact-mail"
            >
              <a :href="`mailto:${applicant.perEmail}`">{{ applicant.perEmail }}</a>
            </p>
            <p
              v-if="applicant.perPhone"
              class="wcm-contact-phone"
            >
              <a :href="`tel:${applicant.perPhone}`">{{ applicant.perPhone }}</a>
            </p>
          </div>
        </div>
      </template>
      <template v-if="applicant.rcrtName">
        <h2 class="vue-onboarding-header">{{ $t('onboarding.person.yourRecruiter') }}</h2>
        <h3 class="vue-onboarding-subheader">{{ $t('onboarding.person.yourRecruiterNote') }}</h3>
        <div class="wcm-contact wcm-contact-personal">
          <div class="wcm-contact-content">
            <p class="wcm-contact-title">{{ applicant.rcrtName }}</p>
            <p
              v-if="applicant.rcrtEmail"
              class="wcm-contact-mail"
            >
              <a :href="`mailto:${applicant.rcrtEmail}`">{{ applicant.rcrtEmail }}</a>
            </p>
            <p
              v-if="applicant.rcrtPhone"
              class="wcm-contact-phone"
            >
              <a :href="`tel:${applicant.rcrtPhone}`">{{ applicant.rcrtPhone }}</a>
            </p>
          </div>
        </div>
      </template>
    </template>

    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isLoading"
        :overlay="true"
        :fullScreen="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';

import mxNavigationDefault from '@/mixins/mx-navigation-default';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';
import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import i18n from '@/i18n';
import dataLoader from '@/utils/data-loader';
import logger from '@/utils/logger';

export default {
  name: 'OnboardingContacts',
  components: {
    PageLayout,
    Prj1002Navbar,
  },

  mixins: [
    mxNavigationDefault,
    mxErrorMessage
  ],

  data() {
    return {
      isLoading: true,
      initialized: false,
      hasError: false,
      ERROR_MODAL_TIMEOUT,
      navbarCaptionData: null,
      applicant: {}
    };
  },

  computed: {
    navbarCaptionCustom() {
      return i18n.t('onboarding.contacts')
    },
  },

  watch: {
    isLoading(value) {
      if (!value && this.initialized) {
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    initialized(value) {
      if (value) {
        this.setLoadingState(false);
      }
    }
  },

  mounted() {
    this.getApplicantDetail().then(() => {
      this.initialized = true;
    });
  },

  methods: {
    setLoadingState(value) {
      if(value) {
        this.isLoading = true;
        this.$refs.pageLayout.pageAsyncDataLoadedInProgress();
      } else {
        this.isLoading = false;
        this.$refs.pageLayout.pageAsyncDataLoadedDone();
      }
    },

    goToView(viewName) {
      this.$router.push({name: viewName});
    },

    getApplicantDetail() {
      this.setLoadingState(true);

      return dataLoader.fetchApplicantDetail()
        .then((data) => {
          this.applicant = data;
        })
        .catch(error => {
          logger.error(error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
          if (error.response?.data?.error?.errorCode === 'ERR-ACCESS_DENIED') {
            setTimeout(() => {
              this.$router.push({name: 'login'});
            }, 5000);
          }
        })
        .finally(() => {
          this.setLoadingState(false);
        });
    },
  },
}
</script>
