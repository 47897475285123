<template>
  <page-layout>
    <!--========== NAVBAR =================================-->
    <!--===================================================-->
    <template v-slot:navbar>
      <prj1002-navbar
        :caption="navbarCaption"
        :building="navbarBuilding"
        :buttonType="navbarButtonType"
        :buttonMode="navbarButtonMode"
        :buttonDestination="navbarButtonDestination"
      />
    </template>

    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <div class="vue-b-content">
        <!--=== ACTIONS ===-->
        <div class="vue-b-actions">
          <frm1006-button
            type="navigation"
            class="vue-button-refresh"
            mode="anchor"
            :iconLeft="true"
            :disabled="actionsDisabled('restart')"
            @buttonClickEvent="restartApp"
          >
            {{ $t('userSettings.restartApp') }}
          </frm1006-button>

          <frm1006-button
            type="navigation"
            class="vue-button-cookies"
            mode="button"
            :iconLeft="true"
            :disabled="actionsDisabled('cookies')"
            @buttonClickEvent="showCookieModal"
          >
            {{ $t('userSettings.cookieSettings') }}
          </frm1006-button>

          <frm1006-button
            type="navigation"
            class="vue-button-power"
            mode="anchor"
            :iconLeft="true"
            :disabled="actionsDisabled('logout')"
            @buttonClickEvent="logout"
          >
            {{ $t('userSettings.logout') }}
          </frm1006-button>
        </div>

        <!--=== RESTARTING ===-->
        <template v-if="isRestarting">
          <gen1016-loading-indicator :active="isRestarting" />
          {{ $t('userSettings.restartNow') }}
        </template>

        <!--=== NOTIFICATIONS ===-->
        <div
          v-if="!isRestarting"
          class="vue-b-notifications"
        >
          <h2>{{ $t('userSettings.headlineNotification') }}</h2>

          <gen1016-loading-indicator :active="updatingNotifications" />

          <template v-if="!pushBrowserSupport">
            <gen1006-info-panel
              type="neutral"
              class="vue-disclaimer"
            >
              {{ $t('userSettings.pushNotSupported') }}
            </gen1006-info-panel>
          </template>
          <template v-else>
            <template v-if="pushNotificationsPermission === 'denied'">
              <gen1006-info-panel
                type="neutral"
                class="vue-disclaimer"
              >
                {{ $t('userSettings.notificationsDenied') }}
              </gen1006-info-panel>
            </template>
            <template v-else>
              <template v-if="pushNotificationsSubscribed">
                <gen1006-info-panel
                  type="neutral"
                  class="vue-disclaimer"
                >
                  {{ $t('userSettings.notificationsEnabled') }}
                </gen1006-info-panel>

                <frm1006-button
                  type="primary"
                  :disabled="actionsDisabled('notification-off') || !swRegistration"
                  @buttonClickEvent="togglePushNotifications(false)"
                >
                  {{ $t('userSettings.disableNotifications') }}
                </frm1006-button>
              </template>
              <template v-else>
                <gen1006-info-panel
                  type="neutral"
                  class="vue-disclaimer"
                >
                  {{ $t('userSettings.notificationsDisabled') }}
                </gen1006-info-panel>

                <frm1006-button
                  type="primary"
                  :disabled="actionsDisabled('notification-on') || !swRegistration"
                  @buttonClickEvent="togglePushNotifications(true)"
                >
                  {{ $t('userSettings.enableNotifications') }}
                </frm1006-button>
              </template>
            </template>
          </template>
        </div>
      </div>
    </template>

    <!--========== FOOTER =================================-->
    <!--===================================================-->
    <template v-slot:footer>
      <div class="vue-c-page-footer">
        <prj1020-separator />

        <!-- TODO possibility to use footer in other pages -->
        <span class="vue-version">{{ $t('userSettings.appVersion') }} {{ appVersion }}</span>
      </div>
    </template>
  </page-layout>
</template>

<script>

//=== COMPONENTS
import PageLayout from '@/templates/partials/page-layout';
import mxNavigationDefault from '../../mixins/mx-navigation-default';

import Prj1002Navbar from '@/components/prj1002-navbar/prj1002-navbar';
import Prj1020Separator from '@/components/prj1020-separator/prj1020-separator';
import {mapState, mapGetters} from 'vuex';

//=== UTILITY
import * as MUTATIONS_CONSTANTS from '../../store/constants/mutations';
import * as ACTIONS_CONSTANTS from '../../store/constants/actions';
import * as STORE_MODULES from '../../store/store-modules';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import logger from '../../utils/logger';
import client from '@/utils/data-loader/index';
import utilsGeneral from '@/utils/utils-general';
import {SYNC_STATUS} from '@/constants/app-constants';

export default {
  name: 'UserSettings',
  components: {
    PageLayout,
    Prj1002Navbar,
    Prj1020Separator
  },

  mixins: [
    mxNavigationDefault
  ],

  data() {
    return {
      SYNC_STATUS: SYNC_STATUS,
      syncActivationPeriodicChecker: null,
      syncEmail: '',
      isRestarting: false,
      updatingNotifications: false,
      updatingSync: false,
      tempPetName: '',
      tempWorkoutId: '',
      syncRegisterSuccess: false
    }
  },

  computed: {
    ...mapState('general', [
      'swRegistration',
      'swInstance',
      'pushNotificationsSubscribed',
      'pushNotificationsPermission'
    ]),
    ...mapGetters('general',
      {
        'pushBrowserSupport': GETTERS_CONSTANTS.PUSH_BROWSER_SUPPORT,
      }),

    syncEmailValid() {
      return utilsGeneral.isValidEmail(this.syncEmail);
    },

    appVersion() {
      if (this.$store.state.general.appVersion) {
        return this.$store.state.general.appVersion;
      } else {
        return '';
      }
    }
  },

  watch: {
    swInstance() {
      this.loadPushNotificationsStatus();
    },
  },

  created() {
    this.loadPushNotificationsStatus();
  },

  methods: {
    loadPushNotificationsStatus() {
      if(this.pushBrowserSupport) {
        this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.LOAD_PUSH_NOTIFICATIONS_STATUS)
          .catch(error => {
            logger.error(error);
          })
      }
    },

    togglePushNotifications(status) {
      if (!status) {
        // user wants to disable push, make sure he does not immediately receive overlay with push hint
        this.$store.commit(STORE_MODULES.PERSISTENT_STORAGE + '/' + MUTATIONS_CONSTANTS.POSTPONE_PUSH_NOTIFICATIONS);
      }

      this.updatingNotifications = true;
      this.$store.dispatch(STORE_MODULES.GENERAL + '/' + ACTIONS_CONSTANTS.TOGGLE_PUSH_NOTIFICATIONS, status)
        .catch(error => {
          logger.error(error);
        })
        .finally(() => {
          this.updatingNotifications = false;
        })
    },

    restartApp() {
      if ((window.navigator && window.navigator.onLine) || !window.navigator) {
        this.isRestarting = true;
        // deprecated, but only one really functional on iOS
        window.location.reload(true);
      }
    },

    logout() {
      client.logoutRequest()
        .then(data => {
          logger.info('Logout response: ', data);
        });
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.LOGOUT_USER);
      window.localStorage.removeItem('nastup-localstorage');
      this.$router.push({ name: 'login', params: { autologin: false }});
    },

    showCookieModal() {
      document.getElementsByClassName('cky-consent-container cky-classic-bottom cky-hide')[0].classList.remove('cky-hide');
    },

    actionsDisabled() {
      return this.updatingNotifications || this.isRestarting;
    }
  }
}
</script>
