import Frm1010Datepicker from './frm1010-datepicker';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1010Datepicker);
    }
};

use(Plugin);

export default Plugin;

export { Frm1010Datepicker };
