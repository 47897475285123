import { TWO_WEEKS_IN_MS } from '@/constants/app-constants';
import * as STRING_VALUES from '../string-values';
import * as MUTATIONS_CONSTANTS from '../constants/mutations'
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import * as GETTERS_CONSTANTS from '@/store/constants/getters';
import dataLoader from '@/utils/data-loader';

const state = {
  publicKey: '',
  platform: STRING_VALUES.PLATFORM_OTHER,
  systemVersion: STRING_VALUES.SYSTEM_VERSION_UNKNOWN,
  isLogged: false,
  isStandalone: false,
  browser: '',
  deviceId: null,
  entryPath: '',
  syncStatus: 0,
  syncRegistrationId: null,
  syncDeviceId: null,
  syncToken: null,
  syncConfirmationDeadline: null,
  syncData: {},
  syncReminderTimestamp: null,
  installReminderTimestamp: null,
  forvardinoNewsletterState: null,
  // authorized mode only
  amUserProfile: null,
  isProfileMenuOpened: false,
  // App notifications
  isAppNotificationsMenuOpened: false,
  appNotificationsList: []
};

const mutations = {
  [MUTATIONS_CONSTANTS.SET_PUBLIC_KEY](state, token) {
    state.publicKey = token;
  },
  [MUTATIONS_CONSTANTS.SET_IS_LOGGED](state, loginState) {
    state.isLogged = loginState;
  },
  [MUTATIONS_CONSTANTS.SET_SYNC_STATUS](state, status) {
    state.syncStatus = status;
  },
  [MUTATIONS_CONSTANTS.SET_DEVICE_ID](state, deviceid) {
    state.deviceId = deviceid;
  },
  [MUTATIONS_CONSTANTS.SET_SYNC_REGISTRATION](state, registration) {
    if(!registration) {
      state.syncStatus = 0;
      state.syncRegistrationId = null;
      state.syncDeviceId = null;
      state.syncToken = null;
      state.syncConfirmationDeadline = null;
    } else {
      state.syncStatus = registration.syncStatus;
      state.syncRegistrationId = registration.registrationId;
      state.syncDeviceId = registration.deviceId;
      state.syncToken = registration.deviceToken;
      state.syncConfirmationDeadline = registration.validationDeadline;
    }
  },
  [MUTATIONS_CONSTANTS.SET_SYNC_DATA](state, data) {
    state.syncData = data;
  },
  [MUTATIONS_CONSTANTS.SET_SYNC_REMINDER_TIMESTAMP](state, reminderTimestamp) {
    state.syncReminderTimestamp = reminderTimestamp;
  },
  [MUTATIONS_CONSTANTS.SET_PLATFORM](state, platform) {
    state.platform = platform;
  },
  [MUTATIONS_CONSTANTS.SET_BROWSER](state, browser) {
    state.browser = browser;
  },
  [MUTATIONS_CONSTANTS.SET_IS_STANDALONE](state, isStandalone) {
    state.isStandalone = isStandalone;
  },
  [MUTATIONS_CONSTANTS.SET_SYSTEM_VERSION](state, systemVersion) {
    state.systemVersion = systemVersion;
  },
  [MUTATIONS_CONSTANTS.SET_INSTALL_REMINDER_TIMESTAMP](state, reminderTimestamp) {
    state.installReminderTimestamp = reminderTimestamp;
  },
  [MUTATIONS_CONSTANTS.SET_FORVARDINO_NEWSLETTER_STATE](state, forvardinoNewsletterState) {
    state.forvardinoNewsletterState = forvardinoNewsletterState;
  },
  [MUTATIONS_CONSTANTS.SET_PROFILE_MENU](state, menuState) {
    state.isProfileMenuOpened = menuState;
  },
  // APP NOTIFICATIONS MUTATIONS
  // ===================================================
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU](state, notificationState) {
    state.isAppNotificationsMenuOpened = notificationState;
  },
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS](state, notificationsData) {
    state.appNotificationsList = notificationsData
  },
  [MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_RESET](state) {
    state.appNotificationsList = [];
  },
  // AUTHORIZED MODE ONLY MUTATIONS
  // ===================================================
  [MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE](state, userProfile) {
    state.amUserProfile = userProfile;
  }
};

const actions = {
  [ACTIONS_CONSTANTS.REGISTER_DEVICE_SYNC]({ commit, state }, email) {
    return dataLoader.registerDeviceSync(state.publicKey, email, state.deviceId)
      .then(syncRegistration => {
        commit(MUTATIONS_CONSTANTS.SET_SYNC_REGISTRATION, syncRegistration);
      })
  },
  [ACTIONS_CONSTANTS.REMOVE_DEVICE_SYNC]({ commit, state }) {
    return dataLoader.removeDeviceSync(state.publicKey, state.syncDeviceId, state.syncToken)
      .then(() => {
        commit(MUTATIONS_CONSTANTS.SET_SYNC_REGISTRATION, null);
      })
  },
  [ACTIONS_CONSTANTS.CHECK_DEVICE_SYNC_STATUS]({ commit, state }) {
    return dataLoader.checkDeviceSyncStatus(state.publicKey, state.syncDeviceId, state.syncToken)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_SYNC_STATUS, data.status);
      })
  },
  [ACTIONS_CONSTANTS.GET_SYNC_DATA]({ commit, state }) {
    return dataLoader.getSyncData(state.publicKey,  state.syncRegistrationId, state.syncDeviceId, state.syncToken)
      .then(data => {
        commit(MUTATIONS_CONSTANTS.SET_SYNC_DATA, data);
      })
  },
  [ACTIONS_CONSTANTS.SET_SYNC_STATIC]({ commit, state }, data) {
    return dataLoader.setSyncStatic(state.publicKey,  state.syncRegistrationId, state.syncDeviceId, state.syncToken, data)
      .then(data => {
        let newData = {...state.syncData};
        newData.static = data.json;
        commit(MUTATIONS_CONSTANTS.SET_SYNC_DATA, newData);
      })
  },
  [ACTIONS_CONSTANTS.SET_SYNC_LINKED]({ commit, state }, payload) {
    return dataLoader.setSyncLinked(state.publicKey,  state.syncRegistrationId, state.syncDeviceId, state.syncToken, payload)
      .then(data => {
        let newData = {...state.syncData};
        newData.linked = data.linked;
        commit(MUTATIONS_CONSTANTS.SET_SYNC_DATA, newData);
      })
  },
  [ACTIONS_CONSTANTS.MAKE_SYNC_REMINDER_TIMESTAMP]({ commit }) {
    const reminderTimestamp = Date.now() + TWO_WEEKS_IN_MS;

    commit(MUTATIONS_CONSTANTS.SET_SYNC_REMINDER_TIMESTAMP, reminderTimestamp);
  },
  [ACTIONS_CONSTANTS.MAKE_INSTALL_REMINDER_TIMESTAMP]({ commit }) {
    const reminderTimestamp = Date.now() + TWO_WEEKS_IN_MS;

    commit(MUTATIONS_CONSTANTS.SET_INSTALL_REMINDER_TIMESTAMP, reminderTimestamp);
  },
  [ACTIONS_CONSTANTS.LOGOUT_USER]({ commit }) {
    commit(MUTATIONS_CONSTANTS.SET_PUBLIC_KEY, '');
    commit(MUTATIONS_CONSTANTS.SET_IS_LOGGED, false);
  },
  [ACTIONS_CONSTANTS.TOGGLE_PROFILE_MENU]({state, commit}) {
    let menuState = state.isProfileMenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, menuState = !menuState);

    // close notifications menu if it's opened
    if (state.isAppNotificationsMenuOpened) {
      commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, false);
    }
  },
  // APP NOTIFICATIONS ACTIONS
  // ===================================================
  [ACTIONS_CONSTANTS.TOGGLE_APP_NOTIFICATIONS_MENU]({state, commit}) {
    let notificationState = state.isAppNotificationsMenuOpened;

    commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_MENU, notificationState = !notificationState);

    // close profile menu if it's opened
    if (state.isProfileMenuOpened) {
      commit(MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
    }
  },
  [ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS]({ commit }) {
    return dataLoader.fetchAppNotifications()
      .then(data => {
        const appNotifications = data.notices;
        const notificationsSortedByDate = appNotifications.sort((a, b) => {
          return new Date(b.created.date) - new Date(a.created.date);
        });

        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS, notificationsSortedByDate);
      })
  },
  [ACTIONS_CONSTANTS.APP_NOTIFICATIONS_READ_ALL]({ commit }) {
    return dataLoader.postAppNotificationsReadAll()
      .then(() => {
        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS_RESET);
      })
  },
  [ACTIONS_CONSTANTS.APP_NOTIFICATION_READ]({ state, commit }, id) {
    return dataLoader.postAppNotificationRead(id)
      .then(() => {
        const filteredAppNotifications = state.appNotificationsList.filter((item) => item.id !== id);

        commit(MUTATIONS_CONSTANTS.SET_APP_NOTIFICATIONS, filteredAppNotifications);
      })
  },
  // AUTHORIZED MODE ONLY ACTIONS
  // ===================================================
  [ACTIONS_CONSTANTS.AM_LOAD_AUTHORIZED_MODE_PROFILE]({ commit }) {
    return dataLoader.fetchUserProfile()
      .then(data => {
        commit(MUTATIONS_CONSTANTS.AM_SET_USER_PROFILE, data);
      })
  }
};

const getters = {
  [GETTERS_CONSTANTS.GET_USER_ROLE]: state => role => {
    return state.amUserProfile.roles.includes(role)
  },
  [GETTERS_CONSTANTS.GET_USER_NOTICES]: state => {
    return state.appNotificationsList;
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
