<template>
  <div class="vue-c-stepper">
    <div
      v-for="(step, index) in stepsCount"
      :key="'step-' + index"
      class="vue-step-item"
      :class="stepItemClassObject(step)"
    />
  </div>
</template>

<script>
export default {
  name: 'Prj1040Stepper',
  props: {
    stepsCount: {
      type: Number,
      required: true
    },
    activeStep: {
      type: Number,
      required: true
    }
  },

  methods: {
    stepItemClassObject(step) {
      return {
        'vue-step-item-active': this.activeStep === step
      }
    }
  }
}
</script>
