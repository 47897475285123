<template>
  <page-layout class="vue-m-intro">
    <!--========== CONTENT MAIN ===========================-->
    <!--===================================================-->
    <template v-slot:contentMain>
      <img
        class="vue-zapka-logo"
        alt="Zapka Logo"
        src="../../../public/img/logo/intro-logo-with-branding.svg"
      >

      <p
        class="vue-intro-message"
        v-html="$t('intro.message')"
      />

      <gen1006-info-panel
        v-show="!smsRequestState"
        type="neutral"
      >
        {{ $t('onboarding.smsCodeRequired') }}
      </gen1006-info-panel>

      <frm1001InputField
        v-show="smsRequestState"
        id="code-input"
        ref="codeInput"
        v-model="code"
        name="code"
        class="vue-intro-code"
        :label="$i18n.t('onboarding.code')"
        :tooltip="false"
        :disabled="isAuthorizing"
        @inputKeyDownEvent="authFromInput"
      />
    </template>
    <!--========== CONTENT REST ===========================-->
    <!--===================================================-->
    <template v-slot:contentRest>
      <template v-if="!smsRequestState">
        <frm1006Button
          type="primary"
          :disabled="buttonRequestSMSDisabled"
          @buttonClickEvent="buttonSMSClick"
        >
          {{ $t('onboarding.nextButton') }}
        </frm1006Button>
      </template>
      <template v-if="smsRequestState">
        <frm1006Button
          type="primary"
          :disabled="buttonRequestAuthDisabled"
          @buttonClickEvent="buttonAuthClick"
        >
          {{ $t('onboarding.loginButton') }}
        </frm1006Button>
        <br>
        <br>
        <frm1006-button
          type="secondary"
          :disabled="buttonRequestSMSAgainDisabled"
          @buttonClickEvent="buttonSMSClick"
        >
          {{ $t('onboarding.nextButtonAgain') }}
          <vue-countdown-timer
            :start-time="smsRequestTimestamp"
            :end-time="endedTime"
            :start-label="''"
            :end-label="''"
            :end-text="''"
            :interval="1000"
            label-position="begin"
            @start_callback="startCallBack()"
            @end_callback="endCallBack()"
          >
            <template
              slot="countdown"
              slot-scope="scope"
            >
              (<span>{{ scope.props.minutes }}</span><i>{{ scope.props.minutesTxt }}</i>
              <span>{{ scope.props.seconds }}</span>)
            </template>
          </vue-countdown-timer>
        </frm1006-button>
      </template>
    </template>
    <!--========== FIXED ==================================-->
    <!--===================================================-->
    <template v-slot:fixed>
      <gen1016-loading-indicator
        :active="isAuthorizing"
        :fullScreen="true"
        :overlay="true"
      />
      <gen1009-overlay
        class="vue-is-info-panel"
        :active.sync="hasError"
        :buttonClose="true"
        :closeAfter="ERROR_MODAL_TIMEOUT"
      >
        <gen1006-info-panel
          type="error"
        >
          {{ $t('error.generic') }}
          <div class="vue-message">
            <strong>{{ serverError }}</strong>
          </div>
        </gen1006-info-panel>
      </gen1009-overlay>
    </template>
  </page-layout>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/i18n';
import logger from '@/utils/logger';
import client from '@/utils/data-loader/index';
import mxErrorMessage from '@/mixins/mx-error-message';
import PageLayout from '@/templates/partials/page-layout';

import * as STORE_MODULES from '@/store/store-modules';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import { ERROR_MODAL_TIMEOUT } from '@/constants/app-constants';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';

export default {
  name: 'Login',
  components: {
    PageLayout
  },

  mixins: [
    mxErrorMessage
  ],

  data() {
    return {
      code: '',
      isAuthorizing: false,
      hasError: false,
      buttonRequestSMSAgainDisabled: true,
      ERROR_MODAL_TIMEOUT
    }
  },

  computed: {
    ...mapState('onboarding', {
      uuid: state => state.user.uuid,
      smsRequestTimestamp: state => state.user.smsRequestTimestamp,
      smsRequestState: state => state.user.smsRequestState
    }),

    paramUUID() {
      return this.$route.params.uuid;
    },

    hasUUID() {
      return !!this.uuid || typeof this.paramUUID !== 'undefined';
    },

    getUUID() {
      return (typeof this.paramUUID !== 'undefined') ? this.paramUUID : this.uuid;
    },

    buttonRequestSMSDisabled() {
      return !this.hasUUID;
    },

    buttonRequestSMSText() {
      return this.smsRequestState ? i18n.t('onboarding.loginButton') : i18n.t('onboarding.nextButton');
    },

    buttonRequestAuthDisabled() {
      return this.code.length < 5;
    },

    endedTime() {
      return this.smsRequestTimestamp + 180;
    }
  },

  watch: {
    smsRequestState(value) {
      logger.info('sms request state: ', value);
    },

    smsRequestTimestamp() {
      this.buttonRequestSMSAgainDisabled = this.checkSMSAgainTimeout();
    }
  },

  created() {
    this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED, false);
    this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED_TIMESTAMP, null);
  },

  methods: {
    currentTime() {
      return Math.round(new Date().getTime() / 1000);
    },

    checkSMSAgainTimeout() {
      return (this.currentTime - this.smsRequestTimestamp) <= 180;
    },

    buttonSMSClick() {
      if (this.hasUUID) {
        this.requestSMSFetch();
      }
    },

    requestSMSFetch() {
      this.isAuthorizing = true;
      this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_SMS_TIMESTAMP, this.currentTime());
      this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_SMS_STATE, true);

      let userData = {
        uuid: this.getUUID
      }

      client.authRequest(userData)
        .then(data => {
          logger.info('Requested sms code: ', data);
        })
        .catch(error => {
          logger.error('Requested sms code has failed.', error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.isAuthorizing = false;
        });
    },

    buttonAuthClick() {
      this.requestCodeValidFetch();
    },

    requestCodeValidFetch() {
      this.isAuthorizing = true;

      let userData = {
        uuid: this.getUUID,
        code: this.code
      }

      client.authRequest(userData)
        .then(data => {
          logger.info('Requested sms code: ', data);
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_USER_UUID, this.getUUID);
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_SMS_STATE, false);
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED, true);
          this.$store.commit(STORE_MODULES.ONBOARDING + '/' + MUTATIONS_CONSTANTS.SET_ONBOARDING_REQUEST_USER_AUTHORIZED_TIMESTAMP, this.currentTime());
          this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.FETCH_APP_NOTIFICATIONS);
          this.$router.push({name: 'dashboard'});
        })
        .catch(error => {
          logger.error('Requested sms code has failed.', error);
          this.serverError = this.getErrorMessageDetail(error.response?.data?.error?.errorCode);
          this.$nextTick(() => {
            this.hasError = true
          });
        })
        .finally(() => {
          this.isAuthorizing = false;
        });
    },

    authFromInput(value, event) {
      if (event.key === 'Enter') {
        this.buttonAuthClick();
      }
    },

    startCallBack() {
      this.buttonRequestSMSAgainDisabled = true;
    },

    endCallBack() {
      this.buttonRequestSMSAgainDisabled = false;
    }
  }
}
</script>
