<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
const TRACKING = Object.freeze({
    enabled: 'enabled',
    disabled: 'disabled',
    anonymous: 'anonymous'
});
const TRACK_ALL = 'all';

//============ EXPORT ==================================//
//======================================================//
export default {
    props: {
        tracking: {
            default: TRACKING.enabled,
            type: String
        },
        trackingId: String,
        trackEvents: {
            default: () => [TRACK_ALL],
            type: Array
        }
    },
    computed: {
        isTrackingEnabled() {
            return !!this.$tracking;
        }
    },
    methods: {
        /**
         * @param component    String component ID or name
         * @param action       String event name that occurred
         * @param label        Element label or name
         * @param value        Element value
         * @param trackEvents  String[] events to track, eg. ["change", "blur"] or ["all"]
         */
        track(component, action, label, value, trackEvents) {
            if (
                this.isTrackingEnabled &&
                Array.isArray(trackEvents) &&
                (this.trackEvents.includes(TRACK_ALL) || this.trackEvents.includes(action))
            ) {
                if (this.tracking === TRACKING.enabled) {
                    this.$tracking.track(component, action, label, value);
                } else if (this.tracking === TRACKING.anonymous) {
                    this.$tracking.trackAnonymous(component, action);
                }
            }
        }
    }
};
</script>
