<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-table-responsive">
        <div class="vue-b-table">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script itemType="application/javascript">
export default {
    name: 'Tbl1007TableResponsive',
    props: {
        id: String
    },
    computed: {},
    methods: {}
};
</script>
