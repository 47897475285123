import * as apiKeys from './api-keys';
import * as moduleConfig from './module-config';
import * as webServices from './web-services';

// FIXME MSE: improve this, we then use envConfig.default. call (could be shorter)
export default {
  apiKeys,
  moduleConfig,
  webServices
}
