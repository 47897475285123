import Frm1012CurrencyInput from './frm1012-currency-input';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Frm1012CurrencyInput);
    }
};

use(Plugin);

export default Plugin;

export { Frm1012CurrencyInput };
