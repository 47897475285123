<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <section :id="id" class="vue-component vue-c-section" :class="classObject">
        <!-- TODO MBU: accessibility -->
        <div v-if="hasHeader" class="vue-b-section-header">
            <!--========== HEADER CUSTOM ==========================-->
            <!--===================================================-->
            <slot name="headerCustom"></slot>

            <!--========== HEADER REGULAR =========================-->
            <!--===================================================-->
            <template v-if="!headerCustomHasContent">
                <slot name="header"></slot>
                <div v-if="headerSlotPrimaryHasContent" class="vue-b-slot-primary">
                    <slot name="headerSlotPrimary"></slot>
                </div>
                <div v-if="headerSlotSecondaryHasContent" class="vue-b-slot-secondary">
                    <slot name="headerSlotSecondary"></slot>
                </div>
                <frm1006-button
                    v-if="collapsible"
                    type="internal"
                    :title="buttonToggleVisibilityCaption"
                    class="vue-ci-button-toggle-visibility"
                    @buttonClickEvent="buttonToggleVisibilityClick"
                >
                    {{ buttonToggleVisibilityCaption }}
                </frm1006-button>
            </template>
        </div>

        <div v-if="hasContent" class="vue-b-section-content">
            <slot name="content"></slot>
        </div>

        <div v-if="footerHasContent" class="vue-b-section-footer">
            <slot name="footer"></slot>
        </div>
    </section>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Localization from '../../mixins/localization';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1015Section',
    components: {
        Frm1006Button
    },
    mixins: [Localization],
    props: {
        collapsible: Boolean,
        collapsed: Boolean,
        buttonToggleVisibilityExpandCaption: String,
        buttonToggleVisibilityCollapseCaption: String,
        id: String
    },
    data() {
        return {
            collapsedData: this.collapsed
        };
    },
    computed: {
        classObject() {
            return {
                'vue-has-header': this.hasHeader,
                'vue-has-header-custom': this.headerCustomHasContent,
                'vue-has-header-slot-primary': !this.headerCustomHasContent && this.headerSlotPrimaryHasContent,
                'vue-has-header-slot-secondary': !this.headerCustomHasContent && this.headerSlotSecondaryHasContent,
                'vue-has-content': this.hasContent,
                'vue-has-footer': this.footerHasContent,
                'vue-is-collapsible': this.collapsible,
                'vue-is-collapsed': this.collapsible && this.collapsedData,
                'vue-is-expanded': this.collapsible && !this.collapsedData
            };
        },
        hasHeader() {
            return (
                this.headerHasContent ||
                this.headerSlotPrimaryHasContent ||
                this.headerSlotSecondaryHasContent ||
                this.headerCustomHasContent
            );
        },
        headerCustomHasContent() {
            return !!this.$slots.headerCustom;
        },
        headerHasContent() {
            return !!this.$slots.header;
        },
        headerSlotPrimaryHasContent() {
            return !!this.$slots.headerSlotPrimary;
        },
        headerSlotSecondaryHasContent() {
            return !!this.$slots.headerSlotSecondary;
        },
        hasContent() {
            return !!this.$slots.content;
        },
        footerHasContent() {
            return !!this.$slots.footer;
        },
        buttonToggleVisibilityCaption() {
            if (this.collapsedData) {
                return this.buttonToggleVisibilityExpandCaption
                    ? this.buttonToggleVisibilityExpandCaption
                    : this.i18n('sectionToggleVisibilityExpand');
            }

            return this.buttonToggleVisibilityCollapseCaption
                ? this.buttonToggleVisibilityCollapseCaption
                : this.i18n('sectionToggleVisibilityCollapse');
        }
    },
    watch: {
        collapsed(value) {
            // watch prop, if it changes set data
            this.collapsedData = value;
        },
        collapsedData(value) {
            this.$emit('collapsedChangeEvent', value);
            this.$emit('update:collapsed', value);
        }
    },
    methods: {
        buttonToggleVisibilityClick() {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.collapsedData = !this.collapsedData;
            this.$emit('buttonToggleVisibilityClickEvent');
        }
    }
};
</script>
