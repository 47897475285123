import Gen1006InfoPanel from './gen1006-info-panel';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1006InfoPanel);
    }
};

use(Plugin);

export default Plugin;

export { Gen1006InfoPanel };
