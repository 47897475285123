export const GENERAL = 'general';
export const PERSISTENT_STORAGE = 'persistentStorage';
export const DATA_STORE = 'dataStore';
export const ARTICLES = 'articles';
export const USER = 'user';
export const DASHBOARD = 'dashboard';
export const POPUP = 'popup';
export const EVENTS = 'events';
export const RECRUITMENT = 'recruitment';
export const RESTAURANTS = 'restaurants';
export const FORVARDINO = 'forvardino';
export const WORKOUTS = 'workouts';
export const COMPETITION = 'competition';
export const CLIENT_SATISFACTION = 'clientSatisfaction';
export const FAULT_REPORTING = 'fault_reporting';
export const SERVICES = 'services';
export const WHERE_TO_FIND = 'where_to_find';
export const NEWS = 'news';
export const ARTICLE = 'article';
export const TUTORIAL = 'tutorial';
export const PAGE = 'page';
export const TRIO = 'trio';
export const WEEK_ACTIONS = 'weekActions';
export const SURVEY = 'survey';
export const PARKING = 'parking';
export const FOOD_ESHOP = 'foodEshop';
export const BENEFITS = 'benefits';
export const IDEAS_SENDING = 'ideas_sending';
export const ONBOARDING = 'onboarding';
