<template>
  <div
    v-show="isVisible"
    class="vue-c-navbar-profile"
    :class="{ 'vue-is-clickable': hasIdentity,
              'vue-has-dropdown-open': isProfileMenuOpened
    }"
    @click="toggleMenu"
  >
    <template v-if="loggingIn">
      <gen1016-loading-indicator />
    </template>
    <template v-else>
      <div
        class="vue-navigation-avatar"
      >
        <div
          v-if="hasProfilePhoto"
          class="vue-photo"
          :style="styleObject"
        />
      </div>
    </template>
    <transition
      name="vue-anim-move-from-top"
      appear
    >
      <div
        v-if="isProfileMenuOpened"
        class="vue-b-dropdown-menu"
        :class="dropdownMenuClassObject"
      >
        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-profile vue-user-name"
        >
          {{ profileName }}<br>
        </Frm1006Button>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-settings"
          @buttonClickEvent="goToSettings()"
        >
          {{ $t('navigation.settings') }}
        </Frm1006Button>

        <div class="vue-dropdown-divider"></div>

        <Frm1006Button
          type="internal"
          :icon-left="true"
          class="vue-dropdown-item vue-icon vue-is-logout"
          @buttonClickEvent="goToLogout()"
        >
          {{ $t('userSettings.logout') }}
        </Frm1006Button>
      </div>
    </transition>

    <transition
      name="vue-anim-fade"
      appear
    >
      <div
        v-if="isProfileMenuOpened"
        class="vue-navbar-overlay"
      />
    </transition>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import * as MUTATIONS_CONSTANTS from '@/store/constants/mutations';
import * as STORE_MODULES from '@/store/store-modules';
import * as ACTIONS_CONSTANTS from '@/store/constants/actions';
import router from '@/router';

import mxDetectDesktop from '@/mixins/mx-detect-desktop';

export default {
  name: 'Prj1002NavbarProfile',

  mixins: [mxDetectDesktop],

  data() {
    return {
      loggingIn: false,
      hasIdentity: true
    }
  },

  computed: {
    ...mapState('user', [
      'isProfileMenuOpened'
    ]),

    ...mapState('onboarding', {
      firstName: state => state.user.firstName,
      lastName: state => state.user.lastName
    }),

    profileName() {
      return this.firstName && this.lastName ? this.firstName + ' ' + this.lastName : '';
    },

    hasProfilePhoto() {
      return false;
    },

    styleObject() {
      if (this.hasProfilePhoto) {
        return {
          'background-image': 'url("' + this.profilePhotoUrl + '")'
        }
      } else {
        return false;
      }
    },

    dropdownMenuClassObject() {
      return {
        'vue-is-active': this.isProfileMenuOpened
      }
    },

    currentRoute() {
      return this.$route.name;
    },

    isVisible() {
      return this.isDesktopLayout || ['dashboard'].includes(this.currentRoute);
    }
  },

  methods: {
    hexToBase64(str) {
      return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')));
    },

    toggleMenu() {
      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.TOGGLE_PROFILE_MENU);
    },

    goToProfile() {
      if (!this.hasIdentity) {
        return false;
      }

      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
      router.push({ name: 'profile' });
    },

    goToSettings() {
      this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
      router.push({ name: 'user-settings' });
    },

    goToLogout() {
      if (!this.hasIdentity) {
        return false;
      }

      this.$store.dispatch(STORE_MODULES.USER + '/' + ACTIONS_CONSTANTS.LOGOUT_USER)
        .then(() => {
          this.$store.commit(STORE_MODULES.USER + '/' + MUTATIONS_CONSTANTS.SET_PROFILE_MENU, false);
          window.localStorage.removeItem('nastup-localstorage');
        })
        .finally(() => {
          this.$router.push({ name: 'login', params: { autologin: false }});
        });
    }
  }
}
</script>
