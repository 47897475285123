import Gen1011ContextContainerMenu from './gen1011-context-container-menu';
import Gen1011ContextContainerMenuItem from './gen1011-context-container-menu-item';

import { use, registerComponent } from '../../../utils/plugins';

const Plugin = {
    install(Vue) {
        registerComponent(Vue, Gen1011ContextContainerMenu);
        registerComponent(Vue, Gen1011ContextContainerMenuItem);
    }
};

use(Plugin);

export default Plugin;

export { Gen1011ContextContainerMenu, Gen1011ContextContainerMenuItem };
