<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-input-field" :class="classObjectComputed">
        <div class="vue-b-form-field">
            <label
                v-if="label"
                :id="idComputed + ID_EXTENSIONS.LABEL"
                :for="idComputed + ID_EXTENSIONS.INPUT"
                class="vue-label"
                >{{ label }}</label
            >
            <input
                :id="inputId"
                ref="input"
                v-model="valueComputed"
                :type="inputType"
                class="vue-input"
                :name="name"
                :readonly="readonly"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :aria-labelledby="labeledByComputed"
                :aria-describedby="describedByComputed"
                @click="inputClick($event.target.value, $event)"
                @keydown="inputKeyDown($event.target.value, $event)"
                @keyup="inputKeyUp($event.target.value, $event)"
                @input="inputInput($event.target.value, $event)"
                @change="inputChange($event.target.value, $event)"
                @focus="inputFocus($event.target.value, $event)"
                @blur="inputBlur($event.target.value, $event)"
            />
            <div v-if="decorator" class="vue-decorator"></div>
            <frm1006-button
                v-if="hasButtonClear"
                ref="buttonClear"
                type="internal"
                class="vue-ci-button-clear"
                :title="i18n('globalInputButtonClear')"
                :tabindex="-1"
                :preventLosingFocus="true"
                @buttonClickEvent="buttonClearClick"
            >
                {{ i18n('globalInputButtonClear') }}
            </frm1006-button>
        </div>

        <gen1010-information-tooltip
            v-if="tooltipComputed"
            ref="tooltip"
            :expanded.sync="tooltipExpandedData"
            :state="state"
            :disabled="tooltipDisabled"
            :content="tooltipContent"
            :contentId="idComputed + ID_EXTENSIONS.TOOLTIP_CONTENT"
            :whiteList="tooltipWhiteListComputed"
            :boundComponentActive="componentIsActive"
            :boundComponentPreventLosingFocus="tooltipPreventLosingFocus"
            class="vue-ci-tooltip"
        />
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Gen1010InformationTooltip from '../../gen/gen1010-information-tooltip/gen1010-information-tooltip';
import Frm1006Button from '../../frm/frm1006-button/frm1006-button';

//=== MIXINS
import Component from '../../mixins/component';
import ButtonClear from '../../mixins/buttonClear';
import Tooltip from '../../mixins/tooltip';
import Localization from '../../mixins/localization';
import DigitalTracking from '../../mixins/digital-tracking';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1001';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1001InputField',
    components: {
        Gen1010InformationTooltip,
        Frm1006Button
    },
    mixins: [Component, ButtonClear, Tooltip, Localization, DigitalTracking],
    model: {
        prop: 'value',
        event: 'inputUpdateEvent'
    },
    props: {
        name: String,
        state: {
            default: 'info',
            type: String,
            validator: value => {
                return config.formElementStates.includes(value);
            }
        },
        required: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        label: String,
        labeledBy: String,
        describedBy: String,
        value: {
            default: '',
            type: String
        },
        placeholder: String,
        autocomplete: String,
        liveUpdate: {
            default: () => {
                return config.inputLiveUpdate;
            },
            type: Boolean
        },
        selectValueOnFocus: Boolean,
        //=== INPUT TYPE
        inputType: {
            type: String,
            default: 'text'
        },
        //=== ADDITIONAL ELEMENTS
        decorator: Boolean,
        //=== TOOLTIP
        focusOnTooltipOpen: {
            default: true,
            type: Boolean
        },
        tooltipPreventLosingFocus: {
            default: true,
            type: Boolean
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            valueData: this.value,
            componentIsActive: false,
            focused: false
        };
    },
    computed: {
        //========= GENERAL ==========================//
        //============================================//
        classObject() {
            return [
                'vue-is-' + this.state,
                {
                    'vue-is-required': this.required,
                    'vue-is-readonly': this.readonly,
                    'vue-is-disabled': this.disabled,
                    'vue-is-set': !this.notSet,
                    'vue-is-not-set': this.notSet,
                    'vue-has-label': this.label,
                    'vue-has-decorator': this.decorator,
                    'vue-is-component-active': this.componentIsActive,
                    'vue-is-focused': this.focused
                }
            ];
        },
        // TODO REVIEW: better naming, classObject is also computed
        classObjectComputed() {
            return [...this.classObject, ...this.classObjectMixinTooltip, ...this.classObjectMixinButtonClear];
        },
        // TODO REVIEW: better naming, classObject is also computed
        valueComputed: {
            get() {
                return this.valueData;
            },
            set(value) {
                this.valueData = value;
                if (this.liveUpdate) {
                    this.$emit('inputUpdateEvent', value); // event for v-model
                }
            }
        },
        //========= ID & ACCESSIBILITY ===============//
        //============================================//
        generateAutoId() {
            return !!this.label || this.tooltipHasContent;
        },
        inputId() {
            if (this.generateAutoId) {
                return this.idComputed + this.ID_EXTENSIONS.INPUT;
            }

            return null;
        },
        labeledByComputed() {
            if (this.label && !this.labeledBy) {
                return this.idComputed + this.ID_EXTENSIONS.LABEL;
            }

            return this.labeledBy;
        },
        describedByComputed() {
            if (!this.describedBy && this.tooltipHasContent) {
                return this.idComputed + this.ID_EXTENSIONS.TOOLTIP_CONTENT;
            }

            return this.describedBy;
        },
        //============ OTHER ===================================//
        //======================================================//
        notSet() {
            return this.valueData === '';
        }
    },
    watch: {
        value(value) {
            this.valueData = value;
        },
        tooltipExpandedData() {
            this.setComponentActiveState();
            if (this.focusOnTooltipOpen && !this.componentIsActive && this.tooltipExpandedData) {
                this.$refs.input.focus();
            }
        },
        componentIsActive(value) {
            this.$emit('componentIsActiveEvent', value);
        },
        focused(value) {
            this.$emit('inputFocusStateEvent', value);
        },
        tooltipWhiteListInitial(value) {
            this.$emit('tooltipWhiteListInitial', value);
        }
    },
    mounted() {
        this.tooltipExpandedData = this.tooltipExpanded;
        if (this.tooltipWhiteListInitialInit) {
            this.setTooltipWhiteListInitial();
        }
    },
    methods: {
        //=== GENERAL
        inputSetFocus() {
            this.$refs.input.focus();
        },
        inputSetBlur() {
            this.$refs.input.blur();
        },
        setComponentActiveState() {
            if (!this.readonly) {
                this.componentIsActive = document.activeElement === this.$refs.input;
            }
        },
        setInputFocusState() {
            this.focused = document.activeElement === this.$refs.input;
        },
        //=== EVENTS
        inputClick(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputClickEvent', value, event);
        },
        inputKeyDown(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputKeyDownEvent', value, event);
        },
        inputKeyUp(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputKeyUpEvent', value, event);
        },
        inputChange(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputChangeEvent', value, event);

            if (!this.liveUpdate) {
                this.$emit('inputUpdateEvent', value); // event for v-model
            }

            this.track(this.trackingId, 'change', this.name, this.value, this.trackEvents);
        },
        inputInput(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputInputEvent', value, event);
        },
        inputFocus(value, event) {
            // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
            this.$emit('inputFocusEvent', value, event);
            // states
            this.setComponentActiveState();
            this.setInputFocusState();
            // tooltip
            if (
                this.tooltipComputed &&
                !this.tooltipExpanded &&
                (this.tooltipOpenOnFocus === 'all' ||
                    (this.tooltipOpenOnFocus === 'invalidOnly' && this.state === 'invalid'))
            ) {
                this.$refs.tooltip.open();
            }
            // select value on focus
            if (this.selectValueOnFocus) {
                this.$refs.input.select();
            }
            this.track(this.trackingId, 'focus', this.name, this.value, this.trackEvents);
        },
        // TODO REVIEW: extract event constants into separate file, it will be also importable for developer
        inputBlur(value, event) {
            this.$emit('inputBlurEvent', value, event);
            this.setComponentActiveState();
            this.setInputFocusState();
            this.track(this.trackingId, 'blur', this.name, this.value, this.trackEvents);
        },

        //=== TOOLTIP
        setTooltipWhiteListInitial() {
            this.tooltipWhiteListInitial = [];

            // input
            this.tooltipWhiteListInitial.push(this.$refs.input);

            // button clear
            if (this.$refs.buttonClear !== undefined) {
                let buttonClearElements = this.$refs.buttonClear.$el.querySelectorAll('*');
                for (let element of buttonClearElements) {
                    this.tooltipWhiteListInitial.push(element);
                }
            }
        }
    }
};
</script>
