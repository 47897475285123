<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-button-container" :class="classObject">
        <div class="vue-buttons">
            <slot></slot>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
export default {
    name: 'Frm1031ButtonContainer',
    props: {
        id: {
            default: null,
            type: String
        }
    },
    computed: {
        classObject() {
            return {
                'vue-is-empty': this.$slots.default === undefined
            };
        }
    }
};
</script>
