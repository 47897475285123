import * as components from './components';
import config, { setOptionsConfig } from './config';
import localization, { setOptionsLocalization } from './localization';
import { use, registerComponentProgrammatic } from './utils/plugins';

const VueFramework = {
    install(Vue, options = {}) {
        // Options
        setOptionsConfig(Object.assign(config, options.config));
        setOptionsLocalization(Object.assign(localization, options.localization));
        // Components
        // if there is no components param in config, load all components specified in components/index
        // else load only those components specified there
        if (!config.hasOwnProperty('components')) {
            for (let componentKey in components) {
                Vue.use(components[componentKey]);
            }
        } else {
            for (let componentKey in components) {
                if (config.components.indexOf(componentKey) !== -1) {
                    Vue.use(components[componentKey]);
                }
            }
        }
        // Config component
        const VueFrameworkProgrammatic = {
            setOptionsConfig(optionsConfig) {
                if (optionsConfig) {
                    setOptionsConfig(Object.assign(config, optionsConfig));
                }
            },
            setOptionsLocalization(optionsLocalization) {
                if (optionsLocalization) {
                    setOptionsLocalization(Object.assign(localization, optionsLocalization));
                }
            }
        };
        registerComponentProgrammatic(Vue, '$vueFramework', VueFrameworkProgrammatic);
    }
};

use(VueFramework);

export default VueFramework;
