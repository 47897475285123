<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="id" class="vue-component vue-c-address" :class="classObject">
        <div class="vue-b-street">
            <span class="street">{{ street }}</span>
        </div>
        <div class="vue-b-city-zip">
            <span class="city-zip">{{ cityZip }}</span>
        </div>
        <div class="vue-b-country">
            <span class="country">{{ country }}</span>
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Gen1019Address',
    props: {
        street: String,
        cityZip: String,
        country: String,
        id: String
    },
    computed: {
        classObject() {
            return [
                {
                    'vue-has-street': this.street,
                    'vue-has-city-zip': this.cityZip,
                    'vue-has-country': this.country
                }
            ];
        }
    }
};
</script>
