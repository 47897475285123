<template>
  <div class="vue-c-separator" />
</template>

<script>
// TODO: MSE & JNO: how to solve super simple components - CSS components only / something else?
export default {
  name: 'Prj1020Separator'
}
</script>
