<template>
  <div class="vue-c-dashboard-buttons">
    <div
      v-for="(group, index) in dashboardGroupButtons"
      :key="index"
      class="vue-dashboard-tile-group-desktop"
      :class="classObjectGroupButtons(group.items)"
      @click="groupButtonClickEvent(group, $event)"
    >
      <div
        v-for="groupItem in group.items"
        :key="groupItem.id"
        class="vue-dashboard-button"
      >
        <frm1006-button
          type="tile"
          importance="normal"
        >
          <template v-slot:iconLeft>
            <div
              class="vue-tile-icon"
              :class="generateTileIcon(groupItem)"
              :style="generateTileIconBackground(groupItem)"
            />
          </template>
        </frm1006-button>

        <prj1044-dashboard-tile-sticker
          v-if="groupItem.sticker"
          :sticker="groupItem.sticker"
          :compact="true"
        />
      </div>
    </div>
    <div
      v-for="item in orderedDashboardButtons"
      :key="item.id"
      class="vue-dashboard-tile-desktop"
    >
      <div class="vue-dashboard-button">
        <frm1006-button
          type="tile"
          importance="normal"
          @buttonClickEvent="goToRoute(item.routeName)"
        >
          <template v-slot:iconLeft>
            <div
              class="vue-tile-icon"
              :class="generateTileIcon(item)"
              :style="generateTileIconBackground(item)"
            />
          </template>
          {{ item.nameShort }}
        </frm1006-button>

        <prj1044-dashboard-tile-sticker
          v-if="item.sticker"
          :sticker="item.sticker"
        />
      </div>
    </div>
    <gen1009-overlay
      :active.sync="showGroupMenu"
      :positionCustom="contextContainerPositions"
    >
      <div
        class="vue-b-group-menu-items"
      >
        <div
          v-for="groupItem in groupMenu.items"
          :key="groupItem.id"
          class="vue-dashboard-tile-desktop"
        >
          <div class="vue-dashboard-button">
            <frm1006-button
              type="tile"
              importance="normal"
              @buttonClickEvent="goToRoute(groupItem.routeName)"
            >
              <template v-slot:iconLeft>
                <div
                  class="vue-tile-icon"
                  :class="generateTileIcon(groupItem)"
                  :style="generateTileIconBackground(groupItem)"
                />
              </template>
              {{ groupItem.nameShort }}
            </frm1006-button>

            <prj1044-dashboard-tile-sticker
              v-if="groupItem.sticker"
              :sticker="groupItem.sticker"
            />
          </div>
        </div>
      </div>
    </gen1009-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as envConfig from 'env-config';
import router from '@/router';
import mxDetectDesktop from '@/mixins/mx-detect-desktop';
import Prj1044DashboardTileSticker from '@/components/prj1044-dashboard-tile-sticker/prj1044-dashboard-tile-sticker';

export default {
  name: 'Prj1042DashboardButtons',
  components: {
    Prj1044DashboardTileSticker
  },
  mixins: [
    mxDetectDesktop
  ],

  data() {
    return {
      showGroupMenu: false,
      groupMenu: false,
      contextContainerPositions: false
    }
  },

  computed: {
    ...mapState('general', [
      'appDestination',
      'dashboardButtons',
      'dashboardGroupButtons',
    ]),
    orderedDashboardButtons() {
      const buttonsArray = [...this.dashboardButtons];
      const uniqueButtonsArray = buttonsArray.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return index === buttonsArray.findIndex(obj => {
          return JSON.stringify(obj) === _thing;
        });
      });

      return uniqueButtonsArray.sort((a, b) => parseInt(a.orderingDashboard) - parseInt(b.orderingDashboard));
    },
  },

  methods: {
    generateTileIcon(item) {
      if (item.cssClass.length > 0 && !item.iconCustom.length > 0) {
        return item.cssClass;
      }

      else if (item.iconCustom.length > 0) {
        return 'vue-icon-custom';
      }

      return 'vue-icon-' + item.iconDashboard;
    },

    generateTileIconBackground(item) {
      if (item.iconCustom.length > 0) {
        return {
          'background-image': `url(${envConfig.default.webServices.BASE_API_URL}${item.iconCustom})`
        }
      }
    },

    goToRoute(routeName) {
      const routeToGo = {};

      if (routeName.startsWith('/')) {
        routeToGo.path = routeName;
      } else {
        routeToGo.name = routeName;
      }

      if (!this.categoryId && this.postId) {
        routeToGo.params = { id: this.postId };
      }

      else if(this.categoryId) {
        routeToGo.query = { categoryId: this.categoryId };
      }

      router.push(routeToGo);
    },

    classObjectGroupButtons(group) {
      return [
        {
          'vue-has-2': group.length === 2,
          'vue-has-4': group.length > 2,
        }
      ];
    },

    groupButtonClickEvent(group, event) {
      this.groupMenu = group;
      this.showGroupMenu = true;

      event.customProps = {};
      event.customProps.overlayPreventClickOutside = true;

      if (this.isDesktopLayout) {
        this.contextContainerPositions = {
          x: event.pageX,
          y: event.pageY,
          mode: 'top-left'
        }
      } else {
        this.contextContainerPositions = false
      }
    }
  }
}
</script>
