<!-- =========================================================== -->
<!-- ///////////////////////// RENDER ////////////////////////// -->
<!-- =========================================================== -->
<template>
    <div :id="idComponent" class="vue-component vue-c-split-button" :class="classObject">
        <!--========== BUTTONS ================================-->
        <!--===================================================-->
        <div class="vue-b-actions">
            <!--=== DEFAULT ===-->
            <frm1006-button
                ref="buttonDefault"
                :type="type"
                :importance="importance"
                :disabled="disabled"
                :title="buttonDefaultCaption"
                :mode="buttonDefaultMode"
                :link="buttonDefaultLink"
                class="vue-ci-button-default"
                @buttonClickEvent="buttonDefaultClick"
            >
                {{ buttonDefaultCaption }}
            </frm1006-button>

            <!--=== DROPDOWN ===-->
            <frm1033-dropdown-button
                ref="buttonDropdown"
                :options="optionsComputed"
                :type="type"
                :importance="importance"
                :disabled="disabled"
                :title="buttonDropdownCaption"
                :expanded.sync="expandedData"
                :caption="buttonDropdownCaption"
                :contextContainerWhiteList="contextContainerWhiteList"
                class="vue-ci-button-dropdown"
                @buttonDropdownToggleClickEvent="buttonDropdownClick"
                @contextContainerMenuItemSelectEvent="contextContainerMenuItemSelect"
            />
        </div>
    </div>
</template>

<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ IMPORT ==================================//
//======================================================//

//=== GEN
import Frm1006Button from '../frm1006-button/frm1006-button';
import Frm1033DropdownButton from '../frm1033-dropdown-button/frm1033-dropdown-button';

//=== MIXINS
import Component from '../../mixins/component';

//=== MISC
import config from '../../../config';

//============ CONSTANTS ===============================//
//======================================================//
let COMPONENT_ID = 'frm1008';

//============ EXPORT ==================================//
//======================================================//
export default {
    name: 'Frm1008SplitButton',
    components: {
        Frm1006Button,
        Frm1033DropdownButton
    },
    mixins: [Component],
    props: {
        options: {
            type: Array,
            required: true
        },
        type: {
            default: config.buttonTypes[0],
            type: String,
            validator: value => {
                return config.buttonTypes.includes(value);
            }
        },
        importance: {
            default: config.buttonImportances[0],
            type: String,
            validator: value => {
                return config.buttonImportances.includes(value);
            }
        },
        expanded: Boolean,
        disabled: Boolean,
        buttonDefaultCaption: {
            type: String,
            required: true
        },
        buttonDefaultMode: {
            default: 'button',
            type: String,
            validator: value => {
                return ['button', 'anchor'].indexOf(value) !== -1;
            }
        },
        buttonDefaultLink: String,
        buttonDefaultDisabled: Boolean,
        buttonDropdownCaption: String,
        buttonDropdownDisabled: Boolean,
        //=== CONTEXT CONTAINER
        contextContainerWhiteList: {
            default: () => {
                return [];
            },
            type: Array
        },
        //=== OTHER
        idPrefix: {
            default: COMPONENT_ID,
            type: [String, Object]
        }
    },
    data() {
        return {
            expandedData: this.expanded
        };
    },
    computed: {
        classObject() {
            return [
                {
                    [`vue-is-${this.type}`]: this.type,
                    [`vue-is-${this.importance}`]: this.importance,
                    [`vue-is-${this.mode}`]: this.mode,
                    'vue-is-disabled': this.disabled,
                    'vue-is-expanded': this.expandedData
                }
            ];
        },
        optionsComputed() {
            // TODO MBU: do deep copy correctly - prepare methods in utilsGeneral
            let optionsStringified = JSON.stringify(this.options);
            let optionsComputed = JSON.parse(optionsStringified);

            for (let i = 0; i < optionsComputed.length; i++) {
                let option = optionsComputed[i];

                if (!option.mode) {
                    optionsComputed[i].mode = 'simple';
                }

                if (!option.action) {
                    optionsComputed[i].action = 'default';
                }

                if (option.component) {
                    optionsComputed[i].component = this.options[i].component;
                }
            }

            return optionsComputed;
        }
    },
    watch: {
        expanded(value) {
            this.expandedData = value;
        },
        expandedData(value) {
            this.$emit('update:expanded', value);
            this.$emit('contextContainerExpandedChangeEvent', value);
        }
    },
    methods: {
        //========= EVENTS ===========================//
        //============================================//
        buttonDefaultClick(event) {
            this.$emit('buttonDefaultClickEvent', event);
        },
        buttonDropdownClick(event) {
            this.$emit('buttonDropdownClickEvent', event);
        },
        contextContainerMenuItemSelect(item, itemIndex) {
            this.$emit('contextContainerMenuItemSelectEvent', item, itemIndex);
        }
    }
};
</script>
