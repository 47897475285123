<!-- =========================================================== -->
<!-- /////////////////////// JAVASCRIPT //////////////////////// -->
<!-- =========================================================== -->
<script type="application/javascript">
//============ OPTIONS =================================//
//======================================================//
/**
 * Mixin provides method to detect absolutely position element placement in window and find, whether
 * is rendered over the viewport.
 */
const POSITION_TOP = 'top';
const POSITION_BOTTOM = 'bottom';
const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

//============ EXPORT ==================================//
//======================================================//
export default {
    methods: {
        getPositionRelativeTo(domElement, currentPosition) {
            let boundingRect = domElement.getBoundingClientRect();
            let positionX = currentPosition.x;
            let positionY = currentPosition.y;

            let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            let windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            let fitsToViewportX = boundingRect.width < windowWidth;
            let fitsToViewportY = boundingRect.height < windowHeight;

            if (boundingRect.bottom > windowHeight) {
                positionY = POSITION_TOP;
            }

            if (boundingRect.top < 0) {
                positionY = POSITION_BOTTOM;
            }

            if (boundingRect.right > windowWidth) {
                positionX = POSITION_LEFT;
            }

            if (boundingRect.left < 0) {
                positionX = POSITION_RIGHT;
            }

            return {
                position: {
                    x: positionX,
                    y: positionY
                },
                fitsToViewport: {
                    x: fitsToViewportX,
                    y: fitsToViewportY
                },
                left: boundingRect.left,
                top: boundingRect.top
            };
        }
    }
};
</script>
